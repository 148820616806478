/* eslint-disable import/prefer-default-export */
import { isStepSkipable } from 'utils/formValuesUtils';

function getUnansweredQuestions(visibleQuestions, formValues) {
  return visibleQuestions.filter((question) => !formValues[question.name]);
}

/**
* Auto-advance rules for Multi-Question forms
*  - Single questions
      - always auto-advance if skippable
* - Multiple questions
*     - advance if a user is filling them out for the first time, and they complete the final unanswered question on a slide.
*     - Return users will not auto-advance on multi-question slides.
*     - Users hitting the 'Prev' button will not auto-advance when they re-answer questions.
*/
// TODO: Can this be moved inside the Form component? That could prevent having to pass in the `meta` object as the last param
export function handleAutoAdvance(
  visibleQuestions,
  updatedForm,
  currentBreakpoint,
  { isNonUserAction, formValues, formStatus: { isFormPrefilled } }
) {
  let shouldAutoAdvance = false;

  const unansweredQuestions = getUnansweredQuestions(
    visibleQuestions,
    formValues
  );

  // If the user is returning to a slide, they have answered all questions, do not auto-advance.
  if (isFormPrefilled && visibleQuestions?.length > 1) {
    shouldAutoAdvance = false;
  } else if (
    (unansweredQuestions.length === 1 &&
      updatedForm[unansweredQuestions[0].name]) ||
    visibleQuestions?.length === 1
  ) {
    const shouldSkip =
      !isNonUserAction &&
      isStepSkipable(visibleQuestions, updatedForm, currentBreakpoint);

    shouldAutoAdvance = !visibleQuestions.length || shouldSkip;
  }

  return shouldAutoAdvance;
}
