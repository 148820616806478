import { useState, useLayoutEffect } from 'react';

const TRANSITION_DURATION = 500;

const useSlider = (slideCount) => {
  const [sliderState, setSliderState] = useState({
    currentIndex: 0,
    prevIndex: 0,
    slideCount,
    slideDirection: 'next',
    infiniteSlidePosition: 0,
    shouldTransition: true,
    isTransitioning: false,
  });
  const infiniteSlideCount = slideCount + 2;

  function gotoSlide(slideIndex, direction) {
    const nextDirection =
      slideIndex > sliderState.currentIndex ? 'next' : 'prev';
    setSliderState({
      ...sliderState,
      prevIndex: sliderState.currentIndex,
      currentIndex: slideIndex,
      slideDirection: direction || nextDirection,
    });
  }

  function gotoNextSlide() {
    if (sliderState.currentIndex === sliderState.slideCount - 1) {
      return gotoSlide(0, 'next');
    }
    return gotoSlide(sliderState.currentIndex + 1, 'next');
  }

  function gotoPrevSlide() {
    if (sliderState.currentIndex === 0) {
      return gotoSlide(sliderState.slideCount - 1, 'prev');
    }
    return gotoSlide(sliderState.currentIndex - 1, 'prev');
  }

  const shouldJumpToEnd =
    sliderState.currentIndex === slideCount - 1 &&
    sliderState.currentIndex !== sliderState.prevIndex &&
    sliderState.slideDirection === 'prev' &&
    sliderState.infiniteSlidePosition !== sliderState.currentIndex + 1;

  // infinite Slider methods
  const shouldJumpToStart =
    sliderState.currentIndex === 0 &&
    sliderState.currentIndex !== sliderState.prevIndex &&
    sliderState.slideDirection === 'next' &&
    sliderState.infiniteSlidePosition !== sliderState.currentIndex + 1;

  function handleOnTransitionEnd() {
    if (shouldJumpToEnd || shouldJumpToStart) {
      setSliderState({
        ...sliderState,
        isTransitioning: false,
        shouldTransition: false,
      });
    } else if (!sliderState.shouldTransition) {
      setSliderState({
        ...sliderState,
        isTransitioning: false,
        shouldTransition: true,
      });
    } else {
      setSliderState({
        ...sliderState,
        isTransitioning: false,
      });
    }
  }

  function getInfiniteSlidePosition() {
    let nextPosition;

    // if the current slide is the clone of the FIRST slide
    // jump to the real first slide after transition ends
    if (shouldJumpToEnd && sliderState.shouldTransition) {
      nextPosition = 0;

      // if the current slide is the clone of the LAST slide
      // jump to the real last slide after transition ends
    } else if (shouldJumpToStart && sliderState.shouldTransition) {
      nextPosition = infiniteSlideCount - 1;
    } else {
      nextPosition = sliderState.currentIndex + 1;
    }

    if (nextPosition === sliderState.infiniteSlidePosition) {
      return null;
    }
    return setSliderState({
      ...sliderState,
      isTransitioning: true,
      infiniteSlidePosition: nextPosition,
    });
  }

  useLayoutEffect(() => {
    const transitionTimer = setTimeout(
      handleOnTransitionEnd,
      TRANSITION_DURATION
    );
    return () => {
      clearTimeout(transitionTimer);
    };
  }, [sliderState.currentIndex, sliderState.infiniteSlidePosition]);

  useLayoutEffect(() => {
    getInfiniteSlidePosition();
  }, [sliderState.shouldTransition, sliderState.currentIndex]);

  return { sliderState, gotoSlide, gotoNextSlide, gotoPrevSlide };
};

export default useSlider;
