import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';

const Wysiwyg = ({ content, textAlignment, isReversed }) => {
  const wysiwygClassName = classNames({
    wysiwyg: true,
    [`wysiwyg--text${
      textAlignment ? capitalizeFirstLetter(textAlignment) : ''
    }`]: !!textAlignment,
    [`wysiwyg--isReversed`]: isReversed,
  });

  return (
    <div
      className={wysiwygClassName}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

Wysiwyg.propTypes = {
  content: PropTypes.string,
  textAlignment: PropTypes.string,
  isReversed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Wysiwyg;
