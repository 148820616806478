import { QUERY_PARAMS } from 'consts';

const { DEGREE_GUID_PARAM, PARENT_CAT_GUID_PARAM, CATEGORY_GUID_PARAM } =
  QUERY_PARAMS;

function generateQueryString(degree, parentCategory, category) {
  return `${PARENT_CAT_GUID_PARAM}=${parentCategory.value}&${CATEGORY_GUID_PARAM}=${category.value}&${DEGREE_GUID_PARAM}=${degree.value}`;
}

/**
 * @summary this function is used to parse the backend response to generate a structure that can be
 *          better used to render the MegaMenu on the Click Portal
 */
export default function getProgramNavTree(degrees = [], currentBreakpoint) {
  const isMobile = currentBreakpoint !== 'desktop';

  if (!degrees.length) {
    return [];
  }
  const defaultLevel = isMobile ? 0 : 1;

  const navTree = degrees
    .map((degree) => ({
      ...degree,
      level: defaultLevel,
      id: degree.value,
      children:
        degree.children?.map((parentCategory) => ({
          ...parentCategory,
          level: defaultLevel + 1,
          id: parentCategory.value,
          children: parentCategory.children?.map((category) => ({
            ...category,
            id: category.value,
            level: defaultLevel + 2,
            link: `/portal/search?${generateQueryString(
              degree,
              parentCategory,
              category
            )}`,
          })),
        })) || [],
      customFunction: degree.customFunction,
      className: degree.className,
    }))
    .filter((navItem) => navItem.children.length > 0 || navItem.customFunction);

  if (!isMobile) {
    return [
      {
        id: 'degreesAndPrograms',
        label: 'Browse Programs',
        level: 0,
        children: navTree,
      },
      // {
      //   id: 'getRecommendations',
      //   label: 'Get Recommendations',
      //   level: 0,
      //   children: [],
      // },
    ];
  }

  return navTree;
}
