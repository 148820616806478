import { applyPreFills } from 'components/form-wizards/basic-form/utils';
import { getFormStepValidationInfo } from 'utils/form-utils/getFormStepValidationInfo';

export default function updatedFormValues(state, action) {
  const { updatedForm } = applyPreFills(state, {
    ...state.formValues,
    ...action.formValues,
  });

  const currentStepIndex = action.currentStepIndex || state.currentStepIndex;

  const updatedState = {
    ...state,
    currentStepIndex,
    isLastStep: state.totalSteps === currentStepIndex + 1,
    formValues: updatedForm,
    formStatus: {
      ...state.formStatus,
      ...action.formStatus,
    },
  };

  updatedState.formStatus.stepsValidationStatus[updatedState.currentStepIndex] =
    getFormStepValidationInfo(updatedState);

  return updatedState;
}
