import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import cx from 'utils/classnames';
import AccordionContext from './AccordionContext';

function AccordionPanel({
  title = '',
  children,
  index,
  onActive,
  id,
  headerComponent,
}) {
  const { activeIndex, setActiveIndex } = useContext(AccordionContext);
  const [height, setHeight] = useState(0);

  const accordionContentRef = useRef(null);
  const isActive = activeIndex === index;
  const accordionPanelClass = cx('accordion__panel', {
    'accordion__panel--isActive': isActive,
    [`accordion__panel--${camelCase(title)}`]: !!title,
  });

  const handleToggle = (e) => {
    e.preventDefault();
    const nextIndex = activeIndex !== index ? index : null;

    setActiveIndex(nextIndex);
  };

  useEffect(() => {
    if (isActive) {
      onActive && onActive();
      setHeight(accordionContentRef.current.scrollHeight);
    }

    if (!isActive && height > 0) {
      setHeight(0);
    }
  }, [activeIndex, accordionContentRef]);

  return (
    <div className={accordionPanelClass}>
      <button
        className="accordion__header"
        type="button"
        onClick={handleToggle}
        aria-controls={`panel_${id}_${index}`}
        aria-expanded={isActive}
      >
        <span className="accordion__headerTitle">
          {headerComponent || title}
        </span>
        <span className="accordion__toggle">
          <span className="accordion__toggleLabel">Open</span>
        </span>
      </button>
      <div
        id={`panel_${id}_${index}`}
        className="accordion__body"
        ref={accordionContentRef}
        style={{ height }}
        aria-hidden={!isActive}
      >
        <div className="accordion__content">{children}</div>
      </div>
    </div>
  );
}

AccordionPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  index: PropTypes.number,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  onActive: PropTypes.func,
  headerComponent: PropTypes.node,
};

export default AccordionPanel;
