import { useEffect, useState } from 'react';

/**
 * @summary use this when we want to know if an element is being viewed
 * @param {Object} ref - ref to DOM element
 * @param {*} rootMargin - when should we consider this element to be on the screen
 */
export default function useOnScreen(ref, rootMargin = '0px', options = {}) {
  const [state, setState] = useState({
    isOnScreen: !!options.isOnScreen,
    hasBeenSeen: false,
  });

  useEffect(() => {
    const observerOptions = {
      rootMargin,
    };

    let observer;
    const callback = ([entry]) => {
      setState((prevState) => ({
        isOnScreen: entry.isIntersecting,
        hasBeenSeen: prevState.hasBeenSeen || entry.isIntersecting,
      }));
    };

    try {
      observer = new IntersectionObserver(callback, observerOptions);

      if (ref.current) {
        observer.observe(ref.current);
      }
    } catch (error) {
      // IE it will crash but we don't support
    }

    return () => {
      if (ref.current && observer) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return state;
}
