/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';

interface CarouselSlideProps {
  children: ReactNode;
  isActive?: boolean;
  [key: string]: unknown;
}

function CarouselSlide({
  children,
  isActive,
  ...props
}: CarouselSlideProps): React.ReactElement {
  return (
    <li
      className={`carouselSlide ${isActive ? 'carouselSlide--isActive' : ''}`}
      {...props}
    >
      {children}
    </li>
  );
}

export default CarouselSlide;
