import get from 'lodash/get';
import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import request from 'utils/request';
import {
  GetQuestionOptionsParsedResponse,
  School,
  SchoolOptionAPIResponse,
  GetSchoolListingRawResponse,
  OptionsArray,
  GetSchoolListingPayload,
} from 'types';
import { QUESTION_IDS } from 'consts';
import { LogError, LogInfo } from 'utils/logging';

const { MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION } = QUESTION_IDS;

function getOptionsFromProgramList(
  programData: SchoolOptionAPIResponse['programs'] = []
): OptionsArray {
  if (programData.length === 1) {
    const singleProgramGroup = programData[0];
    return singleProgramGroup.ProgramList
      ? singleProgramGroup.ProgramList.map((program) => {
          return {
            label: program.label,
            value: program.value,
            selected: false,
            guid: program.value,
          };
        })
      : [];
  }
  return programData.map((programGroup) => {
    return {
      group: programGroup.label,
      options: programGroup.ProgramList
        ? programGroup.ProgramList.map((program) => {
            return {
              label: program.label,
              value: program.value,
              selected: false,
              guid: program.value,
            };
          })
        : [],
    };
  });
}
/**
 * Maps the raw school options response from the API to the shape required by the form
 */
function transformSchoolListings(
  schools: SchoolOptionAPIResponse[],
  impressionGroupGuid: string
): School[] {
  return schools.map((school) => ({
    impressionGroupGuid,
    matchingProgramOptions: getOptionsFromProgramList(school?.programs),
    degreeList:
      school.programs?.map((degree) => ({
        label: degree.label,
        value: degree.value,
        programs:
          degree.ProgramList?.map((program) => ({
            label: program.label,
            value: program.value,
          })) || [],
      })) || [],
    description: school?.schoolDesc?.[0] || '',
    featuredImage: {
      mobile: school.featuredImage?.Mobile?.imageUrl || '',
      desktop: school.featuredImage?.Desktop?.imageUrl || '',
    },
    highlights: school.highlights || '',
    id: school.value,
    label: school.label,
    originalSelectedProgram: {
      label: school.selectedProgram?.label || '',
      value: school.selectedProgram?.value || '',
    },
    rating: school.rating,
    schoolLogo: {
      mobile: school.schoolImages?.Mobile?.imageUrl || '',
      desktop: school.schoolImages?.Desktop?.imageUrl || '',
    },
    terms: school.disclaimer || '',
    impressionGuid: school.impressionGuid,
  }));
}

/**
 * @summary given the current state of the form and the current school we may need to get options for a question
 */
export function getMicroPortalSchoolListing(
  payload: GetSchoolListingPayload,
  schoolCode: string,
  variant: string,
  endpoint: string
): GetQuestionOptionsParsedResponse {
  const replies = get(payload, 'questionReplies', []).map(
    ({ questionAnswer, questionId }) => ({
      questionAnswer,
      questionId,
    })
  );

  LogInfo('getMicroPortalSchoolListing', { description: 'Start' });

  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}${endpoint}`,
    body: {
      schoolCode,
      requestType: payload.requestType,
      impressionGroup: payload.impressionGroup,
      submittedImpressionGuids: payload.submittedImpressionGuids,
      maxSchools: payload.maxSchools,
      pageTaxonomyValues: payload.pageTaxonomyValues,
      leadEvalToken: payload.leadEvalToken,
      templateName: variant,
      requestedOptions: payload.requestedOptions,
      questionReplies: replies,
    },
  })
    .then(({ Listings, impressionGroup }: GetSchoolListingRawResponse) => {
      if (!Listings || !Listings.length) {
        LogInfo('getMicroPortalSchoolListing', {
          description: 'Success No Results',
        });
      } else {
        LogInfo('getMicroPortalSchoolListing', {
          description: 'Success With Results',
        });
      }

      if (Listings && payload.requestType === 'Primary') {
        return {
          [MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION]: {
            questionId: MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION,
            options: transformSchoolListings(Listings, impressionGroup),
          },
        };
      }

      LogError('getMicroPortalSchoolListing - Listing Not Mapped Correctly');

      return {};
    })
    .catch((error: Error) => {
      LogInfo('getMicroPortalSchoolListing', { description: 'Failed' });
      throw error;
    });
}

export const forTestingOnly = {
  getOptionsFromProgramList,
  transformSchoolListings,
};
