import React from 'react';
import PropTypes from 'prop-types';
import { getCurrentDateFromArray } from 'components/sections/admissions/utils';
import { format } from 'date-fns';
import { isNumber } from 'utils/stringHelpers';

const STAT_MAP = {
  courseLength: { prefix: 'Usually', label: 'Course Length', units: 'weeks' },
  format: { label: 'Format' },
  totalCreditsRequired: { label: 'Total Credits' },
  transferCredits: { label: 'Transfer Credits' },
};

function getStartDate(startDates) {
  const currentDate = getCurrentDateFromArray(startDates);

  return currentDate ? format(currentDate, 'PPP') : null;
}

function renderStatValue(stat, value) {
  if (isNumber(value)) {
    return (
      <>
        {STAT_MAP[stat].prefix && `${STAT_MAP[stat].prefix} `}
        {value}
        {STAT_MAP[stat].units && ` ${STAT_MAP[stat].units}`}
      </>
    );
  }

  return value;
}

function ProgramInfoStats({ stats = {}, startDates }) {
  const filteredStats = Object.keys(stats).filter((stat) => stats[stat]);

  if (!filteredStats || !filteredStats.length) {
    return null;
  }

  return (
    <div className="programInfoStats">
      <div className="programInfoStats__header">Quick Facts</div>
      <ul className="programInfoStats__stats">
        {filteredStats.map((stat) => {
          const currentStat = stats[stat];
          return (
            <li
              className="programInfoStats__stat"
              key={`programInfoStats__${stat}`}
            >
              <div className="programInfoStats__statLabel">
                {STAT_MAP[stat]?.label}
              </div>
              <div className="programInfoStats__statValue">
                {renderStatValue(stat, currentStat)}
              </div>
            </li>
          );
        })}
        {startDates && startDates.length > 0 && (
          <li
            className="programInfoStats__stat programInfoStats__stat--hasIcon"
            key="programInfoStats__startDate"
          >
            <span className="programInfoStats__statLabel">Next Start Date</span>
            <span className="programInfoStats__statValue">
              <span className="programInfoStats__statIcon" />
              {getStartDate(startDates)}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
}

ProgramInfoStats.propTypes = {
  stats: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  startDates: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      registrationDate: PropTypes.string,
    })
  ),
};

export default ProgramInfoStats;
