import { HISTORY_PAGE_ID } from 'consts';
import { LogDebug } from 'utils/logging';

interface FormInfo {
  isInitializing: boolean;
  currentStepIndex: number;
  currentAction: string;
  handleBack: () => void;
  handleSubmit: () => void;
}

/**
 * @summary this is used so that when a user clicks back or forward on the browser we can control the form navigation
 */
export function handleBrowserBackButton(
  event: History,
  formInfo: FormInfo
): void {
  const {
    isInitializing,
    currentStepIndex,
    currentAction,
    handleBack,
    handleSubmit,
  } = formInfo;

  const requestedStep = event.state.currentStepIndex;

  if (isInitializing) {
    return;
  }

  if (event.state.id && event.state.id !== HISTORY_PAGE_ID) {
    // another sessions page
    window.history.back();
  } else if (
    currentStepIndex <= 0 &&
    currentAction === 'prev' &&
    !requestedStep
  ) {
    // first step and user wants to move back, let browser take care of this
  } else if (typeof requestedStep === 'undefined') {
    // when user is about to move to the first question
    handleBack();
  } else if (requestedStep > currentStepIndex) {
    // user clicks forward
    handleSubmit();
  } else if (requestedStep < currentStepIndex) {
    // use click back, note this can also be trigger from prev button in form
    handleBack();
  }
}

/**
 * @summary We want to keep track of movement within the form as page views, note this must stay in between the 2 recursive calls
 */
export function setBrowserStepInHistory(
  nextStepIndex: number,
  isNonUserAction: boolean,
  stepsCount: number
): void {
  try {
    if (
      window.history?.state?.currentStepIndex !== nextStepIndex &&
      !isNonUserAction &&
      stepsCount > 1
    ) {
      // prevents the browser from automatically scrolling to the previous location on load
      if (window && window.history.scrollRestoration) {
        window.history.scrollRestoration = 'manual';
      }

      window.history.pushState(
        {
          ...window.history.state,
          currentStepIndex: nextStepIndex,
          id: HISTORY_PAGE_ID,
        },
        ''
      );
    }
  } catch (error) {
    LogDebug('Error setting browser history', {
      description: (error as unknown as Error).message,
    });
  }
}
