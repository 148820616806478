/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { QUERY_PARAMS } from 'consts';
import ApplicationLink from './ApplicationLink';

const { PARENT_CAT_GUID_PARAM } = QUERY_PARAMS;

const CtaButton = ({
  buttonText,
  buttonActionType,
  link,
  trackingLabel = null,
}) => {
  let linkParams = '';
  const router = useRouter();

  const {
    userData: { parentCategorySelection, applicationLink },
    actions,
  } = useContext(GlobalContext);

  if (parentCategorySelection) {
    linkParams = `?${PARENT_CAT_GUID_PARAM}=${parentCategorySelection.value}`;
  }

  const triggerClickPortalWizardModal = () => {
    actions.setActiveModalId('clickPortalFormWizardModal');
  };

  if (buttonActionType === 'clickPortalWizard') {
    return (
      <button
        type="button"
        className="ctaButton"
        data-testid={`ctaButton--${buttonActionType}`}
        onClick={triggerClickPortalWizardModal}
      >
        {buttonText}
      </button>
    );
  }

  if (buttonActionType === 'fullPageForm') {
    const urlPath = router?.query?.degree || 'default';

    return (
      <a
        className="ctaButton"
        data-testid={`ctaButton--${buttonActionType}`}
        href={`/${urlPath}/landing/form${linkParams}`}
      >
        {buttonText}
      </a>
    );
  }

  if (buttonActionType === 'applicationLink') {
    return <ApplicationLink url={applicationLink} buttonText={buttonText} />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className="ctaButton"
      data-track-click={trackingLabel}
      data-testid={`ctaButton--${buttonActionType}`}
      href={link}
      target={buttonActionType === 'customLink' ? '_blank' : null}
      rel={buttonActionType === 'customLink' ? 'noreferrer noopener' : null}
    >
      {buttonText}
    </a>
  );
};

CtaButton.propTypes = {
  buttonText: PropTypes.string,
  link: PropTypes.string,
  buttonActionType: PropTypes.string,
  trackingLabel: PropTypes.string,
};

export default CtaButton;
