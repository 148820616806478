/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import Accordion from 'components/base/accordion/Accordion';
import AccordionPanel from 'components/base/accordion/AccordionPanel';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';

// panelIndex is passed to ensure that Accordion rerenders
// otherwise the activeIndex will remain the same
function FaqPanel({ questions = [], panelIndex }) {
  if (!questions.length) {
    return null;
  }

  return (
    <div className="faqPanel">
      <Accordion key={`faqPanel_${panelIndex}`}>
        {questions?.map((question, index) => (
          <AccordionPanel
            title={question.question}
            id={`${camelCase(question.question)}_faqPanel${index}`}
            key={`${camelCase(question.question)}_faqPanelKey${index}`}
          >
            <div>
              <Wysiwyg content={question.answer} />
            </div>
          </AccordionPanel>
        ))}
      </Accordion>
    </div>
  );
}

FaqPanel.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  panelIndex: PropTypes.number,
};

export default FaqPanel;
