/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import getComponent from 'components/COMPONENT_MAP';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import useOnScreen from 'hooks/custom/useOnScreen';
import GlobalContext from 'hooks/contexts/GlobalContext';

function SiteHeader(props) {
  const {
    config: { headerComponent, ...headerProps } = {
      component: 'DEFAULT_HEADER',
    },
    meta: { googleTagManagerSiteId, siteName },
  } = props;

  const { actions, refs, windowSize, flags } = useContext(GlobalContext);
  const headerRef = useRef(null);
  const headerComponentRef = useRef(null);

  const { isOnScreen } = useOnScreen(refs.heroRef || headerRef, '20px', {
    isOnScreen: true,
  });

  const HeaderComponent = getComponent(headerComponent);

  // dynamically set header height cssCustomProperty for access inside theme CSS
  useEffect(() => {
    if (headerComponentRef && headerComponentRef.current) {
      actions.setCssCustomProps(
        '--headerHeight',
        `${headerComponentRef.current.offsetHeight}px`
      );
    }
  }, [windowSize, flags.hasStickyHeader, headerComponentRef]);

  useEffect(() => {
    if (headerProps.useStickyHeader) {
      actions.updateGlobalFlags({
        hasStickyHeader: !isOnScreen,
      });
    }
  }, [isOnScreen]);

  useEffect(() => {
    actions.updateGlobalRefs({
      headerComponentRef,
    });
    return () => {
      actions.updateGlobalRefs({
        headerComponentRef: null,
      });
    };
  }, []);

  return (
    <DefaultErrorBoundary>
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerSiteId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <div className="site__header" role="banner" ref={headerRef}>
        <HeaderComponent
          {...headerProps}
          siteName={siteName}
          ref={headerComponentRef}
        />
      </div>
    </DefaultErrorBoundary>
  );
}

SiteHeader.propTypes = {
  meta: PropTypes.shape({
    googleTagManagerSiteId: PropTypes.string.isRequired,
    siteName: PropTypes.string,
  }).isRequired,
  config: PropTypes.shape({
    headerComponent: PropTypes.string.isRequired,
    headertext: PropTypes.string,
    showNavigation: PropTypes.bool,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      })
    ),
  }),
};

export default SiteHeader;
