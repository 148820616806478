/* eslint-disable import/prefer-default-export */

export function getPageTitle(seoTitle, title, siteName) {
  let pageTitle = `${seoTitle || title} | ${siteName}`;

  if (title && title === 'Full Page Form') {
    pageTitle = `${pageTitle} | Find Your Program`;
  }

  return pageTitle;
}
