import React from 'react';

interface CarouselDirectionNavProps {
  handleGotoNext: () => void;
  handleGotoPrev: () => void;
}

function CarouselDirectionNav({
  handleGotoNext,
  handleGotoPrev,
}: CarouselDirectionNavProps): React.JSX.Element {
  return (
    <ul className="carouselDirectionNav">
      <li className="carouselDirectionNav__item carouselDirectionNav__item--prev">
        <button
          type="button"
          data-testid="carouselPrevButton"
          className="carouselDirectionNav__button"
          onClick={handleGotoPrev}
          aria-label="Previous slide"
        >
          Prev
        </button>
      </li>
      <li className="carouselDirectionNav__item carouselDirectionNav__item--next">
        <button
          type="button"
          data-testid="carouselNextButton"
          className="carouselDirectionNav__button"
          onClick={handleGotoNext}
          aria-label="Next slide"
        >
          Next
        </button>
      </li>
    </ul>
  );
}

export default CarouselDirectionNav;
