import moveToNextStepReduce from 'hooks/custom/forms/basic-wizard/reducer-functions/moveToNextStep';
import { LogError } from 'utils/logging';

/**
 * @summary This function is used to check values on the form before submit in order to alert us of any irregularities
 * @param {Object} formState - the current state of the form
 * @returns returns true if all checks out
 */
export default function preFormSubmitChecks(formValues, formState) {
  try {
    const stateToTest = {
      ...formState,
      formValues,
      currentStepIndex: 0,
    };

    // TODO [FSIU-75]: Remove this once isFormCompleteAndValid is live and ready
    const { currentStepIndex } = moveToNextStepReduce(stateToTest, {
      shouldSkipSteps: true,
      isSanityCheck: true,
    });
    if (currentStepIndex !== formState.currentStepIndex) {
      LogError('Form Skipping Error', {
        stepIndexSkipped: currentStepIndex,
        currentStepIndex: formState.currentStepIndex,
      });
      return false;
    }

    if (!formState.formStatus.isFormCompleteAndValid) {
      // TODO [FSIU-75]: Find a way to implement isFormCompleteAndValid
    }
  } catch (error) {
    LogError(`Form Skipping Error Calculation Error: ${error.message}`);
    return false;
  }

  return true;
}
