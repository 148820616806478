import React from 'react';
import PropTypes from 'prop-types';

const Site = ({ children }) => {
  return <div className="site">{children}</div>;
};

Site.propTypes = {
  children: PropTypes.node,
};

export default Site;
