import React from 'react';
import PropTypes from 'prop-types';
import isDevelopment from 'utils/isDevelopment';

function KetchScript({ isEnabled, propertyId }) {
  if (!isEnabled || isDevelopment()) {
    return null;
  }

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
      !function() {
        window.semaphore = window.semaphore || [];
        window.ketch = function() {
          window.semaphore.push(arguments);
        };
        var e = new URLSearchParams(document.location.search),
            o = e.has("property") ? e.get("property") : "${propertyId}",
            n = document.createElement("script");
        n.type = "text/javascript";
        n.src = "https://global.ketchcdn.com/web/v3/config/triad_media_solutions/".concat(o, "/boot.js");
        n.defer = n.async = true;
        document.getElementsByTagName("head")[0].appendChild(n);
      }();
      `,
      }}
    />
  );
}

KetchScript.propTypes = {
  isEnabled: PropTypes.bool,
  propertyId: PropTypes.string,
};

export default KetchScript;
