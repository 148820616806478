import React from 'react';

interface CloseBtnProps {
  onClick: () => void;
}

function CloseBtn({ onClick }: CloseBtnProps): React.JSX.Element {
  return (
    <button type="button" className="closeBtn" onClick={onClick}>
      <span className="closeBtn__text">Close</span>
    </button>
  );
}

export default CloseBtn;
