import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import Link from 'next/link';
import GlobalContext from 'hooks/contexts/GlobalContext';
import MegaMenuContext from './MegaMenuContext';

function MegaMenuSecondaryNav({ navItems }) {
  const {
    navState,
    handlers,
    settings: { megaMenuAnimationSpeed },
  } = useContext(MegaMenuContext);

  const {
    flags: { navIsOpen },
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  if (!navItems) {
    return null;
  }

  const toggleMenu = () => {
    if (navIsOpen) {
      handlers.handleNavToggle();
    }
  };

  return (
    <CSSTransition
      in={
        currentBreakpoint === 'desktop' ||
        (navIsOpen && navState.navLevel === 0)
      }
      timeout={megaMenuAnimationSpeed}
      key="level1_secondaryNav"
      className="megaMenu__navItem megaMenu__navItem--hasSecondaryNav"
      classNames="megaMenu__navItem"
    >
      <li>
        <div className="megaMenuSecondaryNav">
          <div className="megaMenuSecondaryNav__heading">Popular Programs:</div>
          <ul className="megaMenuSecondaryNav__items">
            {navItems.map((item) => (
              <li
                className="megaMenuSecondaryNav__item"
                key={`${item.label}_${item.url}`}
              >
                <Link
                  href={item.url}
                  onClick={toggleMenu}
                  className="megaMenuSecondaryNav__itemLink">

                  {item.label}

                </Link>
              </li>
            ))}
          </ul>
        </div>
      </li>
    </CSSTransition>
  );
}

MegaMenuSecondaryNav.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default MegaMenuSecondaryNav;
