/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import { QUESTION_IDS } from 'consts';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import GlobalContext from 'hooks/contexts/GlobalContext';

const { PRIMARY_PHONE } = QUESTION_IDS;

export default function Disclaimer({
  name,
  required,
  onChange,
  value = false,
  disabled,
  disclaimerTextValue = '',
}) {
  const {
    siteMeta: { disclaimerText },
  } = useContext(GlobalContext);

  const { formValues, allQuestionsInForm } = useContext(BasicFormWizardContext);

  const phoneQuestion = allQuestionsInForm[PRIMARY_PHONE] || {};
  const phoneNumber = get(formValues, `[${phoneQuestion.name}]`, '');

  const _disclaimerText = (disclaimerTextValue || disclaimerText).replace(
    /{PHONE}/,
    phoneNumber
  );

  const _onChange = (event) => {
    // T1-7146: validation issue. False is a valid value.
    onChange(event.target.checked || undefined, {
      name: event.target.name,
      event,
    });
  };

  return (
    <div
      className={required ? 'disclaimer disclaimer--isRequired' : 'disclaimer'}
    >
      {required && (
        <input
          disabled={disabled}
          checked={value}
          onChange={_onChange}
          className="disclaimer__input"
          type="checkbox"
          id="disclaimerInput"
          name={name}
          data-testid="disclaimer"
        />
      )}
      <label
        htmlFor={required ? 'disclaimerInput' : 'leadid_tcpa_disclosure'}
        className="disclaimer__label"
      >
        {required && <span className="disclaimer__box" />}
        <span className="disclaimer__labelText">
          <Wysiwyg content={_disclaimerText} />
        </span>
      </label>
      {/* Don't touch this as jornaya needs it */}

      <input
        type="hidden"
        id="leadid_tcpa_disclosure"
        name="leadid_tcpa_disclosure"
      />
    </div>
  );
}

Disclaimer.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  disclaimerTextValue: PropTypes.string,
};
