import React from 'react';
import { numOrStrPropType } from 'utils/propTypes';

export default function ProgressBar({ progress = 0 }) {
  return (
    <div
      className="progressBar"
      id="progressBar"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuenow={progress}
      aria-valuetext={`${Math.round(progress)}%`}
    >
      <span
        className="progressBar__progress"
        style={{ width: `${progress}%` }}
      />
      <label htmlFor="progressBar" className="progressBar__label">
        {`${Math.round(progress)}%`}
      </label>
    </div>
  );
}

ProgressBar.propTypes = {
  progress: numOrStrPropType.isRequired,
};
