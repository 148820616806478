import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import {
  GroupedOptionPropType,
  GroupedOptionsPropType,
  OptionPropType,
} from 'utils/propTypes';
import { NOP } from 'utils/generalUtils';

export function GroupedButtonSelectOptions({
  optionsGroup,
  name,
  value,
  onChange,
}) {
  return (
    <div className="groupedButtonSelectOptions">
      {optionsGroup.options &&
        optionsGroup.options.map((option) => (
          <button
            type="button"
            key={option.value}
            className={cx('groupedButtonSelectOptions__option', {
              'groupedButtonSelectOptions__option--selected':
                option.value === (value.value || value),
            })}
            onClick={() => onChange(option, { name })}
          >
            {option.label}
          </button>
        ))}
    </div>
  );
}

export default function GroupedButtonSelect(props) {
  const { name = '', options = [], value = {}, onChange = NOP } = props;
  const [
    groupedButtonSelectOptions,
    setGroupedButtonSelectOptions,
  ] = useState();

  // expand the group on mount if this field has a value
  useEffect(() => {
    const selectedGroup = options.find((group) =>
      group.options.some((option) => option.value === (value.value || value))
    );

    if (selectedGroup) {
      setGroupedButtonSelectOptions(selectedGroup.group);
    }
  }, []);

  const handleGroupExpandClick = (group) => {
    let selectedGroup = group;

    if (group === groupedButtonSelectOptions) {
      selectedGroup = '';
    }
    setGroupedButtonSelectOptions(selectedGroup);
  };

  return (
    <div className="groupedButtonSelect">
      {options.map((optionsGroup) => (
        <div
          key={optionsGroup.group}
          className={cx('groupedButtonSelect__group', {
            'groupedButtonSelect__group--isExpanded':
              groupedButtonSelectOptions === optionsGroup.group,
          })}
        >
          <button
            type="button"
            className="groupedButtonSelect__groupButton"
            onClick={() => handleGroupExpandClick(optionsGroup.group)}
          >
            <span className="groupedButtonSelect__buttonIcon" />
            {optionsGroup.group}
          </button>
          <GroupedButtonSelectOptions
            optionsGroup={optionsGroup}
            name={name}
            value={value}
            onChange={onChange}
          />
        </div>
      ))}
    </div>
  );
}

GroupedButtonSelectOptions.propTypes = {
  optionsGroup: GroupedOptionPropType,
  name: PropTypes.string,
  value: OptionPropType,
  onChange: PropTypes.func.isRequired,
};

GroupedButtonSelect.propTypes = {
  options: GroupedOptionsPropType,
  name: PropTypes.string,
  value: PropTypes.oneOfType([OptionPropType, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};
