import { FormFieldValidationStatus, QUESTION_IDS } from 'consts';
import { getValidQuestions } from 'utils/formValuesUtils';

const { MICRO_PORTAL_DYNAMIC_TCPA, TCPA_DISCLOSURE } = QUESTION_IDS;

export function setFieldLevelValidationStatus(state, action = {}) {
  const { fieldNameMap } = action;

  function getIdByFieldName(fieldName) {
    return state.fieldNameMap[fieldName]?.id;
  }

  // no rerender needed if no fieldNameMap
  if (Object.keys(fieldNameMap).length === 0) {
    return state;
  }

  const fieldsToUpdate = Object.keys(fieldNameMap);

  // no rerender needed if no change in validation status
  if (
    fieldsToUpdate.every(
      (fieldName) =>
        fieldNameMap[fieldName] ===
        state.formValuesV2[getIdByFieldName(fieldName)]?.status
          ?.validationStatus
    )
  ) {
    return state;
  }

  const updatedFormValuesV2 = { ...state.formValuesV2 };

  fieldsToUpdate.forEach((fieldName) => {
    const questionId = getIdByFieldName(fieldName);
    updatedFormValuesV2[questionId] = updatedFormValuesV2[questionId] || {
      status: {},
    };
    updatedFormValuesV2[questionId].status = {
      ...updatedFormValuesV2[questionId].status,
      validationStatus: fieldNameMap[fieldName],
    };
  });

  const requiredQuestionIds = getValidQuestions(
    /* questions */ Object.values(state.allQuestionsInForm),
    /* formValues */ state.formValues,
    /* questionsMap */ state.allQuestionsInForm,
    /* loadedDynamicOptions */ state.formStatus.dynamicOptions
  )
    .filter((question) => question.required)
    .filter(
      (question) =>
        ![TCPA_DISCLOSURE, MICRO_PORTAL_DYNAMIC_TCPA].includes(question.id)
    )
    .map((question) => question.id);

  let isFormCompleteAndValid = true;

  for (let i = 0; i < requiredQuestionIds.length; i += 1) {
    const questionId = requiredQuestionIds[i];
    const { validationStatus } = updatedFormValuesV2[questionId]?.status || {};

    if (validationStatus !== FormFieldValidationStatus.VALID) {
      isFormCompleteAndValid = false;
    }

    if (!validationStatus) {
      updatedFormValuesV2[questionId] = updatedFormValuesV2[questionId] || {};
      updatedFormValuesV2[questionId].status =
        updatedFormValuesV2[questionId].state || {};
      updatedFormValuesV2[questionId].status.validationStatus =
        FormFieldValidationStatus.INVALID;
    }
  }

  return {
    ...state,
    formValuesV2: updatedFormValuesV2,
    formStatus: { ...state.formStatus, isFormCompleteAndValid },
  };
}
