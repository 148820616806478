import { useEffect, useContext } from 'react';
import { getApplicationLinks } from 'app-requests/triadmsRequests';
import GlobalContext from 'hooks/contexts/GlobalContext';

export default function useApplicationLinks() {
  const {
    sessionInfo: { pageViewId, userSessionId },
    siteMeta: { schoolCode },
    actions: { updateUserData },
    userData: { applicationLink },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (applicationLink) {
      return;
    }

    if (userSessionId && schoolCode) {
      getApplicationLinks({
        pageViewId,
        userSessionId,
        schoolCode,
      }).then((links) => {
        if (links) {
          updateUserData(links);
        }
      });
    }
  }, []);
}
