import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';
import Checkbox from 'components/base/checkbox/Checkbox';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import React, { useContext } from 'react';
import { UseMultiSchoolSelect } from 'types';
import { formValuesToRequestArr } from 'utils/formValuesUtils';

interface SelectionCardActionsProps {
  id: string;
  showSelect: boolean;
  showSubmit: boolean;
  showCheckbox: boolean;
}
export function SelectionCardActions(
  props: SelectionCardActionsProps
): React.JSX.Element {
  const { id, showSelect, showSubmit, showCheckbox } = props;
  const {
    state,
    handleSelectSchool,
    handleToggleSkip,
    handleSkipSchool,
    handleAcceptTerms,
  } = useContext<UseMultiSchoolSelect>(MultiSchoolSelectContext);

  const { formConfigs, formValues, fieldNameMap } = useContext(
    BasicFormWizardContext
  );

  function _handleAcceptTerms(): void {
    const metaData = {
      schoolCode: formConfigs.schoolCode,
      variant: formConfigs.variant,
      disclaimerText: state[id].schoolInfo.terms,
    };

    // Backend does not need the primary school selection question
    const questionReplies = formValuesToRequestArr(formValues, fieldNameMap);

    handleAcceptTerms(id, questionReplies, metaData);
  }

  if (state[id].isSubmitted) {
    return (
      <div className="selectionCard__submitted">
        <div className="selectionCard__submittedIcon">
          <svg
            enableBackground="new 0 0 32 32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m27 4-15 15-7-7-5 5 12 12 20-20z" />
          </svg>
        </div>
        <span className="selectionCard__submittedText">Submitted</span>
      </div>
    );
  }

  return (
    <div className="selectionCardActions">
      {showSelect && (
        <button
          className="selectionCardActions__btn selectionCardActions__btn--select "
          type="button"
          onClick={() => handleSelectSchool(id)}
        >
          Request Info
        </button>
      )}

      {showCheckbox && (
        <Checkbox
          onChange={() => handleToggleSkip(id)}
          name={id}
          id={id}
          label=""
          checked={!state[id].isSkipped}
        />
      )}

      {showSubmit && (
        <>
          <button
            className="selectionCardActions__btn selectionCardActions__btn--submit"
            type="button"
            onClick={_handleAcceptTerms}
          >
            Submit
          </button>
          <button
            className="selectionCardActions__btn selectionCardActions__btn--skip"
            type="button"
            onClick={() => handleSkipSchool(id)}
          >
            Skip
          </button>
        </>
      )}
    </div>
  );
}
