import React, { useState } from 'react';
import { SelectionCard } from 'components/form-wizards/micro-portal/SelectionCard/SelectionCard';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { UseMultiSchoolSelect } from 'types';
import Carousel from 'components/sections/carousel/Carousel';
import CarouselSlide from 'components/sections/carousel/CarouselSlide';

interface Props {
  formSpeed: number;
  isMobile: boolean;
  isTransitioning: boolean;
  setIsTransitioning: (isTransitioning: boolean) => void;
  state: UseMultiSchoolSelect['state'];
}

export default function MultiSchoolSelectList({
  formSpeed,
  isMobile,
  isTransitioning,
  setIsTransitioning,
  state,
}: Props): React.JSX.Element {
  const [cardIsTransitioning, setCardIsTransitioning] = useState(false);

  const handleTransitionStart = (): void => {
    setIsTransitioning(true);
  };

  const handleTransitionComplete = (): void => {
    setIsTransitioning(false);
  };

  const handleCardTransitionEnd = (): void => {
    setCardIsTransitioning(false);
  };

  const handleCardTransitionStart = (): void => {
    setCardIsTransitioning(true);
  };

  if (isMobile) {
    return (
      <Carousel
        onSlideAnimationStart={handleTransitionStart}
        onSlideAnimationComplete={handleTransitionComplete}
        cardIsTransitioning={cardIsTransitioning}
      >
        {Object.values(state).map((school) => (
          <CarouselSlide key={school.schoolInfo.id}>
            <SelectionCard
              id={school.schoolInfo.id}
              isListTransitioning={isTransitioning}
              onTransitionEnd={handleCardTransitionEnd}
              onTransitionStart={handleCardTransitionStart}
            />
          </CarouselSlide>
        ))}
      </Carousel>
    );
  }

  return (
    <TransitionGroup component="ul" className="multiSchoolSelect__list">
      {Object.values(state).map((school) => (
        <CSSTransition
          key={school.schoolInfo.id}
          timeout={formSpeed}
          classNames="multiSchoolSelect__listItem"
          appear
          enter
          exit
          onEntering={() => setIsTransitioning(true)}
          onEntered={() => setIsTransitioning(false)}
          onExiting={() => setIsTransitioning(true)}
          onExited={() => setIsTransitioning(false)}
        >
          <li className="multiSchoolSelect__listItem">
            <SelectionCard
              id={school.schoolInfo.id}
              isListTransitioning={isTransitioning}
              onTransitionEnd={handleCardTransitionEnd}
              onTransitionStart={handleCardTransitionStart}
            />
          </li>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}
