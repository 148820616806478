import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WPImagesPropType } from 'utils/propTypes';
import { BREAKPOINT_MAP } from 'consts';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { getSizesStringFromObj, getSrcSetSizes } from './responsiveImageUtils';

function ResponsiveImage({
  images = {},
  className,
  sizes,
  loading,
  ...otherProps
}) {
  const { image, mobileImage } = images;

  // return early if there's no image data
  // this can happen if media is deleted from the library
  // but not unattached from a page
  if (!image) {
    return null;
  }

  const {
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  const currentImage =
    (currentBreakpoint === BREAKPOINT_MAP.MOBILE.label ||
      currentBreakpoint === BREAKPOINT_MAP.TABLET.label) &&
    mobileImage
      ? mobileImage
      : image;

  const sourceSizes = getSizesStringFromObj(sizes);

  if (currentImage.mimeType === 'image/svg+xml' && currentImage.svg) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: currentImage.svg }}
      />
    );
  }

  return (
    <picture>
      <source
        media={
          mobileImage ? `(min-width: ${BREAKPOINT_MAP.TABLET.width}px)` : null
        }
        srcSet={image.srcSet || getSrcSetSizes(image)}
        type={image.mimeType}
        sizes={sourceSizes}
      />
      {mobileImage && (
        <source
          srcSet={mobileImage.srcSet || getSrcSetSizes(mobileImage)}
          type={mobileImage.mime_type}
          sizes={sourceSizes}
        />
      )}
      <img
        className={className}
        src={currentImage.url}
        alt={currentImage.alt}
        loading={loading}
        width={currentImage.width ? currentImage.width : null}
        height={currentImage.height ? currentImage.height : null}
        {...otherProps}
      />
    </picture>
  );
}

ResponsiveImage.propTypes = {
  images: WPImagesPropType,
  className: PropTypes.string,
  sizes: PropTypes.shape({
    MOBILE: PropTypes.string,
    TABLET: PropTypes.string,
    DESKTOP: PropTypes.string,
    DEFAULT: PropTypes.string,
  }),
  loading: PropTypes.string,
};

export default ResponsiveImage;
