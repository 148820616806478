/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent } from 'react';

interface CheckboxProps {
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  fieldRef?: React.RefObject<HTMLInputElement>;
  id: string;
  label: string;
  name: string;
  onChange: (
    value: string,
    event: { name: string; event: ChangeEvent<HTMLInputElement> }
  ) => void;
  value?: string;
  valueTransform?: (v: string) => string;
}

function Checkbox(props: CheckboxProps): React.JSX.Element {
  const {
    disabled = false,
    fieldRef,
    id,
    label,
    name,
    checked,
    onChange,
    value = '',
    valueTransform = (v: string): string => v,
    ...otherProps
  } = props;

  const _onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(valueTransform(event.target.value), {
      name: event.target.name,
      event,
    });
  };

  return (
    <div className="checkbox">
      <input
        {...otherProps}
        checked={checked}
        ref={fieldRef}
        value={value}
        name={name}
        id={id}
        aria-label={name}
        onChange={_onChange}
        type="checkbox"
        disabled={disabled}
        className="checkbox__input"
      />
      <label htmlFor={id} className="checkbox__label">
        <span className="checkbox__box" />
        <span className="checkbox__labelText">{label}</span>
      </label>
    </div>
  );
}

export default Checkbox;
