/* eslint-disable import/prefer-default-export */
import { isFuture } from 'date-fns';

export function getCurrentDateFromArray(startDateArray) {
  if (!startDateArray || !startDateArray.length) {
    return false;
  }
  let nextStartDate;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < startDateArray.length; i++) {
    const currentStartDate = startDateArray[i];

    if (isFuture(currentStartDate.startDateInMilliseconds)) {
      nextStartDate = currentStartDate.startDateInMilliseconds;
      break;
    }
  }

  return nextStartDate ? new Date(nextStartDate) : false;
}
