/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CtaPropTypes, WPImagesPropType } from 'utils/propTypes';
import cx from 'utils/classnames';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import { useRouter } from 'next/router';
import SectionTitle from 'components/blocks/headers/section-title/SectionTitle';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import { QUERY_PARAMS } from 'consts';
import GlobalContext from 'hooks/contexts/GlobalContext';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import FeatureBlock from './FeatureBlock';

const { PARENT_CAT_GUID_PARAM } = QUERY_PARAMS;

function FeatureBlocks({
  blocks = [],
  content,
  customCssClass,
  sectionTitle = '',
  subTitle,
  variation,
  background,
  blockCount,
  mediaWidth,
  blockAlignment,
}) {
  const {
    userData: { parentCategorySelection },
    windowSize: { currentBreakpoint },
    actions: { setActiveModalId },
  } = useContext(GlobalContext);

  let linkParams = '';
  const router = useRouter();

  if (parentCategorySelection) {
    linkParams = `?${PARENT_CAT_GUID_PARAM}=${parentCategorySelection.value}`;
  }

  const triggerClickPortalWizardModal = () => {
    setActiveModalId('clickPortalFormWizardModal');
  };

  const featureBlocksClass = cx({
    featureBlocks: true,
    [`featureBlocks--${blockCount[currentBreakpoint]}`]: true,
    [`featureBlocks--align${capitalizeFirstLetter(blockAlignment)}`]:
      !!blockAlignment,
    [`featureBlocks--mediaWidth${capitalizeFirstLetter(mediaWidth)}`]: true,
    [`featureBlocks--variation${capitalizeFirstLetter(variation)}`]:
      !!variation,
    [`featureBlocks--background${capitalizeFirstLetter(background)}`]:
      !!background,
    [`featureBlocks--${customCssClass}`]: !!customCssClass,
  });

  // TODO: Make these values configurable in WP
  const responsiveImageSizes = {
    MOBILE: '50vw',
    TABLET: '33vw',
    DESKTOP: `${100 / blocks.length}vw`,
    DEFAULT: '50vw',
  };

  return (
    <div className={featureBlocksClass}>
      <div className="featureBlocks__inner">
        {(sectionTitle || subTitle || content) && (
          <div className="featureBlocks__content">
            {sectionTitle && (
              <div className="featureBlocks__title">
                <SectionTitle title={sectionTitle} />
              </div>
            )}
            {subTitle && (
              <div className="featureBlocks__subTitle">
                <Wysiwyg content={subTitle} />
              </div>
            )}
            {content && (
              <div className="featureBlocks__copy">
                <Wysiwyg content={content} />
              </div>
            )}
          </div>
        )}
        {blocks && blocks.length > 0 && (
          <div className="featureBlocks__blocks">
            {blocks.map(
              (
                { featureImage, title, description, cta, wrapBlockWithCtaLink },
                index
              ) => (
                <FeatureBlock
                  featureImage={featureImage}
                  title={title}
                  description={description}
                  responsiveImageSizes={responsiveImageSizes}
                  wrapBlockWithCtaLink={wrapBlockWithCtaLink}
                  cta={{
                    ...cta,
                    handleClickPortalWizardToggle:
                      triggerClickPortalWizardModal,
                    router,
                    linkParams,
                  }}
                  key={`${camelCase(title)}_block_${get(
                    featureImage,
                    'image.url'
                  )}_${index}`}
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const blocksPropType = PropTypes.arrayOf(
  PropTypes.shape({
    featureImage: WPImagesPropType,
    title: PropTypes.string.isRequired,
    cta: CtaPropTypes,
    subTitle: PropTypes.string,
    responsiveImageSizes: PropTypes.shape({
      MOBILE: PropTypes.string,
      TABLET: PropTypes.string,
      DESKTOP: PropTypes.string,
      DEFAULT: PropTypes.string,
    }),
  })
);

FeatureBlocks.propTypes = {
  background: PropTypes.string,
  blockAlignment: PropTypes.string,
  blockCount: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
    tablet: PropTypes.string,
  }),
  blocks: blocksPropType,
  content: PropTypes.string,
  customCssClass: PropTypes.string,
  mediaWidth: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  variation: PropTypes.string,
};

export default FeatureBlocks;
