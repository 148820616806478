import { getLeadEvalToken } from 'app-requests/triadmsRequests';
import { FIELD_NAMES } from 'consts';
import { useEffect } from 'react';
import { LogError } from 'utils/logging';

export function LeadEvalTokenRequestSideEffect({ formState, actions }) {
  const {
    formStatus: { leadEvalToken },
    formValues,
    fieldNameMap,
    formConfigs: { schoolCode },
    isLastStep,
  } = formState;

  /**
   * Asynchronously handles the update of lead evaluation token.
   */
  async function handleLeadEvalTokenUpdate() {
    const leadTokenResponse = await getLeadEvalToken(
      formValues,
      fieldNameMap,
      schoolCode
    ).catch((error) => {
      LogError('Error fetching leadEvalToken:', error);
    });

    actions.setStatus({
      leadEvalToken: leadTokenResponse?.leadEvalToken,
    });
  }

  useEffect(() => {
    // TODO [FSIU-75]: Find a way to implement isFormCompleteAndValid
    const timeout = setTimeout(() => {
      const isPhoneNumberValid =
        formValues[FIELD_NAMES.PRIMARY_PHONE]?.replace(/-/g, '').length === 10;

      if (isLastStep && isPhoneNumberValid) {
        handleLeadEvalTokenUpdate();
      } else if (!isLastStep && leadEvalToken) {
        actions.setStatus({
          leadEvalToken: null,
        });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [formValues]);

  return null;
}
