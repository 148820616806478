import { FormFieldValidationStatus } from 'consts';
import get from 'lodash/get';
import FIELD_VALIDATION_MAP from 'utils/fieldValidations';
import { getFieldValue, isInvalidValue } from 'utils/formValuesUtils';

const { VALID, INVALID, PENDING } = FormFieldValidationStatus;

/**
 * @summary Run all questions validations synchronously returning any errors and promises for async validations
 */
export function validateQuestionsSync(questions, formValues) {
  const validationStatus = {};
  const errors = {};
  const asyncValidations = [];

  questions.forEach(({ name, required, validations }) => {
    const currentValue = getFieldValue(formValues, name);
    const hasNoValue = isInvalidValue(currentValue);

    // backend can overwrite any error message they want here
    const errorMessage = get(validations, '[0].errorText') || undefined; // doing this to null out empty strings
    const validationType = get(validations, '[0].type');
    const validationFunction = FIELD_VALIDATION_MAP[validationType];

    if (required && hasNoValue) {
      errors[name] = errorMessage || 'Please Answer The Question.';
      validationStatus[name] = INVALID;
    } else if (validationType && validationFunction) {
      const validationResults = validationFunction({
        value: currentValue,
        name,
        formValues,
        errorMessage,
      });

      if (!validationResults.then) {
        if (validationResults[name]) {
          errors[name] = validationResults[name];
          validationStatus[name] = INVALID;
        } else {
          validationStatus[name] = VALID;
        }
      } else {
        asyncValidations.push(
          validationResults.then((validationResult) => {
            return {
              validationResult,
              validationStatus: {
                [name]: validationResult[name] ? INVALID : VALID,
              },
            };
          })
        );

        validationStatus[name] = PENDING;
      }
    } else {
      validationStatus[name] = VALID;
    }
  });

  return { errors, asyncValidations, validationStatus };
}

/**
 * @summary Run all current question steps validations
 * @param {Array} questions - an array of question to check if they should be shown to the user
 * @param {Object} formValues - all values in the form keyed by the form field name
 */
export default async function validateStep(questions, formValues) {
  const { errors, asyncValidations } = validateQuestionsSync(
    questions,
    formValues
  );

  // handle validations that call APIs
  const asyncValidationResults = await Promise.all(asyncValidations);
  asyncValidationResults.forEach(({ validationResult }) => {
    Object.assign(errors, validationResult);
  });

  if (Object.keys(errors).length) {
    errors.isFormError = true;
    return Promise.reject(errors);
  }

  return Promise.resolve(formValues);
}
