import React, { useContext } from 'react';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { OptionsPropType } from 'utils/propTypes';
import { func, object } from 'prop-types';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { QUESTION_IDS } from 'consts';
import Loader from 'components/base/loader/Loader';
import Disclaimer from '../disclaimer/Disclaimer';

const { MICRO_PORTAL_DYNAMIC_TCPA } = QUESTION_IDS;

/**
 * @typedef {Object} DynamicDisclaimerValue
 * @property {boolean} value - true if the user check the select box
 * @property {string} dynamicDisclaimerText - the text the user sees
 * @property {Array<string>} valueToSubmit - school guids that the user will be submitted to
 */
const DynamicDisclaimerValue = {
  value: false,
  dynamicDisclaimerText: '',
  valueToSubmit: [],
};

export default function DynamicDisclaimer(props) {
  const {
    options = [],
    onChange,
    value = DynamicDisclaimerValue,
    ...otherProps
  } = props;

  const disclaimerValue = /** @type {DynamicDisclaimerValue} */ (value);

  const {
    siteMeta: { disclaimerText, disclaimerSuccessText },
  } = useContext(GlobalContext);

  const {
    formStatus: { isProcessing, dynamicOptions },
  } = useContext(BasicFormWizardContext);

  const _disclaimerText =
    options.length && disclaimerSuccessText
      ? disclaimerSuccessText
      : disclaimerText;

  const dynamicDisclaimerText = useTokenReplacement(_disclaimerText, {
    '{APPLICABLE_SCHOOLS}': options.map(({ label }) => label).join(', '),
  });

  function onChange_(isSelected, event) {
    const updatedDisclaimerValue = {
      valueToSubmit: [],
      dynamicDisclaimerText: '',
      value: Boolean(isSelected) || null, // we wont accept false. Null will trigger form validation,
    };

    if (isSelected && options.length) {
      updatedDisclaimerValue.valueToSubmit = options.map(
        (option) => option.value
      );
      updatedDisclaimerValue.dynamicDisclaimerText = dynamicDisclaimerText;
    } else if (isSelected) {
      updatedDisclaimerValue.dynamicDisclaimerText = dynamicDisclaimerText;
    }

    onChange(updatedDisclaimerValue, event);
  }

  if (isProcessing) {
    return (
      <div data-testid="disclaimer-loading">
        <Loader text="Searching for schools, please wait..." />
      </div>
    );
  }

  if (!dynamicOptions[MICRO_PORTAL_DYNAMIC_TCPA]) {
    return <div data-testid="disclaimer-no-value" />;
  }

  return (
    <Disclaimer
      {...otherProps}
      value={disclaimerValue?.value}
      disclaimerTextValue={dynamicDisclaimerText}
      onChange={onChange_}
    />
  );
}

DynamicDisclaimer.propTypes = {
  options: OptionsPropType,
  onChange: func,
  value: object,
};
