/* eslint-disable jsx-a11y/label-has-associated-control */
// TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
import React, { memo } from 'react';

import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';

interface TermsProps {
  terms: string;
  schoolName: string;
  isSkipped: boolean;
  isAccepted: boolean;
}

let TCP_COUNTER = 1;
const TCP_IDS: Record<string, string> = {};

function getTCPId(schoolName: string): string {
  if (!TCP_IDS[schoolName]) {
    TCP_IDS[schoolName] = `leadid_tcpa_disclosure_${TCP_COUNTER}`;
    TCP_COUNTER += 1;
  }

  return TCP_IDS[schoolName];
}

/**
 * @see https://marketing.verisk.com/helpcenter/docs/how-to-add-jornaya-tcpa-label-tag#yesno-menu
 */
function JornayaInput(props: {
  schoolName: string;
  inputName: string;
  isSkipped: boolean;
  isAccepted: boolean;
}): React.JSX.Element | null {
  const { isSkipped, isAccepted, inputName, schoolName } = props;

  const shouldRenderInput = isSkipped || isAccepted;

  if (!shouldRenderInput) return null;

  return (
    <select
      name={inputName}
      id={getTCPId(schoolName)}
      value={isAccepted ? 'accepted' : 'skipped'}
      style={{ display: 'none' }}
    >
      <option value="accepted">Yes</option>
      <option value="skipped">No</option>
    </select>
  );
}

const MemoizedJornayaInput = memo(JornayaInput, (prevProps, nextProps) => {
  return (
    prevProps.isAccepted === nextProps.isAccepted &&
    prevProps.isSkipped === nextProps.isSkipped
  );
});

export function Terms(props: TermsProps): React.JSX.Element {
  const { terms, schoolName } = props;

  const inputName = `terms-${schoolName
    ?.trim()
    .toLowerCase()
    .replace(/\s/g, '_')
    .substring(0, 20)}`;

  return (
    <div className="terms">
      <label htmlFor={getTCPId(schoolName)}>
        <Wysiwyg content={terms} />
      </label>

      <MemoizedJornayaInput
        schoolName={schoolName}
        inputName={inputName}
        isAccepted={props.isAccepted}
        isSkipped={props.isSkipped}
      />
    </div>
  );
}
