import React from 'react';
import PropTypes from 'prop-types';
import { SlidePropTypes } from 'utils/propTypes';
import CtaButton from 'components/blocks/ctaButton/CtaButton';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import MultiMedia from 'components/base/multiMedia/MultiMedia';

function Slide({ slide, options, id }) {
  const { images, title, subTitle, content, cta, videoEmbedId } = slide;

  const { mediaWidth } = options;

  const MEDIA_WIDTH_MAP = { small: '33vw', medium: '50vw', large: '66vw' };

  const responsiveImageSizes = {
    MOBILE: '600px',
    TABLET: '600px',
    DESKTOP: MEDIA_WIDTH_MAP[mediaWidth],
    DEFAULT: '100vw',
  };

  return (
    <div className="slide" id={id}>
      <div className="slide__inner">
        <div className="slide__media">
          <MultiMedia
            videoEmbedId={videoEmbedId}
            id={`slideMedia_${id}_${videoEmbedId}`}
            images={images}
            responsiveImageSizes={responsiveImageSizes}
          />
          {cta.useCTA && (
            <div className="slide__cta">
              <CtaButton {...cta} />
            </div>
          )}
        </div>
        <div className="slide__content">
          {title && (
            <h2 className="slide__title">
              <Wysiwyg content={title} />
            </h2>
          )}
          {subTitle && (
            <div className="slide__subTitle">
              <Wysiwyg content={subTitle} />
            </div>
          )}
          {content && (
            <div className="slide__copy">
              <Wysiwyg content={content} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Slide.propTypes = {
  options: PropTypes.shape({
    background: PropTypes.string,
    customCssClass: PropTypes.string,
    imageAlignment: PropTypes.string,
    isReversed: PropTypes.bool,
    mediaWidth: PropTypes.string,
    variation: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  slide: SlidePropTypes,
};

export default Slide;
