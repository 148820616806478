import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavItemProps } from 'utils/propTypes';
import { TransitionGroup } from 'react-transition-group';
import MegaMenuContext from './MegaMenuContext';
import MegaMenuNavItem from './MegaMenuNavItem';

const MegaMenuNav = ({
  navItems,
  currentBreakpoint,
  onMenuClick,
  SecondaryNav,
}) => {
  const { getSelectedStatus } = useContext(MegaMenuContext);

  return (
    <TransitionGroup component="ul" className="megaMenu__nav">
      {navItems.map((item) => {
        const isSelected = getSelectedStatus(item.id);
        return (
          <MegaMenuNavItem
            data-testid="megaMenuNavItem"
            key={`megaMenuNavItem_${item.id}`}
            navItem={{ ...item, isSelected }}
            currentBreakpoint={currentBreakpoint}
            onMenuClick={onMenuClick}
          />
        );
      })}
      {SecondaryNav}
    </TransitionGroup>
  );
};

MegaMenuNav.propTypes = {
  currentBreakpoint: PropTypes.string,
  navItems: PropTypes.arrayOf(NavItemProps),
  onMenuClick: PropTypes.func,
  SecondaryNav: PropTypes.node,
};

export default MegaMenuNav;
