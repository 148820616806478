import React from 'react';
import PropTypes from 'prop-types';

export default function SectionTitle({ title = '' }) {
  return (
    <div className="sectionTitle">
      <h1
        className="sectionTitle__title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
