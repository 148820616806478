import { DEFAULT_SELECT_VALUE } from 'consts';
import uniqBy from 'lodash/uniqBy';
import { useState, useEffect } from 'react';
import { LogError } from 'utils/logging';

/**
 * @summary this is used to get a list of programs that match a degree and or parentCategory
 * @param {Array} programsMap - an array of programs for this micro site
 * @param {Array} initialValues - a list of programs
 * @param {Object} degreeSelection - the degree we want to filter by
 * @param {Object} parentCategorySelection - the parent category we want to filter by
 */
export default function useProgramSelection({
  programsMap = [],
  initialValues = [],
  degreeSelection,
  parentCategorySelection,
}) {
  const [currentSelections, setCurrentSelections] = useState({
    programs: uniqBy(initialValues, 'guid'),
    isNotFoundList: false,
  });

  // Each time degree or parent category changes recalculate selections
  useEffect(() => {
    let selection = initialValues;
    let isNotFoundList = false;

    try {
      const isDegreeSelected =
        degreeSelection.value !== DEFAULT_SELECT_VALUE.value;
      const isParentCatSelected =
        parentCategorySelection.value !== DEFAULT_SELECT_VALUE.value;

      // the first case handles if user selects a parent category, then check if they also have degreeSelection
      if (isParentCatSelected) {
        selection = programsMap.filter((program) => {
          const shouldShowProgram = program.parentCategory.includes(
            parentCategorySelection.value
          );

          // if could be the user have both dropdowns selected
          if (isDegreeSelected) {
            return (
              shouldShowProgram &&
              program.degreeLevels.includes(degreeSelection.value)
            );
          }

          return shouldShowProgram;
        });
      } else if (isDegreeSelected) {
        // if no parent category but only degreeSelection then just search all programs with degree
        selection = programsMap.filter((program) =>
          program.degreeLevels.includes(degreeSelection.value)
        );
      } else {
        selection = [].concat(initialValues, programsMap);
      }

      // T1-5508: if empty result set only take parentCategory into account
      if (isParentCatSelected && !selection.length) {
        selection = programsMap.filter((program) => {
          const shouldShowProgram = program.parentCategory.includes(
            parentCategorySelection.value
          );

          return shouldShowProgram;
        });

        isNotFoundList = true;
      }

      // I don't think we should ever get into a state were there are no programs to show the user
      if (!selection.length) {
        LogError(
          'Warning: No programmes have been shown to the user on program search module',
          { degreeSelection, parentCategorySelection }
        );
      }

      setCurrentSelections({
        programs: uniqBy(selection, 'guid'),
        isNotFoundList,
      });
    } catch (error) {
      LogError(error, { degreeSelection, parentCategorySelection });
      setCurrentSelections({
        programs: uniqBy(initialValues, 'guid'),
        isNotFoundList,
      });
    }
  }, [degreeSelection, parentCategorySelection]);

  return {
    selectedPrograms: currentSelections.programs,
    isNotFoundList: currentSelections.isNotFoundList,
  };
}
