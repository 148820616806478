import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const HelpText = ({ text, isVisible = true, fieldIsFocused }) => {
  if (isVisible) {
    return (
      <div
        className="formField__helpText"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  }

  return (
    <CSSTransition
      in={fieldIsFocused}
      className="formField__helpText"
      classNames="formField__helpText"
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </CSSTransition>
  );
};

HelpText.propTypes = {
  text: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  fieldIsFocused: PropTypes.bool,
};

export default HelpText;
