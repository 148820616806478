/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { LogError } from 'utils/logging';

class DefaultErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    const { meta = {} } = this.props;

    LogError(error, meta);
  }

  render() {
    const { hasError } = this.state;
    const {
      children,
      message = 'We apologize but something seems to have gone wrong rendering this part of the site.',
    } = this.props;

    if (hasError) {
      return <div>{message}</div>;
    }

    return children;
  }
}

DefaultErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.object,
};

export default DefaultErrorBoundary;
