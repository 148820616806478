import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { TransitionGroup } from 'react-transition-group';
import { NavItemProps } from 'utils/propTypes';
import MegaMenuContext from './MegaMenuContext';
import MegaMenuSection from './MegaMenuSection';

const MegaMenuMenus = ({ subNav, isSelected, parent, currentBreakpoint }) => {
  const { getNextMenuSections } = useContext(MegaMenuContext);

  const nextSections = useMemo(() => {
    return getNextMenuSections(subNav, parent);
  }, [subNav, parent, currentBreakpoint]);

  // skip rendering if there's no subnav or this item isn't selected
  if (!isSelected || !subNav || !subNav.length) {
    return null;
  }

  return (
    <TransitionGroup component="div" className="megaMenu__menus">
      {isSelected &&
        Object.keys(nextSections).map((section) => {
          return (
            <MegaMenuSection
              navItems={nextSections[section]}
              key={`megaMenuSection_${section}`}
              currentBreakpoint={currentBreakpoint}
              title={get(nextSections[section], '[0].parent.label', '')}
              sectionNavLevel={get(nextSections[section], '[0].level', 0)}
            />
          );
        })}
    </TransitionGroup>
  );
};

MegaMenuMenus.propTypes = {
  currentBreakpoint: PropTypes.string,
  isSelected: PropTypes.bool,
  parent: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }),
  subNav: PropTypes.arrayOf(NavItemProps),
};

export default MegaMenuMenus;
