import { BasicFormWizardContext, MicroPortalLeadSubmitResults } from 'types';
import {
  FLOODLIGHT_EVENTS,
  trackFloodlightActivity,
  trackGAConversion,
} from './trackingFunctions';
import { LogError } from './logging';

/**
 * @summary This function is used to track the conversion of a form submission.
 */
export function handleConversionTracking(
  formSubmitResponse: MicroPortalLeadSubmitResults,
  formConfigs: BasicFormWizardContext['formConfigs'],
  metaData: {
    floodlightActivityValues: object;
    floodLightActivityFilters: object;
  }
): MicroPortalLeadSubmitResults {
  const { shouldTrackConversion, summary, leadsSubmittedFor } =
    formSubmitResponse;

  if (!shouldTrackConversion) {
    return formSubmitResponse;
  }

  try {
    // for multi submit
    const resultSummary = summary || {};
    // for single submit
    const [submittedUser] = leadsSubmittedFor || [];
    const revenue = resultSummary.revenue || submittedUser?.revenue;
    const adjustedRevenue =
      resultSummary.adjustedRevenue || submittedUser?.adjustedRevenue;
    const floodlightValues = metaData?.floodlightActivityValues; // should always be present, from server
    const userFilterValues = metaData?.floodLightActivityFilters; // getUserSession, from client side

    trackGAConversion(revenue, adjustedRevenue);

    if (floodlightValues && userFilterValues) {
      trackFloodlightActivity(
        FLOODLIGHT_EVENTS.PROFILE_PAYABLE_USER,
        floodlightValues,
        userFilterValues,
        {
          revenue,
          adjustedRevenue,
          schoolCode: formConfigs.schoolCode,
        }
      );
    } else {
      // Do we want to know if this was not tracked?
    }
  } catch (error: unknown) {
    LogError(
      `Failed to track Floodlight Activity: ${(error as Error).message}`
    );
  }

  return formSubmitResponse;
}
