import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccordionContext from './AccordionContext';
import AccordionPanel from './AccordionPanel';

function Accordion({
  children,
  activePanelIndex,
  onUpdateCallback = () => {},
}) {
  const [activeIndex, setActiveIndex] = useState(activePanelIndex);

  useEffect(() => {
    onUpdateCallback(activeIndex);
  }, [activeIndex]);

  function validatePanel(child) {
    if (child.type !== AccordionPanel) {
      console.error(
        'Only AccordionPanel components can be direct children of Accordion'
      );
      return false;
    }
    return true;
  }

  const nextPanels = React.Children.toArray(children).filter(validatePanel);

  if (!nextPanels.length) {
    return null;
  }

  return (
    <AccordionContext.Provider value={{ activeIndex, setActiveIndex }}>
      <div className="accordion" data-testid="accordion">
        {nextPanels.map((child, index) => {
          return React.cloneElement(child, {
            key: child.props.id ? `${child.props.id}_${index}` : index,
            index,
          });
        })}
      </div>
    </AccordionContext.Provider>
  );
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  activePanelIndex: PropTypes.number,
  onUpdateCallback: PropTypes.func,
};

export default Accordion;
