import React from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';

const LoadingButton = ({ onClick, className, disabled, text, loading }) => {
  const buttonClassName = cx({
    loadingButton: true,
    loadingButton__isDisabled: disabled,
    loadingButton__isLoading: loading,
    [className]: !!className,
    [`${className}__isDisabled`]: disabled,
    [`${className}__isLoading`]: loading,
  });

  return (
    <button
      type="button"
      onClick={onClick}
      className={buttonClassName}
      disabled={disabled}
      data-testid="loadingButton"
    >
      <div className="loadingButton__inner">
        <span className="loadingButton__text">{text}</span>
        {loading && (
          <span className="loadingButton__loader">
            <span className="loadingButton__loadingText">Loading...</span>
            <span className="loadingButton__loadingIcon" />
          </span>
        )}
      </div>
    </button>
  );
};

LoadingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default LoadingButton;
