import React from 'react';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';

interface Props {
  title: string;
}

export default function MultiStepTitle({ title }: Props): React.JSX.Element {
  const tokenizedTitle = useTokenReplacement(title);

  return <div className="multiStepLoader__itemTitle">{tokenizedTitle}</div>;
}
