/**
 * @summary this file as all the component mappings that Wordpress will be able to render
 */
import isEmpty from 'lodash/isEmpty';
import { LogError } from 'utils/logging';

import { FIELD_TYPES as aft } from 'components/AVAILABLE_COMPONENTS';

import Disclaimer from 'components/base/inputs/disclaimer/Disclaimer';
import GroupedButtonSelect from 'components/base/selects/groupedButtonSelect/GroupedButtonSelect';
import Input from 'components/base/inputs/base-input/Input';
import EmailInput from 'components/base/inputs/emailInput/EmailInput';
import MultiSelect from 'components/base/multiSelect/MultiSelect';
import NumberInput from 'components/base/inputs/numberInput/NumberInput';
import PhoneInput from 'components/base/inputs/phoneInput/PhoneInput';
import RadioGroup from 'components/base/radioGroup/RadioGroup';
import RadioGroupBoolean from 'components/base/radioGroup/RadioGroupBoolean';
import RadioGroupButtons from 'components/base/radioGroup/RadioGroupButtons';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import ZipCodeInput from 'components/base/inputs/zipCodeInput/ZipCodeInput';
import DynamicDisclaimer from './base/inputs/dynamicDisclaimer/DynamicDisclaimer';
import AdditionalSchoolsDisclaimer from './base/inputs/additionalSchoolsDisclaimer/AdditionalSchoolsDisclaimer';
import { SelectionCard } from './form-wizards/micro-portal/SelectionCard/SelectionCard';
import { MultiSchoolSelect } from './base/inputs/multiSchoolSelect/MultiSchoolSelect';

const COMPONENT_FIELD_TYPE_MAP = {
  [aft.ADDITIONAL_SCHOOLS_DISCLAIMER]: AdditionalSchoolsDisclaimer,
  [aft.INPUT]: Input,
  [aft.EMAIL]: EmailInput,
  [aft.NUMBER]: NumberInput,
  [aft.PHONE]: PhoneInput,
  [aft.SELECT]: ReactSelect,
  [aft.MULTISELECT]: MultiSelect,
  [aft.RADIO]: RadioGroup,
  [aft.RADIO_BUTTONS]: RadioGroupButtons,
  [aft.BOOLEAN]: RadioGroupBoolean,
  [aft.GROUPED_BUTTON_SELECT]: GroupedButtonSelect,
  [aft.DISCLAIMER]: Disclaimer,
  [aft.DYNAMIC_DISCLAIMER]: DynamicDisclaimer,
  [aft.MULTI_SCHOOL_SELECT]: MultiSchoolSelect,
  [aft.SELECTION_CARD]: SelectionCard,
  [aft.ZIPCODE]: ZipCodeInput,
};

function getField(defaultType, controlOverrides, currentBreakpoint) {
  let key = defaultType;

  if (!isEmpty(controlOverrides) && currentBreakpoint) {
    key = controlOverrides[currentBreakpoint] || key;
  }

  if (!COMPONENT_FIELD_TYPE_MAP[key]) {
    LogError(`Field Component Not Found: ${key} is not a valid Field`);
    throw new Error(`Field Component Not Found: ${key} is not a valid Field`);
  }

  return COMPONENT_FIELD_TYPE_MAP[key];
}

export default getField;
