/* eslint-disable react/no-array-index-key */
import React, { useRef, useLayoutEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SlidesPropTypes } from 'utils/propTypes';
import { BREAKPOINT_MAP } from 'consts';
import GlobalContext from 'hooks/contexts/GlobalContext';
import Slide from './Slide';

function Slides({
  slides,
  slidePosition,
  shouldTransition,
  options,
  handleGotoNext,
  handleGotoPrev,
}) {
  const infiniteSlides = [slides[slides.length - 1], ...slides, slides[0]];
  const slidesRef = useRef(null);
  const [slidesWidth, setSlidesWidth] = useState(null);
  const [touchStatus, setTouchStatus] = useState({ initialX: 0, finalX: 0 });

  const {
    windowSize: { width, currentBreakpoint },
  } = useContext(GlobalContext);

  useLayoutEffect(() => {
    if (slidesRef.current) {
      const currentWidth = slidesRef.current.clientWidth;
      setSlidesWidth(currentWidth);
    }
  }, [slidesRef, width]);

  const isMobile =
    currentBreakpoint === BREAKPOINT_MAP.MOBILE.label ||
    currentBreakpoint === BREAKPOINT_MAP.TABLET.label;

  const slideWidthPercentage = isMobile ? 80 : 100;

  const getSlidePosition = () => {
    const slideWidth = slidesWidth * (slideWidthPercentage / 100);
    const offset = (slidesWidth - slideWidth) / 2;

    return (slideWidth * slidePosition - offset) * -1;
  };

  const handleTouchStart = (e) => {
    setTouchStatus({
      initialX: e.touches[0].clientX,
    });
  };

  const handleTouchMove = (e) => {
    setTouchStatus({
      ...touchStatus,
      finalX: e.touches[0].clientX,
    });
  };

  const handleTouchEnd = () => {
    const xDistance = touchStatus.initialX - touchStatus.finalX;
    // length of swipe before considering it an intentional swipe
    const swipeThreshold = slidesWidth / 4;

    if (Math.abs(xDistance) < swipeThreshold) {
      return;
    }
    xDistance < 0 ? handleGotoPrev() : handleGotoNext();
  };

  return (
    <div className="slides" ref={slidesRef}>
      <ol
        className={
          shouldTransition
            ? 'slides__list slides__list--shouldTransition'
            : 'slides__list'
        }
        style={{
          width: `${infiniteSlides.length * 100}%`,
          transform: `translate3d(${getSlidePosition()}px, 0, 0)`,
        }}
      >
        {infiniteSlides.map((slide, index) => (
          <li
            className="slides__slide"
            key={`slides__slide${index}`}
            aria-hidden={slidePosition !== index}
            style={{ width: slidesWidth * (slideWidthPercentage / 100) }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <Slide slide={slide} id={`slide_${index}`} options={options} />
          </li>
        ))}
      </ol>
    </div>
  );
}

Slides.propTypes = {
  handleGotoNext: PropTypes.func,
  handleGotoPrev: PropTypes.func,
  options: PropTypes.shape({
    background: PropTypes.string,
    customCssClass: PropTypes.string,
    imageAlignment: PropTypes.string,
    isReversed: PropTypes.bool,
    mediaWidth: PropTypes.string,
    variation: PropTypes.string,
  }),
  shouldTransition: PropTypes.bool,
  slidePosition: PropTypes.number,
  slides: SlidesPropTypes,
};

export default Slides;
