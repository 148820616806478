import React, { useContext } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { FIELD_NAMES } from 'consts';
import getFormSelectionObjects from 'components/form-wizards/click-portal-search/utils/getFormSelectionObjects';
import getCurrentStepIndexFromSelection from 'components/form-wizards/click-portal-search/utils/getCurrentStepIndexFromSelection';
import { Step } from 'types';
import Steps from './Steps';

const {
  CLICK_PORTAL_DEGREES,
  CLICK_PORTAL_CONCENTRATIONS,
  CLICK_PORTAL_SUBJECTS,
} = FIELD_NAMES;

const steps: Step[] = [
  {
    id: 1,
    title: 'Choose your Degree Level',
    name: CLICK_PORTAL_DEGREES,
    subTitle: '',
    heading: '1',
    groupLabel: '',
    questions: [],
  },
  {
    id: 2,
    title: 'Choose your Subject',
    name: CLICK_PORTAL_SUBJECTS,
    subTitle: '',
    heading: '2',
    groupLabel: '',
    questions: [],
  },
  {
    id: 3,
    title: 'Choose your Concentration',
    name: CLICK_PORTAL_CONCENTRATIONS,
    subTitle: '',
    heading: '3',
    groupLabel: '',
    questions: [],
  },
];

/**
 * This component renders a series of steps with selections for a click portal search.
 * It uses the GlobalContext to access current selections and taxonomy data.
 * The component defines the steps for choosing degree level, subject, and concentration.
 * It also provides utility functions to get step labels and determine the current step index.
 */
function StepsWithSelections(): React.ReactNode {
  const {
    microSiteTaxonomyMap,
    clickPortal: { currentSelection },
    refs: { clickPortalSearchResultsRef },
  } = useContext(GlobalContext);

  const { degree, category, subject } = getFormSelectionObjects(
    currentSelection,
    microSiteTaxonomyMap
  );

  function getStepLabel(stepName: string): string {
    switch (stepName) {
      case CLICK_PORTAL_DEGREES:
        return degree?.label;
      case CLICK_PORTAL_CONCENTRATIONS:
        return category?.label;
      case CLICK_PORTAL_SUBJECTS:
        return subject?.label;

      default:
        return '';
    }
  }

  const nextSteps = steps.map((step) => {
    const stepLabel = getStepLabel(step.name);

    const nextTitle = stepLabel ? `You Chose ${stepLabel}` : step.title;
    return {
      ...step,
      title: nextTitle,
    };
  });

  return (
    <Steps
      currentStepIndex={getCurrentStepIndexFromSelection(currentSelection)}
      steps={nextSteps}
      stepsContainerRef={
        clickPortalSearchResultsRef as React.RefObject<HTMLDivElement> | null
      }
    />
  );
}

export default StepsWithSelections;
