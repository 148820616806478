// TODO [FSIU-59]: remove the use of useMultiFormStateSync. This is causing issues with many re-renders
import GlobalContext from 'hooks/contexts/GlobalContext';

import { useContext, useEffect } from 'react';
import { getSyncKey } from 'utils/formValuesUtils';

export default function useMultiFormStateSync(formContext) {
  const {
    reprocessForm,
    formStatus: { isInitializing },
  } = formContext;

  const {
    clickPortal: { currentSelection, filterKey },
  } = useContext(GlobalContext);

  const globalKey = getSyncKey(currentSelection);

  // pull
  useEffect(() => {
    if (!isInitializing && globalKey) {
      reprocessForm(currentSelection);
    } else if (!isInitializing && filterKey) {
      reprocessForm(currentSelection);
    }
  }, [globalKey, isInitializing]);

  return {};
}
