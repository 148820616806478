import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { OptionsPropType } from 'utils/propTypes';
import Input from 'components/base/inputs/base-input/Input';
import camelCase from 'lodash/camelCase';
import cx from 'utils/classnames';

export default function RadioGroup({
  name,
  onChange,
  onBlur,
  options = [],
  value,
  valueTransform = (v) => v,
  fieldRef,
  className,
}) {
  const radioClass = cx({
    radioGroup: true,
    [className]: className,
  });

  const _handleOnClick = (event) => {
    onChange(valueTransform(event.target.value), {
      name: event.target.name,
      event,
    });
  };

  // It could be an object loaded from a cloned form
  const currentValue = get(value, 'value', value);

  return (
    <ul className={radioClass}>
      {options.map((option, index) => (
        <li className="radioGroup__item" key={option.value}>
          <div className="radioGroup__option">
            <Input
              type="radio"
              fieldRef={!index ? fieldRef : undefined}
              className="radioGroup__optionInput"
              name={name}
              id={`${camelCase(name)}_${index}`}
              value={option.value}
              checked={option.value === currentValue}
              onClick={_handleOnClick}
              onBlur={onBlur}
            />
            <label
              htmlFor={`${camelCase(name)}_${index}`}
              className="radioGroup__optionLabel"
            >
              <span className="radioGroup__optionLabelText">
                {option.label}
              </span>
            </label>
          </div>
        </li>
      ))}
    </ul>
  );
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  fieldRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: OptionsPropType,
  value: PropTypes.string,
  valueTransform: PropTypes.func,
};
