import React from 'react';
import PropTypes from 'prop-types';

const ProgramMeta = ({ accreditation, nextStartDate }) => {
  return (
    <ul className="programMeta">
      {accreditation && (
        <li className="programMeta__item">
          <span className="programMeta__key">Accreditation</span>
          <span className="programMeta__value">{accreditation}</span>
        </li>
      )}
      {nextStartDate && (
        <li className="programMeta__item">
          <span className="programMeta__key">Next Start Date</span>
          <span className="programMeta__value">{nextStartDate}</span>
        </li>
      )}
    </ul>
  );
};

ProgramMeta.propTypes = {
  accreditation: PropTypes.string,
  nextStartDate: PropTypes.string,
};

export default ProgramMeta;
