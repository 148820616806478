/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import PropTypes from 'prop-types';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import { FooterLinksPropTypes } from 'utils/propTypes';
import FooterLink from './FooterLink';

function ClickPortalSiteFooter({ siteName, footerText, footerLinks }) {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const tokenizedWysiwygText = useTokenReplacement(footerText);

  return (
    <footer className="footer footer--clickPortal" role="contentinfo">
      <div className="footer__inner">
        <div className="footer__section">
          <div className="footer__logo">
            <span className="isVisuallyHidden">{siteName}</span>
          </div>
          <div className="footer__nav">
            <div className="footer__navSection">
              {footerLinks && (
                <ul className="footer__links">
                  {footerLinks.map((footerLink) => (
                    <li key={footerLink.link} className="footer__linkItem">
                      <FooterLink
                        link={footerLink.link}
                        label={footerLink.label}
                        trigger={footerLink.trigger}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="footer__section">
          <div className="footer__text">
            <Wysiwyg content={tokenizedWysiwygText} />
            <div className="footer__attribution" suppressHydrationWarning>
              {`© ${currentYear} `}
              <a
                href="https://www.triadms.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                TriAd Media Solutions, Inc.
              </a>
              {' All Rights Reserved'}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

ClickPortalSiteFooter.propTypes = {
  siteName: PropTypes.string,
  footerText: PropTypes.string,
  footerLinks: FooterLinksPropTypes,
};

export default ClickPortalSiteFooter;
