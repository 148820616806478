import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from 'hooks/contexts/GlobalContext';

function SchoolLogo({ logo, alt, className }) {
  const {
    windowSize: { currentBreakpoint = 'mobile' },
  } = useContext(GlobalContext);

  const isMobile = currentBreakpoint === 'mobile';

  function _getSchoolLogoUrl(imageMap = {}) {
    return isMobile ? imageMap.mobile : imageMap.desktop;
  }

  return <img src={_getSchoolLogoUrl(logo)} alt={alt} className={className} />;
}

SchoolLogo.propTypes = {
  logo: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default SchoolLogo;
