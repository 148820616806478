/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import { NOP } from 'utils/generalUtils';
import { OptionsPropType } from 'utils/propTypes';

const ANIMATED_LABEL_CAPABLE_INPUT_TYPES = [
  'text',
  'number',
  'email',
  'search',
];

function Input({
  animatedLabel,
  className = '',
  disabled = false,
  fieldRef,
  hasDefault,
  isFocused,
  name,
  onChange = NOP,
  onEnter = () => {},
  options,
  placeholder,
  showCityState,
  type = 'text',
  value = '',
  valueTransform = (v) => v,
  ...rest
}) {
  const _onChange = (event) => {
    onChange(valueTransform(event.target.value), {
      name: event.target.name,
      event,
    });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      onEnter();
    }
  };

  const inputClass = cx({
    input: type !== 'radio', // radios shouldn't inherit the input class, visually they are too different
    [className]: className,
  });

  const inputWrapClass = cx({
    inputWrap: true,
    'inputWrap--hasAnimatedLabel': !!animatedLabel,
    'inputWrap--hasValue': (value && value.length > 0) || isFocused,
  });

  const inputComponent = (
    <input
      {...rest}
      onKeyDown={onKeyDown}
      ref={fieldRef}
      value={value}
      name={name}
      aria-label={name}
      onChange={_onChange}
      type={type}
      disabled={disabled}
      placeholder={!animatedLabel ? placeholder : ''}
      className={inputClass}
    />
  );

  if (animatedLabel && ANIMATED_LABEL_CAPABLE_INPUT_TYPES.includes(type)) {
    return (
      <div className={inputWrapClass}>
        {inputComponent}
        <span className="inputWrap__label">{animatedLabel}</span>
      </div>
    );
  }

  return inputComponent;
}

Input.propTypes = {
  animatedLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.shape({}),
  hasDefault: PropTypes.bool,
  isFocused: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  showCityState: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  valueTransform: PropTypes.func,
  options: PropTypes.oneOfType([
    OptionsPropType,
    PropTypes.arrayOf([PropTypes.string, PropTypes.number]),
  ]),
};

export default Input;
