import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';

import classnames from 'utils/classnames';

const MainNavMenuColumn = ({ columnName, menuItems, index, currentKey }) => {
  const getColumnClassName = (i) => {
    return classnames([
      'mainNavMegaMenu__column',
      `mainNavMegaMenu__column--${i + 1}`,
    ]);
  };

  return (
    <div className={getColumnClassName(index)} key={currentKey}>
      <ul className="mainNavMegaMenu__menuItems">
        {menuItems.map((item, i) => (
          <li
            className="mainNavMegaMenu__menuItem"
            // eslint-disable-next-line react/no-array-index-key
            key={`${camelCase(columnName)}_submenu_${i}`}
          >
            <a href={item.url} className="mainNavMegaMenu__menuItemLink">
              {item.icon && (
                <img
                  src={item.icon.url}
                  alt=""
                  className="mainNavMegaMenu__menuItemImg"
                />
              )}
              <span className="mainNavMegaMenu__menuItemLabel">
                {item.label}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

MainNavMenuColumn.propTypes = {
  columnName: PropTypes.string,
  index: PropTypes.string,
  currentKey: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
};

export default MainNavMenuColumn;
