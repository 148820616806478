import React from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import useProgramGrouping from 'hooks/custom/programs/useProgramGrouping';
import { ProgramsPropType } from 'utils/propTypes';
import ProgramCard from 'components/blocks/cards/expandable-program-card/ProgramCard';

export default function GroupedProgramsContainer({
  callToActionBehavior,
  currentSelection,
  isNotFoundList = false,
  onCtaClick,
  programs,
  showProgramInfoStats,
}) {
  const {
    groupedPrograms: { main = {}, related = {}, other = {} },
  } = useProgramGrouping({
    programs,
    isNotFoundList,
  });

  const orderedGroup = [main, related, other].filter(
    (group) => group.programs.length
  );

  return (
    <div className="groupedProgramsContainer">
      {orderedGroup.map((group, index) => (
        <div
          key={group.title}
          className={cx('groupedProgramsContainer__group', {
            'groupedProgramsContainer__group--isHidden': !group.programs.length,
          })}
        >
          {/* index > 0 is for not to render for Main or if user selection is active, which will put all programs in Other */}
          {/* isNotFoundList is for T1-5508: if empty result set we must show header */}
          {(index > 0 || isNotFoundList) && (
            <>
              {isNotFoundList && (
                <span className="groupedProgramsContainer__noMatchesText">
                  {`Sorry, there are no exact matches for ${currentSelection?.degreeSelection?.label} in ${currentSelection?.parentCategorySelection?.label}`}
                </span>
              )}
              <h2 className="groupedProgramsContainer__title">{group.title}</h2>
            </>
          )}
          <div>
            {group.programs.map((program) => {
              return (
                <ProgramCard
                  key={program.id}
                  program={program}
                  onCtaClick={onCtaClick}
                  callToActionBehavior={callToActionBehavior}
                  showProgramInfoStats={showProgramInfoStats}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}

GroupedProgramsContainer.propTypes = {
  callToActionBehavior: PropTypes.string,
  programs: ProgramsPropType.isRequired,
  onCtaClick: PropTypes.func,
  isNotFoundList: PropTypes.bool,
  currentSelection: PropTypes.shape({
    degreeSelection: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    parentCategorySelection: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  showProgramInfoStats: PropTypes.bool,
};
