import React from 'react';
import PropTypes from 'prop-types';
import { OptionsPropType } from 'utils/propTypes';
import RadioGroup from 'components/base/radioGroup/RadioGroup';

const RadioGroupButtons = (props) => {
  return <RadioGroup className="radioGroup--buttons" {...props} />;
};

RadioGroupButtons.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: OptionsPropType,
  className: PropTypes.string,
  value: PropTypes.string,
  fieldRef: PropTypes.shape({}),
};

export default RadioGroupButtons;
