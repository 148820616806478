import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WPImagesPropType } from 'utils/propTypes';
import GlobalContext from 'hooks/contexts/GlobalContext';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import ResponsiveImage from 'components/base/responsiveImage/ResponsiveImage';
import VideoEmbed from 'components/base/videoEmbed/VideoEmbed';
import Modal from 'components/base/modals/Modal';

function MultiMedia({ videoEmbedId, images, responsiveImageSizes, id }) {
  const { actions } = useContext(GlobalContext);

  const onMultiMediaActionClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    actions.setActiveModalId(id);
  };

  if (!videoEmbedId) {
    return (
      <DefaultErrorBoundary meta={{ component: 'ResponsiveImage' }}>
        <ResponsiveImage
          images={images}
          sizes={responsiveImageSizes}
          loading="lazy"
        />
      </DefaultErrorBoundary>
    );
  }
  return (
    <div className="multiMedia">
      <div className="multiMedia__img">
        <DefaultErrorBoundary meta={{ component: 'ResponsiveImage' }}>
          <ResponsiveImage
            images={images}
            sizes={responsiveImageSizes}
            loading="lazy"
          />
        </DefaultErrorBoundary>
      </div>
      <button
        type="button"
        className="multiMedia__action"
        onClick={onMultiMediaActionClick}
      >
        Play
      </button>
      <Modal
        id={id}
        customHandler={actions.updateGlobalFlags}
        className="modal--isVideo"
      >
        <VideoEmbed embedId={videoEmbedId} />
      </Modal>
    </div>
  );
}

MultiMedia.propTypes = {
  images: WPImagesPropType,
  responsiveImageSizes: PropTypes.shape({
    MOBILE: PropTypes.string,
    TABLET: PropTypes.string,
    DESKTOP: PropTypes.string,
    DEFAULT: PropTypes.string,
  }),
  videoEmbedId: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default MultiMedia;
