import { PROGRAM_GROUP_NAMES } from 'consts';
import { useState, useEffect } from 'react';

const { MAIN, RELATED, OTHER } = PROGRAM_GROUP_NAMES;

const generateNewGrouping = () => ({
  [MAIN]: {
    title: 'Main Programs',
    programs: [],
  },
  [RELATED]: {
    title: 'Related Programs',
    programs: [],
  },
  [OTHER]: {
    title: 'Other Programs',
    programs: [],
  },
});

export default function useProgramGrouping({ programs = [], isNotFoundList }) {
  const [currentGroupings, setCurrentGroupings] = useState(
    generateNewGrouping()
  );

  // Each time programs change re-group them
  useEffect(() => {
    const _currentGroupings = generateNewGrouping();
    const featuredProgram = [];
    const expandedProgram = [];
    const otherProgram = [];

    programs.forEach((program) => {
      const { isFeatured, isExpanded } = program;
      if (isFeatured) {
        featuredProgram.push(program);
      } else if (isExpanded) {
        expandedProgram.push(program);
      } else {
        otherProgram.push(program);
      }
    });

    // note: never sort. all sorting logic must be done in useProgramEnrichment as there is alot of logic around that
    const sortedPrograms = [].concat(
      featuredProgram,
      expandedProgram,
      otherProgram
    );

    // Start adding programs to their respective groups
    sortedPrograms.forEach((program) => {
      let group = _currentGroupings[(program.groupType || '').toLowerCase()];

      // T1-5508: if this list was produced due to there being no matching results then add to the related group
      if (isNotFoundList) {
        group = _currentGroupings.related;
      } else if (!group) {
        group = _currentGroupings.other;
      }
      group.programs.push(program);
    });

    setCurrentGroupings(_currentGroupings);
  }, [programs]);

  return { groupedPrograms: currentGroupings };
}
