import getVisibleQuestions from 'utils/form-utils/getVisibleQuestions';
import { isNextStepDynamic } from 'components/form-wizards/basic-form/utils';
import {
  doPreviousStepsHaveVisibleQuestions,
  getValidQuestions,
} from 'utils/formValuesUtils';
import { LogError } from 'utils/logging';

/**
 * @summary this function is used to push the user to the previous step
 * @param {Object} state - current state
 */
export default function moveBack(state) {
  const { formConfigs, currentStepIndex } = state;
  const totalSteps = formConfigs.steps.length;
  const nextStepIndex = currentStepIndex - 1;
  const step = formConfigs.steps[nextStepIndex];

  // could be this is the first step, so make no changes
  if (!step) {
    // Browser back button to exit the page, mobile safari swipe edge case
    if (nextStepIndex !== -1) {
      LogError('BasicFormWizard moveBack Error: Moving to unknown Step', {
        formId: formConfigs.id,
      });
    }
    return state;
  }

  const prevStepQuestions = getValidQuestions(
    step.questions,
    state.formValues,
    state.allQuestionsInForm,
    state.formStatus.dynamicOptions
  );

  // it could be that this step has no question that match current responses
  const visibleQuestions = getVisibleQuestions(prevStepQuestions, state);

  if (!prevStepQuestions.length || !visibleQuestions.length) {
    return moveBack({ ...state, currentStepIndex: currentStepIndex - 1 });
  }

  const updatedState = {
    ...state,
    title: step.title || formConfigs.title,
    subTitle: step.subTitle || formConfigs.subTitle,
    currentStepIndex: nextStepIndex,
    isLastStep: step === formConfigs.steps[totalSteps - 1],
    currentQuestions: prevStepQuestions,
    totalSteps,
    ctaText: step.ctaText || 'Continue',
    currentAction: 'prev',
    isNextStepDynamic: isNextStepDynamic(state, nextStepIndex),
    formStatus: {
      ...state.formStatus,
      // if this is true we will keep skipping questions up to an empty one
      isResumingMode: false,
    },
  };

  updatedState.isFirstStep = !doPreviousStepsHaveVisibleQuestions(updatedState);

  return updatedState;
}
