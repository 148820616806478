import React from 'react';
import PropTypes from 'prop-types';

import MainNavMegaMenu from './MainNavMegaMenu';
import MainNavSubMenu from './MainNavSubMenu';

const MainNavSubMenus = ({ item }) => {
  if (!item.child_items && !item.megaMenu) {
    return null;
  }

  return item.megaMenu.columns ? (
    <MainNavMegaMenu columns={item.megaMenu.columns} />
  ) : (
    <MainNavSubMenu item={item} />
  );
};

MainNavSubMenus.propTypes = {
  item: PropTypes.shape({
    child_items: PropTypes.string,
    megaMenu: PropTypes.bool,
  }),
};

export default MainNavSubMenus;
