import React from 'react';
import {
  BasicFormWizardContext as ContextType,
  FormStatus,
  FormValues,
} from 'types';
import { initialState } from 'hooks/custom/forms/basic-wizard/reducer';

const basicFormWizardContextInitialState: ContextType = {
  allQuestionsInForm: null,
  currentStepIndex: initialState.currentStepIndex,
  formValues: {} as FormValues,
  formStatus: {
    isProcessing: initialState.formStatus.isProcessing,
    isSubmitting: initialState.formStatus.isSubmitting,
    dynamicOptions: {} as FormStatus['dynamicOptions'],
    stepsValidationStatus: [],
  },
  handleChange: (__fieldValue, __event) =>
    Promise.reject(new Error('Not implemented')),
  formConfigs: {
    schoolCode: '',
    variant: '',
  },
  fieldNameMap: {} as ContextType['fieldNameMap'],
  handleSubmit: () => { },
  currentQuestions: [],
};

const BasicFormWizardContext = React.createContext<ContextType>(
  basicFormWizardContextInitialState
);

export default BasicFormWizardContext;
