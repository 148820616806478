import { FIELD_NAMES } from 'consts';

const {
  CLICK_PORTAL_CONCENTRATIONS,
  CLICK_PORTAL_SUBJECTS,
  CLICK_PORTAL_DEGREES,
} = FIELD_NAMES;

export default function getCurrentStepIndexFromSelection(selection = {}) {
  let stepIndex = 0;
  if (selection[CLICK_PORTAL_CONCENTRATIONS]) {
    stepIndex = 2;
  } else if (selection[CLICK_PORTAL_SUBJECTS]) {
    stepIndex = 2;
  } else if (selection[CLICK_PORTAL_DEGREES]) {
    stepIndex = 1;
  }

  return stepIndex;
}
