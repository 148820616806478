import { useState } from 'react';

function useCarousel(slideCount: number): {
    currentIndex: number;
    gotoSlide: (index: number) => void;
    gotoNextSlide: () => void;
    gotoPrevSlide: () => void;
} {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const gotoSlide = (index: number): void => {
        setCurrentIndex(index);
    };

    const gotoNextSlide = (): void => {
        setCurrentIndex((prevIndex: number) => (prevIndex + 1) % slideCount);
    };

    const gotoPrevSlide = (): void => {
        setCurrentIndex((prevIndex: number) => (prevIndex - 1 + slideCount) % slideCount);
    };

    return { currentIndex, gotoSlide, gotoNextSlide, gotoPrevSlide };
}

export default useCarousel;
