/* eslint-disable react/no-array-index-key */
import React from 'react';
import cx from 'utils/classnames';

interface CarouselIndexNavProps {
  handleGotoSlide: (index: number) => void;
  slides: React.ReactElement[];
  currentIndex: number;
}

function CarouselIndexNav({
  handleGotoSlide,
  slides,
  currentIndex,
}: CarouselIndexNavProps): React.JSX.Element {
  const getNavButtonClass = (index: number): string =>
    cx({
      carouselIndexNav__button: true,
      'carouselIndexNav__button--isActive': currentIndex === index,
    });

  return (
    <ol className="carouselIndexNav">
      {slides.map((__, index) => (
        <li
          className="carouselIndexNav__item"
          key={`carouselIndexNavItem__${index}`}
        >
          <button
            type="button"
            className={getNavButtonClass(index)}
            onClick={() => handleGotoSlide(index)}
            aria-label={`Go to slide ${index + 1}`}
          >
            {index + 1}
          </button>
        </li>
      ))}
    </ol>
  );
}

export default CarouselIndexNav;
