import React from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';

const ProgramPager = ({
  onPagerClick,
  pageCount,
  currentPageIndex,
  pageCountOffset = 2,
}) => {
  const getPageBtnClass = (pageIndex) =>
    cx({
      programPager__pageBtn: true,
      'programPager__pageBtn--isActive': currentPageIndex === pageIndex,
    });
  if (pageCount <= 1) {
    return null;
  }

  const shouldShowPrevNav = currentPageIndex > 0;
  const shouldShowNextNav = currentPageIndex < pageCount - 1;
  const renderableIndexes = [...Array(pageCountOffset).keys()]
    .map((offset) => {
      return [currentPageIndex - (offset + 1), currentPageIndex + (offset + 1)];
    })
    .concat(currentPageIndex)
    .flat()
    .filter((index) => index >= 0 && index < pageCount)
    .sort((a, b) => a - b);

  const hasLeftEllipsis = renderableIndexes[0] > 0;
  const hasRightEllipsis =
    renderableIndexes[renderableIndexes.length - 1] < pageCount - 1;

  return (
    <div className="programPager">
      <div className="programPager__info">
        Page
        <span className="programPager__infoCurrent">
          {currentPageIndex + 1}
        </span>
        of
        <span className="programPager__infoCount">{pageCount}</span>
      </div>
      {shouldShowPrevNav && (
        <button
          type="button"
          data-testid="page-prev"
          className="programPager__prev"
          onClick={() => onPagerClick(currentPageIndex - 1)}
        >
          Previous
        </button>
      )}
      {hasLeftEllipsis && <span className="programPager__ellipsis">...</span>}
      <ul className="programPager__pages">
        {renderableIndexes.map((pageIndex) => (
          <li className="programPager__page" key={`pager_${pageIndex}`}>
            <button
              type="button"
              data-testid={`page-${pageIndex + 1}`}
              className={getPageBtnClass(pageIndex)}
              onClick={() => onPagerClick(pageIndex)}
            >
              {pageIndex + 1}
            </button>
          </li>
        ))}
      </ul>
      {hasRightEllipsis && <span className="programPager__ellipsis">...</span>}
      {shouldShowNextNav && (
        <button
          type="button"
          data-testid="page-next"
          className="programPager__next"
          onClick={() => onPagerClick(currentPageIndex + 1)}
        >
          Next
        </button>
      )}
    </div>
  );
};
ProgramPager.propTypes = {
  onPagerClick: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageCountOffset: PropTypes.number,
  currentPageIndex: PropTypes.number.isRequired,
};
export default ProgramPager;
