import React from 'react';
import PropTypes from 'prop-types';

const SiteContent = ({ children }) => {
  return <main className="site__content">{children}</main>;
};

SiteContent.propTypes = {
  children: PropTypes.node,
};

export default SiteContent;
