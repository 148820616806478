import React, { useContext } from 'react';
import cx from 'utils/classnames';
import { NavItemProps } from 'utils/propTypes';
import MegaMenuAction from './MegaMenuAction';
import MegaMenuContext from './MegaMenuContext';
import { getActionType } from './megaMenuUtils';

const MegaMenuItem = ({ item }) => {
  const { id, level, label, link, customFunction, className } = item;
  const {
    getSelectedStatus,
    handlers: { handleMenuClick },
  } = useContext(MegaMenuContext);

  const isSelected = getSelectedStatus(id);

  const megaMenuItemClassName = cx({
    megaMenu__menuItem: true,
    'megaMenu__menuItem--isSelected': isSelected,
    [`megaMenu__menuItem--${className}`]: !!className,
  });

  return (
    <li className={megaMenuItemClassName}>
      <MegaMenuAction
        testId="megaMenuItemLink"
        isSelected={isSelected}
        id={id}
        level={level}
        label={label}
        onMenuItemClick={handleMenuClick}
        customFunction={customFunction}
        link={link}
        className="megaMenu__menuItemLink"
        action={getActionType(link, customFunction)}
      />
    </li>
  );
};

MegaMenuItem.propTypes = {
  item: NavItemProps,
};

export default MegaMenuItem;
