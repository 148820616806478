/**
 * @summary The Default Header For the site, This is also used as a fallback.
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import MainNav from 'components/sections/mainNav/MainNav';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';

function DefaultSiteHeader({ headertext = '', menuItems, siteName, innerRef }) {
  const headerClass = cx({
    header: true,
    'header--hasHeaderText': !!headertext,
  });

  return (
    <header className={headerClass} ref={innerRef}>
      <div className="header__inner">
        <div className="header__logo">
          <span className="isVisuallyHidden">{siteName}</span>
        </div>
        {menuItems.length > 0 && (
          <nav className="header__nav" role="navigation" aria-label="Main">
            <MainNav menuItems={menuItems} />
          </nav>
        )}
        {headertext && (
          <div className="header__secondary">
            <Wysiwyg content={headertext} />
          </div>
        )}
      </div>
    </header>
  );
}

DefaultSiteHeader.propTypes = {
  headertext: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  menuItems: PropTypes.array,
  siteName: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default forwardRef((props, ref) => (
  <DefaultSiteHeader {...props} innerRef={ref} />
));
