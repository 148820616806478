/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import Input from 'components/base/inputs/base-input/Input';
import { zipCodeTransform } from 'utils/fieldMaskAndTransforms';
import { getDataForZip } from 'app-requests/triadmsRequests';
import { FIELD_NAMES } from 'consts';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';

const { CITY, STATE } = FIELD_NAMES;

const ZipCodeInput = (props) => {
  const { formValues, setFieldError, formStatus } = useContext(
    BasicFormWizardContext
  );
  const currentCity = formValues[CITY];
  const currentState = formValues[STATE];

  const handleZipDataFetch = ({ isValid, city, state }) => {
    if (isValid) {
      props.onChange(city, { name: CITY });
      props.onChange(state, { name: STATE });
    } else if (currentCity) {
      props.onChange('', { name: CITY });
      props.onChange('', { name: STATE });
    }
  };

  const getZipInput = useCallback(
    debounce((data) => getDataForZip(data).then(handleZipDataFetch), 600),
    [currentCity]
  );

  const onZipUpdate = (...args) => {
    // clear out field error
    if (formStatus.errors[props.name]) {
      setFieldError(props.name, '');
    }

    const zip = args[0];
    getZipInput({ zip });
    props.onChange(...args);
  };

  // need to get the city name on load since it's not always present in return visit data
  useEffect(() => {
    if (props.value) {
      getZipInput({ zip: props.value });
    }
  }, []);

  return (
    <div className="zipCodeInput">
      <div className="zipCodeInput__field">
        <Input
          {...props}
          onChange={onZipUpdate}
          className="input--number"
          // type="number" Open React Bug 'e' wont trigger onchange https://github.com/facebook/react/issues/13499
          inputMode="numeric"
          placeholder={props.placeholder || 'ex. 12345'}
          valueTransform={zipCodeTransform}
        />
      </div>

      {currentCity && props.showCityState && (
        <div className="zipCodeInput__city">
          {currentState ? `${currentCity}, ${currentState}` : currentCity}
        </div>
      )}
    </div>
  );
};

ZipCodeInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.shape({}),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  showCityState: PropTypes.bool,
  value: PropTypes.string,
  valueTransform: PropTypes.func,
};

export default ZipCodeInput;
