import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/base/inputs/base-input/Input';

const EmailInput = (props) => {
  return <Input className="input input--email" type="email" {...props} />;
};

EmailInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.shape({}),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  valueTransform: PropTypes.func,
};

export default EmailInput;
