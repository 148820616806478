/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { SlidesPropTypes } from 'utils/propTypes';
import cx from 'utils/classnames';

function SliderIndexNav({ handleGotoSlide, slides, currentIndex }) {
  const getNavButtonClass = (index) =>
    cx({
      sliderIndexNav__button: true,
      'sliderIndexNav__button--isActive': currentIndex === index,
    });

  return (
    <ol className="sliderIndexNav">
      {slides.map((slide, index) => (
        <li
          className="sliderIndexNav__item"
          key={`sliderIndexNavItem__${index}`}
        >
          <button
            className={getNavButtonClass(index)}
            type="button"
            onClick={() => handleGotoSlide(index)}
            disabled={currentIndex === index}
          >
            {index}
          </button>
        </li>
      ))}
    </ol>
  );
}

SliderIndexNav.propTypes = {
  currentIndex: PropTypes.number,
  handleGotoSlide: PropTypes.func,
  slides: SlidesPropTypes,
};

export default SliderIndexNav;
