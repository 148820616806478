import CarouselDirectionNav from 'components/sections/carousel/CarouselDirectionNav';
import CarouselIndexNav from 'components/sections/carousel/CarouselIndexNav';
import useCarousel from 'components/sections/carousel/useCarousel';
import React, {
  Children,
  cloneElement,
  ReactElement,
  useRef,
  useEffect,
  useState,
} from 'react';

interface CarouselProps {
  children: React.ReactNode;
  onSlideAnimationStart?: (index: number) => void;
  onSlideAnimationComplete?: (index: number) => void;
  cardIsTransitioning?: boolean;
}

function Carousel({
  children,
  cardIsTransitioning,
  onSlideAnimationStart = (): void => {},
  onSlideAnimationComplete = (): void => {},
}: CarouselProps): React.ReactElement {
  const slides = Children.toArray(children).filter(React.isValidElement);
  const { currentIndex, gotoSlide, gotoNextSlide, gotoPrevSlide } = useCarousel(
    slides.length
  );

  const carouselRef = useRef<HTMLDivElement>(null);
  const [carouselWidth, setCarouselWidth] = useState(0);

  const [isScrolling, setIsScrolling] = useState(false);
  let scrollTimeout: NodeJS.Timeout;

  function handleScroll(): void {
    if (!isScrolling) {
      setIsScrolling(true);
      onSlideAnimationStart(currentIndex);
    }

    // Clear existing timeout
    clearTimeout(scrollTimeout);

    // Set new timeout
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
      onSlideAnimationComplete(currentIndex);
    }, 150); // Adjust timeout as needed
  }

  // add event handlers
  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('scroll', handleScroll);
        clearTimeout(scrollTimeout);
      }
    };
  }, [handleScroll]);

  // set the carousel width
  useEffect(() => {
    if (carouselRef.current) {
      setCarouselWidth(carouselRef.current.offsetWidth);
    }
  }, [carouselRef]);

  function scrollToSlide(index: number): void {
    if (carouselRef.current) {
      const slideWidth = carouselRef.current.offsetWidth;
      onSlideAnimationStart(index);
      carouselRef.current.scrollTo({
        left: slideWidth * index,
        behavior: 'smooth',
      });
    }
  }

  return (
    <div
      data-testid="carousel"
      className={`carousel ${
        cardIsTransitioning ? 'carousel--slideIsTransitioning' : ''
      }`}
      style={
        {
          '--carouselSlideCount': slides.length,
          '--carouselWidth': `${carouselWidth}`,
        } as React.CSSProperties
      }
    >
      <div className="carousel__inner">
        <div className="carousel__slides" ref={carouselRef}>
          <ol className="carousel__slidesList">
            {Children.map(slides, (child, index) =>
              cloneElement(child as ReactElement, {
                isActive: index === currentIndex,
                'aria-hidden': currentIndex !== index,
              })
            )}
          </ol>
        </div>
        {slides.length > 1 && (
          <div className="carousel__nav">
            <CarouselIndexNav
              currentIndex={currentIndex}
              slides={slides as React.ReactElement[]}
              handleGotoSlide={(index) => {
                onSlideAnimationStart(index);
                gotoSlide(index);
                scrollToSlide(index);
              }}
            />
            <CarouselDirectionNav
              handleGotoNext={() => {
                const nextIndex = (currentIndex + 1) % slides.length;
                onSlideAnimationStart(nextIndex);
                gotoNextSlide();
                scrollToSlide(nextIndex);
              }}
              handleGotoPrev={() => {
                const prevIndex =
                  (currentIndex - 1 + slides.length) % slides.length;
                onSlideAnimationStart(prevIndex);
                gotoPrevSlide();
                scrollToSlide(prevIndex);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Carousel;
