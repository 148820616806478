import React from 'react';
import PropTypes from 'prop-types';
import MainNavSubMenus from './MainNavSubMenus';

function MainNav({ menuItems }) {
  if (!menuItems) {
    return null;
  }
  return (
    <ul className="mainNav">
      {menuItems.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="mainNav__item" key={`level1_${index}`}>
          <a href={item.url}>{item.title}</a>
          <MainNavSubMenus item={item} />
        </li>
      ))}
    </ul>
  );
}

MainNav.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
};

export default MainNav;
