import React, { useEffect, useState } from 'react';
import cx from 'utils/classnames';
import { LogDebug } from 'utils/logging';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import MultiStepTitle from './MultiStepTitle';

interface MultiStepLoaderProps {
  duration: number; // Duration in milliseconds for the entire animation sequence
  isProcessing: boolean;
  onLoaderComplete: () => void;
  onLoaderStart: () => void;
}

interface ListItemState {
  id: number;
  title: string;
  visible: boolean;
  progress: number;
}

function logSlowLoader(percent: number): void {
  LogDebug(
    `MultiStepLoader:getIntervalDuration: long loading time at ${percent}%`
  );
}

export function MultiStepLoader(
  props: MultiStepLoaderProps
): React.JSX.Element {
  const {
    duration = 1500,
    isProcessing,
    onLoaderComplete,
    onLoaderStart,
  } = props;
  const [listItems, setListItems] = useState<ListItemState[]>([
    {
      id: 1,
      title: 'Searching schools that offer {SUBJECT} programs',
      visible: false,
      progress: 0,
    },
    {
      id: 2,
      title: 'Matching with your experience',
      visible: false,
      progress: 0,
    },
    {
      id: 3,
      title: 'Checking program availability',
      visible: false,
      progress: 0,
    },
  ]);
  const intervalDuration = duration / listItems.length;
  const isProcessingRef = React.useRef(isProcessing);
  const progressIncrement = 10;
  const cssDelayPerStep = 600;

  const tokenizedTitle = useTokenReplacement(
    'Alright {FIRST_NAME}, let me find your matching schools...'
  );

  // Update the ref when the prop changes
  useEffect(() => {
    isProcessingRef.current = isProcessing;
  }, [isProcessing]);

  function getIntervalDuration(
    isLastStep: boolean,
    currentProgress: number
  ): number {
    if (isProcessingRef.current && isLastStep) {
      if (currentProgress >= 80) {
        logSlowLoader(currentProgress);
        return 3000;
      }

      if (currentProgress >= 60) {
        logSlowLoader(currentProgress);
        return 2500;
      }

      if (currentProgress >= 20) {
        logSlowLoader(currentProgress);
        return 1500;
      }
    }

    return intervalDuration / progressIncrement;
  }

  function startAnimation(
    currentItemIndex: number,
    currentProgress: number
  ): Promise<void> {
    if (currentItemIndex >= listItems.length) {
      return Promise.resolve();
    }

    const isLastStep = currentItemIndex === listItems.length - 1;

    // Show the next item
    if (!listItems[currentItemIndex].visible) {
      setListItems((prevItems) => {
        const newItems = [...prevItems];
        newItems[currentItemIndex].visible = true;
        return newItems;
      });
    }

    return new Promise((resolve) => {
      const progress = currentProgress + progressIncrement;
      const isStepComplete = progress >= 100;

      setTimeout(() => {
        setListItems((prevItems) => {
          const newItems = [...prevItems];
          newItems[currentItemIndex] = {
            ...newItems[currentItemIndex],
            progress,
          };
          return newItems;
        });

        startAnimation(
          isStepComplete ? currentItemIndex + 1 : currentItemIndex,
          isStepComplete ? 0 : progress
        ).then(() => {
          resolve();
        });
      }, getIntervalDuration(isLastStep, progress)); // Progress steps of 10% at each interval (slowed down for last item if conditions are met)
    });
  }

  useEffect(() => {
    onLoaderStart();
    startAnimation(0, 0).then(() => {
      setTimeout(() => {
        onLoaderComplete();
      }, cssDelayPerStep);
    });
  }, [duration]);

  return (
    <div className="multiStepLoader">
      <div className="multiStepLoader__title">{tokenizedTitle}</div>
      <ul className="multiStepLoader__items">
        {listItems.map((item) => {
          const multiStepLoaderItemClass = cx('multiStepLoader__item', {
            'multiStepLoader__item--isVisible': item.visible,
            'multiStepLoader__item--isComplete': item.progress === 100,
          });
          return (
            <li
              key={item.id}
              className={multiStepLoaderItemClass}
              style={
                {
                  '--multiStepLoaderProgress': `${item.progress}%`,
                } as React.CSSProperties
              }
            >
              <MultiStepTitle title={item.title} />
              <div className="multiStepLoader__itemProgress">
                <span className="multiStepLoader__progressText">
                  {item.progress}%
                </span>
              </div>
              <div className="multiStepLoader__itemIcon" />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
