import { validateQuestionsSync } from 'hooks/custom/forms/basic-wizard/utils/validateStep';
import { LogError } from 'utils/logging';
import { FormFieldValidationStatus } from 'consts';
import { BasicFormWizardContext, StepValidationInfo } from 'types';

const { VALID, INVALID, PENDING } = FormFieldValidationStatus;

/**
 * Retrieves the validation information for the current step of a form wizard.
 */
export function getFormStepValidationInfo(
  state: BasicFormWizardContext
): StepValidationInfo {
  const { formStatus, currentStepIndex, currentQuestions, formValues } = state;

  try {
    const stepValidationInfo =
      { ...formStatus.stepsValidationStatus[currentStepIndex] } || {};

    const stepValidationStatus = validateQuestionsSync(
      currentQuestions,
      formValues
    );

    const validationStatuses = Object.values(
      stepValidationStatus.validationStatus
    );

    stepValidationInfo.fieldValidations = stepValidationStatus.validationStatus;
    if (validationStatuses.some((status) => status === INVALID)) {
      stepValidationInfo.status = INVALID;
    } else if (validationStatuses.some((status) => status === PENDING)) {
      stepValidationInfo.status = PENDING;
    } else if (validationStatuses.every((status) => status === VALID)) {
      stepValidationInfo.status = VALID;
    } else {
      LogError('Form Step Validation Info has unknown status', {
        statuses: validationStatuses,
      });
      stepValidationInfo.status = VALID;
    }

    return stepValidationInfo;
  } catch (error: unknown) {
    LogError('Failed To Get Form Step Validation Info', {
      message: (error as Error).message,
    });
    return {
      status: VALID,
      fieldValidations: {},
    };
  }
}
