import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { NavItemProps } from 'utils/propTypes';
import { BREAKPOINT_MAP } from 'consts';
import MegaMenuItem from './MegaMenuItem';
import MegaMenuContext from './MegaMenuContext';

function MegaMenuSection({
  currentBreakpoint,
  title,
  sectionNavLevel,
  navItems,
}) {
  if (!navItems) {
    return null;
  }

  const {
    handlers,
    navState: { navLevel, navSelection },
    settings: { megaMenuAnimationSpeed },
  } = useContext(MegaMenuContext);

  const isMobile =
    currentBreakpoint === BREAKPOINT_MAP.MOBILE.label ||
    currentBreakpoint === BREAKPOINT_MAP.TABLET.label;

  const menuIsVisible = isMobile
    ? navLevel === sectionNavLevel
    : navLevel >= sectionNavLevel;

  return (
    <CSSTransition
      in={menuIsVisible}
      key={`megaMenuSectionKey_${navSelection[sectionNavLevel - 1]}`}
      data-testid={`megaMenuSection_${sectionNavLevel}`}
      mountOnEnter
      appear
      unmountOnExit
      timeout={megaMenuAnimationSpeed}
      className={`megaMenu__menuSection megaMenu__menuSection--level${sectionNavLevel}`}
      classNames="megaMenu__menuSection"
      onEntering={(node) => {
        handlers.handleOnSectionEnter(node.clientHeight);
      }}
    >
      <div>
        <div className="megaMenu__menuSectionTitle">
          {isMobile && (
            <button
              type="button"
              className="megaMenu__menuSectionTitleAction"
              onClick={(e) =>
                handlers.handleBackButtonClick(e, sectionNavLevel)
              }
            >
              <span className="megaMenu__menuSectionTitleActionPreamble">
                Back to
              </span>
              {title}
            </button>
          )}
          {!isMobile && title}
        </div>
        <ul className="megaMenu__menu">
          {navItems.map((item) => (
            <MegaMenuItem item={item} key={`megaMenuItem_${item.id}`} />
          ))}
        </ul>
      </div>
    </CSSTransition>
  );
}

MegaMenuSection.propTypes = {
  currentBreakpoint: PropTypes.string,
  navItems: PropTypes.arrayOf(NavItemProps),
  sectionNavLevel: PropTypes.number,
  title: PropTypes.string,
};

export default MegaMenuSection;
