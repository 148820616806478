import { isNextStepDynamic } from 'components/form-wizards/basic-form/utils';
import get from 'lodash/get';
import { LogError } from 'utils/logging';
import { getValidQuestions, isInvalidValue } from 'utils/formValuesUtils';

/**
 * @summary - this is to initialize the form state
 * @param {Object} state - the state
 * @param {Object} formConfigs - form configs from the API
 */
export default function initializeState(state, formConfigs) {
  const currentStepIndex = formConfigs.initialStepIndex || 0;
  const step = formConfigs.steps && formConfigs.steps[currentStepIndex];
  const _formValues = { ...state.formValues };

  if (!step) {
    LogError(
      'BasicFormWizard initializeState Error: This form does not have steps',
      { formId: formConfigs.id }
    );

    return state;
  }

  const allQuestions = formConfigs.steps
    .map(({ questions }) => questions)
    .flat();

  const fieldNameMap = allQuestions.reduce(
    (map, question) => ({ ...map, [question.name]: question }),
    {}
  );

  const allDynamicOptionQuestions = allQuestions.filter(
    (question) =>
      get(question, 'dependency.DYNAMIC_OPTIONS') ||
      get(question, 'dependency.DYNAMIC_VISIBILITY')
  );

  const allQuestionsInForm = allQuestions.reduce(
    (map, question) => ({ ...map, [question.id]: question }),
    {}
  );

  /**
   * We may want some form values to be pre-selected
   * we look for question[n].options[n].isSelected
   */
  allQuestions.forEach((question) => {
    if (isInvalidValue(_formValues[question.name])) {
      (question.options || []).forEach((option) => {
        if (option.isSelected) {
          _formValues[question.name] = option;
        }
      });
    }
  });

  return {
    ...state,
    formValues: _formValues,
    title: step.title || formConfigs.title,
    subTitle: step.subTitle || formConfigs.subTitle,
    currentStepIndex,
    isLastStep:
      formConfigs.steps && formConfigs.steps.length - 1 === currentStepIndex,
    isFirstStep: currentStepIndex === 0,
    currentQuestions: getValidQuestions(step.questions, {}, allQuestionsInForm),
    totalSteps: formConfigs?.steps.length,
    ctaText: step.ctaText || 'Continue',
    allQuestionsInForm,
    allDynamicOptionQuestions,
    formConfigs,
    fieldNameMap,
    formStatus: {
      ...state.formStatus,
      isInitializing: false,
      isDirty: false,
      isFormResumeEligible: false,
    },
    isNextStepDynamic: isNextStepDynamic(state, 0),
  };
}
