/* eslint-disable import/prefer-default-export */
export function getActionType(actionLink, customFunc) {
  if (customFunc) {
    return 'customFunction';
  }

  if (actionLink) {
    return 'link';
  }

  return 'button';
}
