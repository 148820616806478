import { FIELD_NAMES } from 'consts';
import { isValidEmail, isValidPhone, isValidZip } from 'utils/formValuesUtils';

const { ZIP, PRIMARY_PHONE, EMAIL } = FIELD_NAMES;

/**
 * @type {!Map<string, function(*): boolean>}
 */
const SYNCHRONOUS_FIELD_VALIDATIONS = new Map([
  [ZIP, isValidZip],
  [PRIMARY_PHONE, isValidPhone],
  [EMAIL, isValidEmail],
]);

/**
 * @summary its possible that we do not want some form updates to cause the full chain reaction of validations and requesting options
 * @param {!Object<{ action: !Object<{ type: string, name: FIELD_NAMES, value: Object }> }>=}
 * @returns {boolean} return true if you want to stop all post form update actions on a form action dispatch
 */
export default function shouldShortCircuitFormUpdate({ action = {} } = {}) {
  const { type, name, value } = action;

  return (
    type === 'SET_FIELD_VALUE' &&
    SYNCHRONOUS_FIELD_VALIDATIONS.has(name) &&
    !SYNCHRONOUS_FIELD_VALIDATIONS.get(name)(value) // this function should always return true if is valid
  );
}
