/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { QUERY_PARAMS } from 'consts';
import cx from 'utils/classnames';

import { ProgramPropType } from 'utils/propTypes';
import ProgramInfoStats from 'components/sections/programInfo/ProgramInfoStats';
import Accordion from 'components/base/accordion/Accordion';
import AccordionPanel from 'components/base/accordion/AccordionPanel';
import ProgramMeta from './ProgramMeta';
import CardCTA from './CardCTA';
import ProgramCardHeader from './ProgramCardHeader';

const {
  CATEGORY_GUID_PARAM,
  PARENT_CAT_GUID_PARAM,
  PROGRAM_GUID_PARAM,
  PROGRAM_TITLE_PARAM,
} = QUERY_PARAMS;

export default function ProgramCard(props) {
  const {
    program: {
      guid,
      title,
      category,
      parentCategory,
      shortDescription,
      isExpanded = false,
      isFeatured = false,
      accreditation,
      nextStartDate,
      slug,
      infoStats,
    },
    showProgramInfoStats,
    onCtaClick,
    callToActionBehavior,
  } = props;

  const [_isExpanded, setisExpanded] = useState(isExpanded);

  function _setIsExpanded(panelId) {
    setisExpanded(panelId === 0);
  }

  const cardClass = cx('programCard', {
    'programCard--isFeatured': isFeatured,
    'programCard--isExpanded': _isExpanded,
  });

  const _onCtaClick = () => {
    onCtaClick(props.program);
  };

  return (
    <div className={cardClass}>
      <Accordion
        activePanelIndex={isFeatured || isExpanded ? 0 : null}
        onUpdateCallback={_setIsExpanded}
      >
        <AccordionPanel
          title={title}
          headerComponent={
            <ProgramCardHeader isFeatured={isFeatured} title={title} />
          }
          id={slug}
        >
          <div
            className="programCard__content"
            dangerouslySetInnerHTML={{ __html: shortDescription }}
          />
          {showProgramInfoStats && (
            <div className="programCard__infoStats">
              <ProgramInfoStats stats={infoStats} />
            </div>
          )}
          <div className="programCard__footer">
            {(accreditation || nextStartDate) && (
              <ProgramMeta
                accreditation={accreditation}
                nextStartDate={nextStartDate}
              />
            )}
            {/* CTA Button */}

            <CardCTA
              callToActionBehavior={callToActionBehavior}
              programSlug={slug}
              className="programCard__link"
              onClick={_onCtaClick}
              linkParam={`?${PROGRAM_TITLE_PARAM}=${encodeURIComponent(
                title
              )}&${PROGRAM_GUID_PARAM}=${guid}&${CATEGORY_GUID_PARAM}=${
                category[0]
              }&${PARENT_CAT_GUID_PARAM}=${parentCategory[0]}`}
            />
          </div>
        </AccordionPanel>
      </Accordion>
    </div>
  );
}

ProgramCard.propTypes = {
  callToActionBehavior: PropTypes.string,
  program: ProgramPropType.isRequired,
  onCtaClick: PropTypes.func,
  showProgramInfoStats: PropTypes.bool,
};
