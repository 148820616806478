/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import { getTransitionInStatus } from 'components/form-wizards/basic-form/utils';
import { scrollTo } from 'utils/generalUtils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import FormStep from 'components/form-wizards/basic-form/FormStep';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import StepsWithSelections from 'components/base/steps/StepsWithSelections';
import ClickPortalFormContainer from './ClickPortalFormContainer';
import useMultiFormStateSync from './utils/useMultiFormStateSync';

function Form(props) {
  const {
    currentAction,
    currentQuestions,
    currentStepIndex,
    isFirstStep,
    formStatus,
    steps,
    showSteps,
    title,
  } = props;

  useMultiFormStateSync(props);

  const [formStepHeight, setFormStepHeight] = useState(null);
  const [formIsTransitioning, setFormIsTransitioning] = useState(false);
  const wizardRef = useRef(null);
  const { formSpeed } = useCSSsettings(wizardRef);

  const stepContainerClass = cx({
    basicFormWizard__stepContainer: true,
    [`basicFormWizard__stepContainer--${currentAction}`]: currentAction,
    'basicFormWizard__stepContainer--isTransitioning': formIsTransitioning,
  });

  function handleOnEntering(node) {
    setFormStepHeight(node.clientHeight);
    setFormIsTransitioning(true);

    if (formStatus.isDirty) {
      scrollTo(0);
    }
  }

  return (
    <div
      className={
        isFirstStep
          ? 'basicFormWizard basicFormWizard--isClickPortal basicFormWizard--isFirstStep'
          : 'basicFormWizard basicFormWizard--isClickPortal'
      }
      ref={wizardRef}
    >
      {showSteps && (
        <div className="basicFormWizard__head">
          <StepsWithSelections
            currentStepIndex={steps ? steps[currentStepIndex].trueStepIndex : 0}
            steps={steps}
          />
        </div>
      )}
      <div className="basicFormWizard__container">
        <h1 className="basicFormWizard__title">{title}</h1>
        <TransitionGroup
          component="div"
          className={stepContainerClass}
          style={{ minHeight: formStepHeight }}
        >
          <CSSTransition
            in={getTransitionInStatus(formStatus, isFirstStep)}
            key={`formStep_${currentStepIndex}`}
            classNames="formStep"
            timeout={formSpeed}
            onEntering={handleOnEntering}
            onExited={() => {
              setFormIsTransitioning(false);
            }}
          >
            <DefaultErrorBoundary meta={{ currentStepIndex }}>
              <FormStep
                questions={currentQuestions}
                stepIndex={currentStepIndex}
                stepLabel={steps[currentStepIndex].groupLabel}
                formIsDirty={formStatus.isDirty}
              />
            </DefaultErrorBoundary>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default function ClickPortalForm(props) {
  return <ClickPortalFormContainer Form={Form} {...props} />;
}

ClickPortalForm.propTypes = {
  formOptions: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
};
