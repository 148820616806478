/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { QuestionsPropType } from 'utils/propTypes';
import { FORM_ANIMATION_SPEED } from 'consts';
import getVisibleQuestions from 'utils/form-utils/getVisibleQuestions';
import FormField from './FormField';

export default function FormStep({
  questions = [],
  stepIndex,
  formIsDirty,
  stepLabel,
}) {
  const formWizardContext = useContext(BasicFormWizardContext);
  const firstFieldRef = useRef(null);

  const tokenizedStepLabel = useTokenReplacement(stepLabel);

  useEffect(() => {
    if (!formIsDirty) {
      return;
    }
    setTimeout(
      () => firstFieldRef.current && firstFieldRef.current.focus(),
      FORM_ANIMATION_SPEED
    );
  }, [stepIndex]);

  const formStepClass = cx({
    formStep: true,
    'formStep--isMulti': questions.length > 1,
  });

  function getFormGroupClass(groupQuestions) {
    return cx({
      formStep__group: true,
      'formStep__group--hasMultiQuestion': groupQuestions.length > 1,
    });
  }

  function getFieldGroupNames() {
    const fieldGroups = [];
    let currentFieldGroupName;
    questions.forEach((question) => {
      if (
        !question.fieldGroupName ||
        question.fieldGroupName !== currentFieldGroupName
      ) {
        currentFieldGroupName = question.fieldGroupName;
        fieldGroups.push([]);
      }
      fieldGroups[fieldGroups.length - 1].push(question);
    });
    return fieldGroups;
  }

  const fieldGroups = useMemo(getFieldGroupNames, [questions]);

  return (
    <fieldset className={formStepClass} data-test={`step-index-${stepIndex}`}>
      {stepLabel && (
        <legend className="formStep__legend">{tokenizedStepLabel}</legend>
      )}
      {fieldGroups.map((group, groupIndex) => (
        <div
          className={getFormGroupClass(group)}
          key={`formStep__group_${groupIndex}`}
          data-testid={`formStep__group_${groupIndex}`}
        >
          {getVisibleQuestions(group, formWizardContext).map(
            (question, questionIndex) => (
              <div
                className="formStep__question"
                data-testid="formStep__question"
                key={`formStep_question_${question.id}`}
              >
                <FormField
                  question={question}
                  key={`formField_${question.id}`}
                  fieldRef={
                    !groupIndex && !questionIndex ? firstFieldRef : undefined
                  }
                />
              </div>
            )
          )}
        </div>
      ))}
    </fieldset>
  );
}

FormStep.propTypes = {
  questions: QuestionsPropType,
  stepIndex: PropTypes.number,
  formIsDirty: PropTypes.bool,
  stepLabel: PropTypes.string,
};
