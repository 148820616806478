import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import Link from 'next/link';
import MegaMenuContext from './MegaMenuContext';

const MegaMenuAction = ({
  action,
  customFunction,
  id,
  isSelected,
  label,
  level,
  link,
  onMenuItemClick,
  className,
  testId,
}) => {
  const { handlers } = useContext(MegaMenuContext);

  const actionClass = cx({
    [className]: true,
    [`${className}--isSelected`]: isSelected,
    [`${className}--hasCustomFunc`]: !!customFunction,
  });

  // Don't pass event as it will not do client side routing
  const toggleMenu = () => handlers.handleNavToggle();

  switch (action) {
    case 'button':
      return (
        <button
          id={id}
          type="button"
          data-testid={testId}
          onClick={(e) => {
            onMenuItemClick(e, {
              id,
              navLevel: level,
            });
          }}
          className={actionClass}
        >
          {label}
        </button>
      );

    case 'link': {
      return (
        (<Link href={link} shallow className={actionClass} id={id} onClick={toggleMenu}>

          {label}

        </Link>)
      );
    }

    case 'customFunction':
      return (
        <button
          id={id}
          type="button"
          data-testid={testId}
          onClick={(e) => {
            customFunction(e, {
              id,
              navLevel: level,
            });
          }}
          className={actionClass}
        >
          {label}
        </button>
      );

    default:
      return <span className={actionClass}>{label}</span>;
  }
};

MegaMenuAction.propTypes = {
  action: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  testId: PropTypes.string,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  level: PropTypes.number,
  link: PropTypes.string,
  onMenuItemClick: PropTypes.func,
  customFunction: PropTypes.func,
};

export default MegaMenuAction;
