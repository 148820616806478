import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import cx from 'utils/classnames';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { Step } from 'types';

interface TransformedStep {
  id: number;
  title?: string;
  heading: string;
  subTitle?: string;
  questionIds: number[];
}

interface StepsProps {
  currentStepIndex: number;
  steps: Step[];
  stepsContainerRef?: React.RefObject<HTMLDivElement> | null;
}

function Steps({
  currentStepIndex,
  steps,
  stepsContainerRef,
}: StepsProps): React.JSX.Element {
  const stepsMap: Record<number, TransformedStep> = steps.reduce(
    (transformedSteps, step) => ({
      ...transformedSteps,
      [step.id]: {
        id: step.id,
        title: step.title || '',
        heading: step.heading,
        subTitle: step.subTitle || '',
        questionIds: [
          ...(transformedSteps[step.id]?.questionIds || []),
          ...(step.questions?.map((q) => q.id) || []),
        ],
      },
    }),
    {} as Record<number, TransformedStep>
  );

  const { currentQuestions } = useContext(BasicFormWizardContext);

  function getStepProgress(stepId: number): number {
    // if there are no questions, or the current step is less than the stepId, return 0
    if (currentQuestions.length === 0 || currentStepIndex < stepId - 1) {
      return 0;
    }

    // if the current step is greater than the stepId, return 100
    if (currentStepIndex > stepId - 1) {
      return 100;
    }

    // we only care which step they're on, not which question
    // so we'll use the first question's id
    const currentQuestionId = currentQuestions[0].id;
    const { questionIds } = stepsMap[stepId];

    // if the current question is not in the step, return 0
    if (!questionIds?.includes(currentQuestionId)) {
      return 0;
    }

    // if the current question is in the step, return the progress
    const currentQuestionIndex = questionIds.indexOf(currentQuestionId);
    return Math.round(((currentQuestionIndex + 1) / questionIds.length) * 100);
  }

  const getStepClass = (stepIndex: number): string => {
    return cx({
      steps__step: true,
      'steps__step--isCurrent': stepIndex === currentStepIndex + 1,
      'steps__step--isComplete': stepIndex < currentStepIndex + 1,
    });
  };

  const stepComponent = (
    <ol className="steps">
      {Object.values(stepsMap).map((step) => {
        const progress = getStepProgress(step.id);
        return (
          <li
            key={`stepKeyIndex_${step.id}`}
            data-testid="step"
            className={getStepClass(step.id)}
            style={
              { '--stepProgressValue': `${progress}%` } as React.CSSProperties
            }
          >
            <div className="steps__stepHeading" data-testid="stepHeading">
              <span className="steps__stepHeadingVal">{step.heading}</span>
            </div>
            {(step.title || step.subTitle) && (
              <div
                className="steps__stepContent"
                data-testid="steps__stepContent"
              >
                {step.title && (
                  <div className="steps__stepTitle" data-testid="stepTitle">
                    {step.title}
                  </div>
                )}
                {step.subTitle && (
                  <div className="step__subTitle" data-testid="stepSubTitle">
                    {step.subTitle}
                  </div>
                )}
              </div>
            )}
            <div className="steps__stepProgress" data-testid="stepProgress">
              <span
                className="steps__stepProgressText"
                aria-label={`Step progress: ${progress}%`}
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {progress}%
              </span>
            </div>
          </li>
        );
      })}
    </ol>
  );

  // if no ref is passed then we'll render here
  if (!stepsContainerRef || !stepsContainerRef.current) {
    return stepComponent;
  }

  // if there's a ref, use it to render in a portal
  return createPortal(
    <ol className="steps">
      {Object.values(stepsMap).map((step) => {
        const progress = getStepProgress(step.id);
        return (
          <li
            key={`stepKeyIndex_${step.id}`}
            data-testid="step"
            className={getStepClass(step.id)}
            style={
              { '--stepProgressValue': `${progress}%` } as React.CSSProperties
            }
          >
            <div className="steps__stepHeading" data-testid="stepHeading">
              <span className="steps__stepHeadingVal">{step.heading}</span>
            </div>
            <div className="steps__stepContent">
              {step.title && (
                <div className="steps__stepTitle" data-testid="stepTitle">
                  {step.title}
                </div>
              )}
              {step.subTitle && (
                <div className="step__subTitle" data-testid="stepSubTitle">
                  {step.subTitle}
                </div>
              )}
            </div>
          </li>
        );
      })}
    </ol>,
    stepsContainerRef.current
  );
}

export default Steps;
