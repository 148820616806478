import React from 'react';
import PropTypes from 'prop-types';

function ProgramCardHeader({ title, isFeatured }) {
  return (
    <div className="programCard__title">
      <div className="programCard__titleText">{title}</div>
      {isFeatured && <span className="programCard__featureFlag">Featured</span>}
    </div>
  );
}

ProgramCardHeader.propTypes = {
  title: PropTypes.string,
  isFeatured: PropTypes.bool,
};

export default ProgramCardHeader;
