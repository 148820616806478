import { QUESTION_IDS } from 'consts';
import get from 'lodash/get';
import { LogError } from 'utils/logging';

const {
  CLICK_PORTAL_SUBJECTS,
  CLICK_PORTAL_DEGREES,
  CLICK_PORTAL_CONCENTRATIONS,
} = QUESTION_IDS;

export default function onOptionsRequest(payload, taxonomyMap) {
  const { requestedOptions, isOnload, questionReplies } = payload;
  const { degrees } = taxonomyMap;
  const [questionId] = requestedOptions;
  const needSubjects = questionId === CLICK_PORTAL_SUBJECTS;
  const needConcentration = questionId === CLICK_PORTAL_CONCENTRATIONS;

  // Load Degrees
  if (isOnload) {
    return Promise.resolve({
      [CLICK_PORTAL_DEGREES]: {
        options: degrees,
        questionId: CLICK_PORTAL_DEGREES,
      },
    });
  }

  const degreeSelection = questionReplies.find(
    (reply) => reply.questionId === CLICK_PORTAL_DEGREES
  );
  const subjectSelection = questionReplies.find(
    (reply) => reply.questionId === CLICK_PORTAL_SUBJECTS
  );
  const degreeGuid = get(degreeSelection, 'questionAnswer[0]');
  const subjectGuid = get(subjectSelection, 'questionAnswer[0]');
  const validSubjects = get(
    degrees.find((degree) => degree.value === degreeGuid),
    'children',
    []
  );
  const concentrations = get(
    validSubjects.find(({ value }) => value === subjectGuid),
    'children',
    []
  );

  // Load Subjects
  if (needSubjects) {
    if (!validSubjects.length) {
      LogError(`Click Portal Form Has No Subjects for Degree ${degreeGuid}`);
    }

    if (subjectGuid && !concentrations.length) {
      LogError(
        `Click Portal Form Has No Concentration for Subject ${subjectGuid}`
      );
    }

    return Promise.resolve({
      [CLICK_PORTAL_SUBJECTS]: {
        options: validSubjects || [],
        questionId: CLICK_PORTAL_SUBJECTS,
      },
      [CLICK_PORTAL_CONCENTRATIONS]: {
        options: concentrations,
        questionId: CLICK_PORTAL_CONCENTRATIONS,
      },
    });
  }

  // Load Concentrations
  if (needConcentration) {
    if (!concentrations.length) {
      LogError(
        `Click Portal Form Has No Concentration for Degree ${subjectGuid}`
      );
    }

    return Promise.resolve({
      [CLICK_PORTAL_CONCENTRATIONS]: {
        options: concentrations,
        questionId: CLICK_PORTAL_CONCENTRATIONS,
      },
    });
  }

  LogError(
    `QuestionId ${questionId} is not mapped in Click Portal onOptionsRequest`,
    payload
  );
  return Promise.resolve({});
}
