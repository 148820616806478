import {
  applyPreFills,
  setValueIfOnlyOneOption,
} from 'components/form-wizards/basic-form/utils';

export default function setDynamicOptions(state, action) {
  const updatedState = {
    ...state,
    formStatus: {
      ...state.formStatus,
      dynamicOptions: {
        ...state.formStatus.dynamicOptions,
        ...action.dynamicOptions,
      },
    },
  };

  // handle options that were loaded that have a preFill attribute
  updatedState.formValues = applyPreFills(
    updatedState,
    state.formValues
  ).updatedForm;

  if (!state.formConfigs.disableAutoSelect) {
    // handle when only 1 option is loaded
    updatedState.formValues = setValueIfOnlyOneOption(
      updatedState.formValues,
      updatedState.formStatus.dynamicOptions,
      state.allQuestionsInForm
    );
  }
  return updatedState;
}
