import { BREAKPOINT_MAP } from 'consts';

export const getSizesStringFromObj = (imgSizes = {}) => {
  if (!imgSizes) {
    return '';
  }
  // filter out default key, invalid keys or keys without values
  const sizeKeys = Object.keys(imgSizes).filter((key) => {
    const upperKey = key.toUpperCase();
    return upperKey !== 'DEFAULT' && BREAKPOINT_MAP[upperKey] && imgSizes[key];
  });

  // grab the default so we can construct the string
  const defaultSize = imgSizes.DEFAULT || '';

  // Generate the media query string
  const sizeString = sizeKeys
    .reverse() // Reversing for correct media query cascading
    .map(
      (size) =>
        `(min-width: ${BREAKPOINT_MAP[size.toUpperCase()].width}px) ${
          imgSizes[size]
        }`
    )
    .join(', ');

  // Construct the final string, appending the default size only if there's other content
  return sizeString.length ? `${sizeString}, ${defaultSize}` : defaultSize;
};

// TODO: Remove this function and just pipe in srcset string that comes directly from WP
// If images come in from WP without a srcset, we need to generate one from the available sizes
// This can happen if the original upload was less than 500px since WordPress removes small sizes from the srcset
export const getSrcSetSizes = (img) => {
  if (!img) {
    return null;
  }

  if (img.mimeType === 'image/svg+xml') {
    return img.url;
  }

  // these are the crop-sizes generated from WP that are being
  // used as our sources
  const cropSizes = ['small', 'medium', 'large'];

  const sizesArray = [];

  cropSizes.forEach((size) => {
    if (!img.sizes[size]) {
      return;
    }

    const { url, width } = img.sizes[size];

    if (width > 500) {
      sizesArray.push(`${url} ${width}w`);
    }
  });
  // add the fallback image - this is the original uploaded file, not a crop
  sizesArray.push(`${img.url} ${img.width}w`);

  return sizesArray.join(', ');
};
