/* eslint-disable no-use-before-define */
import {
  FIELD_NAMES,
  QUESTION_IDS,
  SKIP_REASON_CODES,
  FormFieldValidationStatus,
} from 'consts';

// ----------------------- //
// ------- Global -------- //
// ----------------------- //
export type FormValues = {
  [__T in FIELD_NAMES]?:
    | FormFieldValueObject<string>
    | FormFieldValueObject<string>[]
    | FormFieldValueObject<MultiSchoolSelectionState>
    | string;
};

export interface FormFieldValueObject<T> {
  shouldSkip?: boolean;
  skipReason?: SKIP_REASON_CODES;
  label: string;
  value: T;
  guid?: string;
}

export interface FormStatus {
  stepsValidationStatus: StepValidationInfo[];
  isProcessing: boolean;
  isSubmitting: boolean;
  dynamicOptions: {
    [__T in QUESTION_IDS]?: { options: SelectFieldOption[] };
  };
}

export interface Step {
  groupLabel?: string;
  heading: string;
  id: number;
  name: string;
  subTitle?: string;
  title?: string;
  questions: Question[];
}

export type LeadSubmitBatchId = string & { __id: 'LeadSubmitBatchId' };

export type SchoolImpressionGuid = string & { __id: 'SchoolImpressionGuid' };

export interface SelectFieldOption {
  value: string;
  label: string;
  selected: boolean;
  guid?: string;
}

export interface GroupedOptions {
  group: string;
  options: SelectFieldOption[];
}

export type OptionsArray = SelectFieldOption[] | GroupedOptions[];

export interface Question {
  name: FIELD_NAMES;
  skipValue?: string;
  id: QUESTION_IDS;
}

export interface Program {
  value: string;
  label: string;
}

export interface Degree {
  value: string;
  label: string;
  programs: Program[];
}

export interface School {
  degreeList: Degree[];
  description: string;
  featuredImage: { mobile: string; desktop: string };
  highlights: string;
  id: string;
  label: string;
  matchingProgramOptions: OptionsArray;
  originalSelectedProgram: Program;
  rating: number;
  schoolLogo: { mobile: string; desktop: string };
  terms: string;
  impressionGuid: SchoolImpressionGuid;
  impressionGroupGuid: string;
}

// ----------------------- //
// -- Field Validations -- //
// ----------------------- //
export interface FieldValidationArguments<T> {
  value: T;
  name: string;
  errorMessage?: string;
  formValues?: FormValues;
}

export interface FieldValidationResult {
  [FIELD_NAMES: string]: string | null;
}

export type FieldValidationFunctionSignature<T> = (
  args: FieldValidationArguments<T>
) => FieldValidationResult | Promise<FieldValidationResult>;

export interface StepValidationInfo {
  status: FormFieldValidationStatus;
  fieldValidations: {
    [__T in FIELD_NAMES]?: FormFieldValidationStatus;
  };
}

// ----------------------- //
// ------- States -------- //
// ----------------------- //
export interface SchoolSelectionState {
  isSelected: boolean;
  isExpanded: boolean;
  isAccepted: boolean;
  isSkipped: boolean;
  isShowingDetails: boolean;
  isSubmitted: boolean;
  selectedProgram: Program;
  schoolInfo: School;
}

export type MultiSchoolSelectionState = Record<string, SchoolSelectionState>;

export enum MultiSchoolSelectActionTypes {
  EXPAND_CARD = 'EXPAND_CARD',
  SKIP_CARD = 'SKIP_CARD',
  TOGGLE_SKIP = 'TOGGLE_SKIP',
  ACCEPT_TERMS = 'ACCEPT_TERMS',
  EXPAND_NEXT_CARD = 'EXPAND_NEXT_CARD',
  TOGGLE_DETAILS = 'TOGGLE_DETAILS',
  CLOSE_CARD = 'CLOSE_CARD',
  UPDATE_PROGRAM = 'UPDATE_PROGRAM',
  INITIALIZE = 'INITIALIZE',
}

export interface MultiSchoolSelectAction {
  type: MultiSchoolSelectActionTypes;
  payload: {
    id?: string;
    program?: Program;
    schools?: School[];
    handleFormFieldChange?: (
      updatedFieldValue: MultiSchoolSelectionState
    ) => void;
    phoneNumber?: string;
    disclaimerText?: string;
  };
}

// ----------------------- //
// ------ Contexts ------- //
// ----------------------- //
export interface BasicFormWizardContext {
  allQuestionsInForm: Record<QUESTION_IDS, Question> | null;
  formValues: FormValues;
  formStatus: FormStatus;
  fieldNameMap: Record<FIELD_NAMES, Question>;
  formConfigs: {
    schoolCode: string;
    variant: string;
  };
  handleChange: <T>(
    fieldValue: T,
    event: { name: FIELD_NAMES }
  ) => Promise<{
    isCurrentStepSkipable: boolean;
  }>;
  handleSubmit: () => void;
  currentQuestions: Question[];
  currentStepIndex: number;
}

// ----------------------- //
// ---- API Interfaces ---- //
// ----------------------- //
export interface QuestionRepliesStructureForBackend {}

export interface APIMetaDataInfo {
  schoolCode: string;
  variant: string;
  disclaimerText: string;
}

export interface getQuestionOptionsPayload {
  questionReplies: {
    questionAnswer: string;
    questionId: string;
  }[];
}

export interface GetSchoolListingPayload {
  maxSchools?: number;
  leadEvalToken?: string;
  impressionGroup?: string;
  submittedImpressionGuids?: string[];
  pageTaxonomyValues: {
    degrees?: string;
    parentCategories?: string;
    categories?: string;
  };
  questionReplies: {
    questionAnswer: string;
    questionId: QUESTION_IDS;
  }[];
  requestedOptions: QUESTION_IDS[];
  requestType: 'Primary' | 'Secondary' | 'MicroClickportal';
}

export interface GetQuestionOptionsParsedResponse {
  [key: string]: {
    questionId: string;
    options: SelectFieldOption[] | School[];
  };
}

export interface SchoolOptionAPIResponse {
  HasFeaturedPrograms: boolean;
  IsOffsiteConversion: boolean;
  MatchingCategory: string;
  destinationUrl: string;
  filteredProgramCount: number;
  featuredImage?:
    | {
        Desktop?: { imageUrl: string };
        Mobile?: { imageUrl: string };
      }
    | undefined;
  highlights: string;
  impressionGuid: SchoolImpressionGuid;
  label: string;
  learningEnvironment: string;
  programCount: number;
  programs?: {
    ProgramList?: {
      DegreeTypeGuid: string;
      categoryPriority: number;
      destinationUrl: string;
      label: string;
      matchesFilterTag: boolean;
      shortDesc: string;
      value: string;
    }[];
    value: string;
    label: string;
  }[];
  rating: number;
  schoolCode: string;
  schoolDesc?: string[];
  schoolImages?:
    | {
        Desktop?: { imageUrl: string };
        Mobile?: { imageUrl: string };
      }
    | undefined;
  selectedProgram?: {
    DegreeTypeGuid: string;
    categoryPriority: number;
    destinationUrl: string;
    label: string;
    matchesFilterTag: boolean;
    shortDesc: string;
    value: string;
  };
  value: string;
  disclaimer: string;
}

export interface GetQuestionOptionsRawResponse {
  questions: { questionId: string; options: SelectFieldOption[] }[];
}

export interface GetSchoolListingRawResponse {
  Listings: SchoolOptionAPIResponse[];
  impressionGroup: string;
}

export interface LeadSubmitResponse {
  shouldTrackConversion: boolean;
  leadsSubmittedFor: Array<{
    leadGuid: string;
    statusMessage: string;
    status: string;
    revenue: number;
    adjustedRevenue: number;
    schoolCode: string;
  }>;
}

export interface MicroPortalLeadSubmitResults {
  shouldTrackConversion: boolean;
  summary: {
    revenue: number;
    adjustedRevenue: number;
  };
  isWaitMoreResults: boolean;
  leadsSubmittedFor: LeadSubmitResponse['leadsSubmittedFor'];
}

// ----------------------- //
// ------ Props ------- //
// ----------------------- //
export interface MultiSchoolSelectProps {
  options: School[];
  name: FIELD_NAMES;
  error?: string;
}

// ----------------------- //
// -------- Hooks -------- //
// ----------------------- //
export interface UseMultiSchoolSelect {
  state: MultiSchoolSelectionState;
  handleSelectSchool: (id: string) => void;
  handleSkipSchool: (id: string) => void;
  handleToggleSkip: (id: string) => void;
  handleAcceptTerms: (
    id: string,
    questionReplies: QuestionRepliesStructureForBackend,
    metaData: APIMetaDataInfo
  ) => void;
  handleNextClick: () => void;
  handleToggleDetails: (id: string) => void;
  handleCloseCard: (id: string) => void;
  handleProgramChange: (id: string, program: Program) => void;
}

export interface UseMicroPortalFormHandler {
  onOptionsRequest: (
    payload: GetSchoolListingPayload,
    schoolCode: string,
    variant: string
  ) => Promise<GetQuestionOptionsParsedResponse>;
  onFormSubmit: () => Promise<MicroPortalLeadSubmitResults>;
  onLogProgress: (
    formValues: BasicFormWizardContext['formValues'],
    fieldNameMap: BasicFormWizardContext['fieldNameMap'],
    formConfigs: BasicFormWizardContext['formConfigs'],
    linkedSessionFormValues: BasicFormWizardContext['formValues'],
    lastQuestionAnswered: Question
  ) => Promise<void>;
}
