import {
  sortPrograms,
  applyWpOrderBy,
} from 'components/form-wizards/basic-form/utils';
import { DEFAULT_SELECT_VALUE } from 'consts';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { useState, useEffect } from 'react';
import { LogError } from 'utils/logging';

function isFeatured(featuredPrograms, guid) {
  return featuredPrograms.some((program) => {
    if (typeof program !== 'object') {
      LogError('useProgramEnrichment feature list must be list of objects');
    }

    return program.guid === guid;
  });
}

// NOTE THAT THE PROGRAM SHOULD BE ASSOCIATED WITH THE PARENT CATEGORY OR DEGREE, ELSE YOU WONT SEE IT
export default function useProgramEnrichment({
  programs = [],
  degreeSelection = DEFAULT_SELECT_VALUE,
  parentCategorySelection = DEFAULT_SELECT_VALUE,
  pageTaxonomy = {},
}) {
  const [currentEnrichment, setCurrentEnrichment] = useState(programs);

  useEffect(() => {
    const dValue = degreeSelection.value;
    const pcValue = parentCategorySelection.value;

    let updatedPrograms = programs;
    let featuredList;

    const isDegreeSelected = dValue !== DEFAULT_SELECT_VALUE.value;
    const isParentCatSelected = pcValue !== DEFAULT_SELECT_VALUE.value;

    // if no selection sorting is different
    const isDefaultPageSort = !isDegreeSelected && !isParentCatSelected;

    if (isParentCatSelected && isDegreeSelected) {
      // degree with a Parent category is nested inside the selection option
      featuredList = get(
        parentCategorySelection,
        `degreeFeaturedPrograms[${dValue}]`,
        []
      );
    } else if (isParentCatSelected) {
      featuredList = parentCategorySelection.featuredPrograms;
    } else if (isDegreeSelected) {
      featuredList = degreeSelection.featuredPrograms;
    }

    // User may have deselected all, so updatedPrograms will be the inital values by skipping this
    if (featuredList) {
      updatedPrograms = updatedPrograms.map((program) => ({
        ...program,
        isExpanded: false,
        isFeatured: isFeatured(featuredList, program.guid),
        // note that since we can not have the wpSort prop on the global program list we overwrite its orderBy for the current selections order
        orderBy: isFeatured(featuredList, program.guid)
          ? findIndex(featuredList, { guid: program.guid })
          : program.orderBy,
      }));
    }

    // https://triadms.atlassian.net/browse/T1-4762
    if (isDefaultPageSort) {
      // this will add a wpOrderBy and a groupType attribute to global programs so they can be added to the correct bucket int he right order
      updatedPrograms = applyWpOrderBy(updatedPrograms, pageTaxonomy);
    }

    // this will sort into correct order and group
    const sortedPrograms = sortPrograms(updatedPrograms, isDefaultPageSort);

    setCurrentEnrichment(sortedPrograms);
  }, [programs, degreeSelection, parentCategorySelection]);

  return { enrichedPrograms: currentEnrichment };
}
