import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Site from 'components/global/site/Site';
import SiteHeader from 'components/global/site/SiteHeader';
import SiteContent from 'components/global/site/SiteContent';
import SiteFooter from 'components/global/site/SiteFooter';
import SiteHead from 'components/global/site/SiteHead';
import { InitializeLogging } from 'utils/logging';
import { pagePropsType } from 'utils/propTypes';

function PageContainer(props) {
  const {
    pageProps: { header, footer, meta, env, title, ogImg } = {},
    children,
  } = props;

  // TODO: set this in env variables
  useEffect(() => {
    // Note this will only init on the browser as useEffect does not run serverside
    InitializeLogging('7aadb60ffc0a4b89ad162c4b7c4680d6', env);
  }, []);

  return (
    <>
      <SiteHead
        meta={{ ...meta, ogImg }}
        availableThemes={env.availableThemes}
        configEnv={env.configEnv}
        abTestingClientKey={env.abTestingClientKey}
        title={title}
      />
      <Site>
        <SiteHeader config={header} meta={meta} />
        <SiteContent>{children}</SiteContent>
        <SiteFooter config={footer} meta={meta} />
      </Site>
    </>
  );
}

PageContainer.propTypes = {
  pageProps: pagePropsType,
  children: PropTypes.node.isRequired,
};

export default PageContainer;
