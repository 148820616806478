/* eslint-disable eqeqeq */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_SELECT_VALUE } from 'consts';
import cx from 'utils/classnames';
import Select from 'react-select';
import { OptionPropType, OptionsPropType } from 'utils/propTypes';
import { findValueInOptions } from 'utils/formValuesUtils';

/**
 * @param {any} value
 * @param {any} onChange
 */
function ReactSelect({
  animatedLabel,
  name,
  options = [],
  onChange = () => {},
  value = '',
  className = '',
  fieldRef,
  hasDefault,
  placeholder,
  onFocus = () => {},
  isFocused,
  isDisabled = false,
  onBlur = () => {},
}) {
  const _onChange = (selection, action) => {
    onChange(selection, {
      name: action.name,
      selection,
    });
  };

  // support groupings
  const flatOptions = options.map((group) => group.options || group).flat();
  const optionsKey = flatOptions.reduce(
    (key, option) => `${key}_${option.value}`,
    ''
  );

  // if options change re-compute the selected value
  useEffect(() => {
    const preSelectedValue = flatOptions.find(({ selected }) => selected);
    if (preSelectedValue) {
      _onChange(preSelectedValue, { name });
    }
  }, [optionsKey]);

  // the default option's label will be the placeholder if supplied
  const defaultValue = placeholder
    ? {
        value: DEFAULT_SELECT_VALUE.value,
        label: placeholder,
      }
    : DEFAULT_SELECT_VALUE;

  const optionSet = hasDefault ? [defaultValue, ...options] : options;

  // React Select Needs a group name to be in label attribute, backend sends it in group attribute
  const _options = useMemo(
    () =>
      optionSet.map((opt) => {
        if (opt.group) {
          return { options: opt.options, label: opt.group };
        }
        return opt;
      }),
    [options, placeholder, hasDefault]
  );

  // as per ticket T1-3394 show label as loading... if the option is not loaded in yet but is shown to the user
  let objValue = findValueInOptions(value, _options);

  if (!_options.length && objValue?.isMissing) {
    objValue = { ...objValue, label: 'Loading...' };
  } else if (objValue?.isMissing) {
    // TBD: should we clear user selection if the option they picked is no available anymore
  }

  const inputWrapClass = cx({
    inputWrap: true,
    'inputWrap--hasAnimatedLabel': !!animatedLabel,
    'inputWrap--hasValue': (value && value.length > 0) || isFocused,
  });

  const selectComponent = (
    <Select
      tabIndex="0"
      ref={fieldRef}
      instanceId={name}
      id={name}
      name={name}
      className={`select ${className}`}
      classNamePrefix="select"
      onChange={_onChange}
      placeholder={placeholder || '-- Please Select --'}
      onFocus={onFocus}
      onBlur={onBlur}
      isSearchable={false}
      options={_options}
      isDisabled={isDisabled || !options || !options.length}
      menuShouldScrollIntoView
      value={objValue}
      minMenuHeight={225}
    />
  );

  if (animatedLabel) {
    return (
      <div className={inputWrapClass}>
        {selectComponent}
        <span className="inputWrap__label inputWrap__label--onSelect">
          {animatedLabel}
        </span>
      </div>
    );
  }

  return selectComponent;
}

ReactSelect.propTypes = {
  animatedLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  fieldRef: PropTypes.shape({}),
  hasDefault: PropTypes.bool,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.oneOfType([
    OptionsPropType,
    PropTypes.arrayOf([PropTypes.string, PropTypes.number]),
  ]).isRequired,
  value: PropTypes.oneOfType([
    OptionPropType,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ReactSelect;
