import React from 'react';
import PropTypes from 'prop-types';

function SliderDirectionNav({
  handleGotoNext,
  handleGotoPrev,
  isTransitioning,
}) {
  return (
    <ul className="sliderDirectionNav">
      <li className="sliderDirectionNav__item sliderDirectionNav__item--prev">
        <button
          className="sliderDirectionNav__button"
          type="button"
          onClick={handleGotoPrev}
          disabled={isTransitioning}
        >
          Prev
        </button>
      </li>
      <li className="sliderDirectionNav__item sliderDirectionNav__item--next">
        <button
          className="sliderDirectionNav__button"
          type="button"
          onClick={handleGotoNext}
          disabled={isTransitioning}
        >
          Next
        </button>
      </li>
    </ul>
  );
}

SliderDirectionNav.propTypes = {
  handleGotoNext: PropTypes.func,
  handleGotoPrev: PropTypes.func,
  isTransitioning: PropTypes.bool,
};

export default SliderDirectionNav;
