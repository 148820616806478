import React from 'react';
import PropTypes from 'prop-types';

const VideoEmbed = ({ embedId, autoplay = false }) => {
  if (!embedId) {
    return null;
  }

  return (
    <div className="videoEmbed">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?autoplay=${
          autoplay ? 1 : 0
        }
        `}
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

VideoEmbed.propTypes = {
  autoplay: PropTypes.bool,
  embedId: PropTypes.string.isRequired,
};

export default VideoEmbed;
