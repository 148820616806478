import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FontAdjustmentPropTypes, WPImagesPropType } from 'utils/propTypes';
import GlobalContext from 'hooks/contexts/GlobalContext';
import Questionnaire from 'components/form-wizards/Questionnaire';
import cx from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import ResponsiveImage from 'components/base/responsiveImage/ResponsiveImage';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';

export default function HeroWithForm({
  background,
  content,
  images,
  subTitle,
  title,
  titleFontAdjustment,
  subtitleFontAdjustment,
  variation,
  customCssClass,
  heroRef,
}) {
  const {
    questionnaire,
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  const questionnaireType = get(questionnaire, 'component');

  const responsiveImageSizes = {
    MOBILE: '100vw',
    TABLET: '1000px',
    DESKTOP: '100vw',
    DEFAULT: '100vw',
  };

  const heroClassName = cx({
    heroWithForm: true,
    [`heroWithForm--variation${
      variation ? capitalizeFirstLetter(variation) : ''
    }`]: !!variation,
    [`heroWithForm--background${
      background ? capitalizeFirstLetter(background) : ''
    }`]: !!background,
    [`heroWithForm--${customCssClass}`]: !!customCssClass,
  });

  return (
    <div className={heroClassName} ref={heroRef}>
      <div className="heroWithForm__inner">
        <div className="heroWithForm__content">
          <div className="heroWithForm__imgContainer">
            <div className="heroWithForm__imgContainerInner">
              <DefaultErrorBoundary meta={{ component: 'ResponsiveImage' }}>
                <ResponsiveImage
                  images={images}
                  className="heroWithForm__img"
                  sizes={responsiveImageSizes}
                  fetchpriority="high"
                />
              </DefaultErrorBoundary>
            </div>
          </div>

          <div className="heroWithForm__copy">
            <div
              className="heroWithForm__titleWrap"
              style={{
                '--fontSizeAdjustment': `${
                  titleFontAdjustment[currentBreakpoint] || 0
                }`,
              }}
            >
              <h1
                className="heroWithForm__title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {subTitle && (
                <div
                  className="heroWithForm__subtitleWrap"
                  style={{
                    '--fontSizeAdjustment': `${
                      subtitleFontAdjustment[currentBreakpoint] || 0
                    }`,
                  }}
                >
                  <h2
                    className="heroWithForm__subtitle"
                    dangerouslySetInnerHTML={{ __html: subTitle }}
                  />
                </div>
              )}
            </div>

            {content && (
              <div
                className="heroWithForm__wysiwyg"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
          <div className="heroWithForm__formContainer">
            {questionnaireType && <Questionnaire {...questionnaire} />}
          </div>
        </div>
      </div>
    </div>
  );
}

HeroWithForm.propTypes = {
  background: PropTypes.string,
  content: PropTypes.string.isRequired,
  customCssClass: PropTypes.string,
  heroRef: PropTypes.shape({ current: PropTypes.any }),
  images: WPImagesPropType,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleFontAdjustment: FontAdjustmentPropTypes,
  subtitleFontAdjustment: FontAdjustmentPropTypes,
  variation: PropTypes.string.isRequired,
};
