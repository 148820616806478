import React, { useContext, useMemo } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import MegaMenu from 'components/global/megaMenu/MegaMenu';
import MegaMenuSecondaryNav from 'components/global/megaMenu/MegaMenuSecondaryNav';
import getProgramNavTree from './getProgramNavTree';

function ClickPortalHeaderNav() {
  const {
    microSiteTaxonomyMap: { degrees },
    clickPortal: { secondaryNavItems },
    actions,
    windowSize: { currentBreakpoint = 'mobile' },
  } = useContext(GlobalContext);

  const triggerClickPortalWizardModal = () => {
    actions.setActiveModalId('clickPortalFormWizardModal');
  };

  const navItems = useMemo(
    () => getProgramNavTree(degrees, currentBreakpoint),
    [degrees, currentBreakpoint]
  );
  const getRecommendationsLink = navItems.find(
    (item) => item.id === 'getRecommendations'
  );

  if (getRecommendationsLink) {
    getRecommendationsLink.customFunction = triggerClickPortalWizardModal;
  }

  const SecondaryNav = useMemo(
    () => <MegaMenuSecondaryNav navItems={secondaryNavItems} />,
    [secondaryNavItems]
  );

  return <MegaMenu navItems={navItems} SecondaryNav={SecondaryNav} />;
}

ClickPortalHeaderNav.propTypes = {};

export default ClickPortalHeaderNav;
