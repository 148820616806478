import React from 'react';
import PropTypes from 'prop-types';

function Loader({ text = 'Loading...' }) {
  return (
    <span className="loader">
      <span className="loader__loadingIcon" />
      <span className="loader__loadingText">{text}</span>
    </span>
  );
}

Loader.propTypes = {
  text: PropTypes.string,
};

export default Loader;
