/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { useState, useContext, useCallback } from 'react';

import GlobalContext from 'hooks/contexts/GlobalContext';

function useMegaMenu() {
  const initialState = {
    navSelection: {},
    navLevel: 0,
    navHeight: null,
  };

  const {
    actions,
    flags: { navIsOpen },
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  const [navState, setNavState] = useState(initialState);

  function resetNavSelection() {
    const navReset = { ...navState.navSelection };

    Object.keys(navReset).forEach((key) => {
      navReset[key] = null;
    });

    return navReset;
  }

  function handleNavToggle(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setNavState({
      ...navState,
      navSelection: resetNavSelection(),
      navLevel: 0,
    });

    actions.updateGlobalFlags({ navIsOpen: !navIsOpen });
  }

  function handleMenuClick(e, { id, navLevel }) {
    e.preventDefault();

    if (currentBreakpoint === 'desktop' && navLevel === 0 && navIsOpen) {
      return handleNavToggle(e);
    }

    // clear out any nav items that are lower in the tree than the currently selected nav
    const nextNavSelection = Object.keys({
      ...navState.navSelection,
      [navLevel]: id,
    }).reduce((nextNav, level) => {
      if (level && parseInt(level, 10) < navLevel) {
        nextNav[level] = navState.navSelection[level];
      }

      if (level && parseInt(level, 10) === navLevel) {
        nextNav[level] = id;
      }
      return nextNav;
    }, {});

    return setNavState({
      ...navState,
      navSelection: nextNavSelection,
      navLevel: navLevel + 1,
    });
  }

  function handleBackButtonClick(e, navLevel) {
    e.preventDefault();

    setNavState({
      ...navState,
      navLevel: navLevel === 0 ? null : navLevel - 1,
      navSelection: { ...navState.navSelection, [navLevel]: null },
    });
  }

  const handleOutsideClick = useCallback(
    (e) => {
      if (navIsOpen || navState.navSelection[0]) {
        handleNavToggle(e);
      }
    },
    [navIsOpen, navState.navSelection[0]]
  );

  function handleOnSectionEnter(height) {
    setNavState({
      ...navState,
      navHeight: height,
    });
    actions.updateGlobalFlags({ navIsOpen: true });
  }

  function getSelectedStatus(id) {
    return Object.values(navState.navSelection).includes(id);
  }

  function getNextMenuSections(items, sectionParent) {
    const derivedSections = {};

    function generateMenuSections(currentItems, currentSectionParent) {
      currentItems.forEach((item) => {
        const itemIsSelected = getSelectedStatus(item.id);

        if (!derivedSections[item.level]) {
          derivedSections[item.level] = []; // eslint-disable-line no-param-reassign
        }

        derivedSections[item.level].push({
          ...item,
          parent: currentSectionParent,
        });

        if (itemIsSelected && item.children) {
          generateMenuSections(item.children, {
            id: item.id,
            label: item.label,
          });
        }
      });
    }
    generateMenuSections(items, sectionParent);

    return derivedSections;
  }

  return {
    navState,
    setNavState,
    getSelectedStatus,
    getNextMenuSections,
    handlers: {
      handleMenuClick,
      handleOutsideClick,
      handleBackButtonClick,
      handleNavToggle,
      handleOnSectionEnter,
    },
  };
}

export default useMegaMenu;
