/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FIELD_NAMES } from 'consts';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import get from 'lodash/get';
import { OptionsPropType } from 'utils/propTypes';
import PhoneFieldRow from './PhoneFieldRow';

const {
  PRIMARY_PHONE_TYPE,
  SECONDARY_PHONE_TYPE,
  PRIMARY_PHONE,
  SECONDARY_PHONE,
} = FIELD_NAMES;

const PhoneInput = ({ options, ...props }) => {
  const {
    formValues,
    formStatus: { errors },
  } = useContext(BasicFormWizardContext);

  const [hasSecondary, setHasSecondary] = useState(
    !!formValues[FIELD_NAMES.SECONDARY_PHONE_TYPE]
  );

  const primaryOptions = options.filter(
    ({ value }) => value !== get(formValues, `[${SECONDARY_PHONE_TYPE}].value`)
  );

  const secondaryOptions = options.filter(
    ({ value }) => value !== get(formValues, `[${PRIMARY_PHONE_TYPE}].value`)
  );

  const handleAddSecondary = () => setHasSecondary(true);

  return (
    <div className="phoneInput">
      <PhoneFieldRow
        {...props}
        selectName={PRIMARY_PHONE_TYPE}
        options={primaryOptions}
        selectValue={formValues[PRIMARY_PHONE_TYPE]}
        inputName={PRIMARY_PHONE}
        inputValue={formValues[PRIMARY_PHONE]}
      />
      {errors[PRIMARY_PHONE] && (
        <div className="formField__error">{errors[PRIMARY_PHONE]}</div>
      )}
      {errors[PRIMARY_PHONE_TYPE] && (
        <div className="formField__error">{errors[PRIMARY_PHONE_TYPE]}</div>
      )}
      {!hasSecondary && (
        <button
          data-testid="add-secondary-phone"
          className="phoneInput__addButton"
          type="button"
          onClick={handleAddSecondary}
        >
          Add additional phone [+]
        </button>
      )}
      {hasSecondary && (
        <div className="phoneInput--secondaryPhone">
          <label className="formField__label">Alternate Phone Number</label>
          <PhoneFieldRow
            {...props}
            selectName={SECONDARY_PHONE_TYPE}
            options={secondaryOptions}
            selectValue={formValues[SECONDARY_PHONE_TYPE]}
            inputName={SECONDARY_PHONE}
            inputValue={formValues[SECONDARY_PHONE]}
          />
          {errors[SECONDARY_PHONE_TYPE] && (
            <div className="formField__error">
              {errors[SECONDARY_PHONE_TYPE]}
            </div>
          )}
          {errors[SECONDARY_PHONE] && (
            <div className="formField__error">{errors[SECONDARY_PHONE]}</div>
          )}
        </div>
      )}
    </div>
  );
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  disclaimerText: PropTypes.string,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.shape({}),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  valueTransform: PropTypes.func,
  options: OptionsPropType,
};

export default PhoneInput;
