import React from 'react';
import PropTypes from 'prop-types';

const MainNavSubMenu = ({ item }) => {
  return (
    <ul className="mainNav__subMenu">
      {item.child_items.map((innerItem) => (
        <li className="mainNav__subMenuItem" key={innerItem.ID}>
          <a href={innerItem.url}>{innerItem.title}</a>
        </li>
      ))}
    </ul>
  );
};

MainNavSubMenu.propTypes = {
  item: PropTypes.shape({
    child_items: PropTypes.array,
  }),
};

export default MainNavSubMenu;
