/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { CtaPropTypes, WPImagesPropType } from 'utils/propTypes';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import ResponsiveImage from 'components/base/responsiveImage/ResponsiveImage';

import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';

function FeatureBlock({
  featureImage,
  title,
  description,
  cta,
  wrapBlockWithCtaLink = true,
  responsiveImageSizes,
}) {
  const blockContent = (
    <>
      {!isEmpty(featureImage) && (
        <div className="featureBlocks__blockIcon">
          <DefaultErrorBoundary meta={{ component: 'ResponsiveImage' }}>
            <ResponsiveImage
              alt={title}
              className="featureBlocks__iconImg"
              images={featureImage}
              loading="lazy"
              sizes={responsiveImageSizes}
            />
          </DefaultErrorBoundary>
        </div>
      )}
      {title && <div className="featureBlocks__blockTitle">{title}</div>}
      {description && (
        <div className="featureBlocks__blockDescription">
          <Wysiwyg content={description} />
        </div>
      )}
    </>
  );

  function getBlockLinkContent() {
    if (wrapBlockWithCtaLink) {
      return (
        <a
          className="featureBlocks__block"
          href={cta.link}
          target={cta.buttonActionType === 'customLink' ? '_blank' : null}
          rel={
            cta.buttonActionType === 'customLink' ? 'noreferrer noopener' : null
          }
        >
          {blockContent}
        </a>
      );
    }
    return (
      <div className="featureBlocks__block">
        <a
          className="featureBlocks__cta"
          href={cta.link}
          target={cta.buttonActionType === 'customLink' ? '_blank' : null}
          rel={
            cta.buttonActionType === 'customLink' ? 'noreferrer noopener' : null
          }
        >
          {cta.buttonText}
        </a>
      </div>
    );
  }

  switch (cta.useCTA && cta.buttonActionType) {
    case 'customLink': {
      return getBlockLinkContent(cta.link, blockContent);
    }

    case 'pageLink': {
      return getBlockLinkContent(cta.link, blockContent);
    }

    case 'clickPortalWizard': {
      return (
        <button
          type="button"
          className="featureBlocks__block"
          data-testid={`featureBlockCtaButton--${cta.buttonActionType}`}
          onClick={cta.handleClickPortalWizardToggle}
        >
          {blockContent}
        </button>
      );
    }

    case 'fullPageForm': {
      const urlPath = cta.router.asPath || 'default/landing';

      return (
        <a
          className="featureBlocks__block"
          data-testid={`featureBlockCtaButton--${cta.buttonActionType}`}
          href={`${urlPath}/form${cta.linkParams}`}
        >
          {blockContent}
        </a>
      );
    }

    default: {
      return <div className="featureBlocks__block">{blockContent}</div>;
    }
  }
}

FeatureBlock.propTypes = {
  cta: CtaPropTypes,
  description: PropTypes.string,
  featureImage: WPImagesPropType,
  responsiveImageSizes: PropTypes.shape({
    MOBILE: PropTypes.string,
    TABLET: PropTypes.string,
    DESKTOP: PropTypes.string,
    DEFAULT: PropTypes.string,
  }),
  title: PropTypes.string,
  wrapBlockWithCtaLink: PropTypes.bool,
};

export default FeatureBlock;
