import React, {
  useContext,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import GlobalContext from 'hooks/contexts/GlobalContext';
import useOutsideClick from 'hooks/custom/useOutsideClick';
import { CSSTransition } from 'react-transition-group';
import debounce from 'lodash/debounce';

function Modal({ children, title, onClose, className = '', id }) {
  const {
    actions,
    activeModalId,
    refs: { modalContainerRef },
  } = useContext(GlobalContext);
  const [isTransitioning, setIsTransitioning] = useState(true);

  const modalRef = useRef(null);

  const isOpen = activeModalId === id;

  const _onCancel = () => {
    actions.setActiveModalId(null);
    onClose && onClose();
  };

  const _onClickAway = useCallback(() => {
    if (!isTransitioning && isOpen) {
      actions.setActiveModalId(null);
      onClose && onClose();
    }
  }, [isOpen, onClose, isTransitioning]);

  useOutsideClick(modalContainerRef, debounce(_onClickAway, 200));

  useEffect(() => {
    document.documentElement.style.overflow = isOpen ? 'hidden' : null;
  }, [isOpen]);

  if (!modalContainerRef || !modalContainerRef.current) {
    return null;
  }

  return createPortal(
    <>
      <CSSTransition
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={500}
        onEntering={() => {
          setIsTransitioning(true);
        }}
        onEntered={() => {
          setIsTransitioning(false);
        }}
        classNames="modal"
        className={`modal ${className}`}
        id={id}
      >
        <div ref={modalRef}>
          <button type="button" className="modal__closeBtn" onClick={_onCancel}>
            <span className="modal__closeBtnText">Close</span>
          </button>
          <div className="modal__header">
            {title && <div className="modal__title">{title}</div>}
          </div>
          <div className="modal__body">{children}</div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={250}
        className="modal__overlay"
        classNames="modal__overlay"
      >
        <div />
      </CSSTransition>
    </>,
    modalContainerRef.current
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default Modal;
