import React from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import CtaButton from 'components/blocks/ctaButton/CtaButton';
import { CtaPropTypes } from 'utils/propTypes';
import useApplicationLinks from 'hooks/custom/useApplicationLinks';

export default function ThankyouStep({ successText, cta, selectedProgram }) {
  if (cta && cta.useCTA && cta.buttonActionType === 'applicationLink') {
    useApplicationLinks();
  }

  const stepClass = cx({
    thankyouStep: true,
    'thankyouStep--hasCta': cta && cta.useCTA,
  });

  const tokenizedText = successText
    ? successText.replace(/{PROGRAM}/, selectedProgram)
    : '';

  return (
    <div className={stepClass}>
      {tokenizedText && <Wysiwyg content={tokenizedText} />}
      {cta && cta.useCTA && (
        <div className="thankyouStep__cta">
          <CtaButton {...cta} trackingLabel="click-thank-you-link" />
        </div>
      )}
    </div>
  );
}

ThankyouStep.propTypes = {
  successText: PropTypes.string,
  cta: CtaPropTypes,
  selectedProgram: PropTypes.string,
};
