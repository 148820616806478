import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { LeadSubmitBatchId } from 'types';
import { retryablePromise } from 'utils/generalUtils';
import request from 'utils/request';

let currentBatchIdRequest: Promise<LeadSubmitBatchId>;

/**
 * Gets a new batch id for the current user.
 */
export function getNewLeadSubmitBatchId(): Promise<LeadSubmitBatchId> {
  if (currentBatchIdRequest) {
    return currentBatchIdRequest;
  }

  currentBatchIdRequest = retryablePromise(
    () =>
      request({
        method: 'get',
        url: `${TRIAD_PROXY_ROUTE}/getNewLeadSubmitBatch`,
      }).then(({ batchId }: { batchId: string }) => batchId),
    {
      waitTimeBetweenFails: 2000,
    }
  ).catch(() => ({ batchId: '' }));

  return currentBatchIdRequest;
}
