import { isNumber } from './stringHelpers';

/**
 * @param {String} value input value
 * @see https://triadms.atlassian.net/browse/T1-1661 for rules
 */
export function phoneNumberMask(value) {
  const digits = value.replace(/-/g, '').split('');

  if (digits.length > 3) {
    digits.splice(3, 0, '-');
  }

  if (digits.length > 7) {
    digits.splice(7, 0, '-');
  }

  return digits.join('');
}

/**
 * @param {String} value input value
 * @see https://triadms.atlassian.net/browse/T1-1661 for rules
 */
export function phoneNumberTransform(value) {
  const strDigits = value.replace(/-/g, '');

  // if user did not enter a number or the number is 1, dont update the value
  if (!isNumber(strDigits[strDigits.length - 1])) {
    return phoneNumberMask(strDigits.substring(0, strDigits.length - 1));
  }

  if (strDigits[0] === '1') {
    return phoneNumberMask(strDigits.substring(1));
  }

  // max 10 digits
  if (strDigits.length > 10) {
    return phoneNumberMask(strDigits.substring(0, 10));
  }

  return phoneNumberMask(strDigits);
}

/**
 * @param {String} value some string to cast to zipcode
 * @see https://triadms.atlassian.net/browse/T1-2864 for rules
 */
export function zipCodeTransform(value = '') {
  const numberValue = (value.match(/\d+/g) || ['']).pop();

  if (numberValue.length <= 5) {
    return numberValue;
  }

  return numberValue.substring(0, 5);
}
