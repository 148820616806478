import React from 'react';
import PropTypes from 'prop-types';

function FooterLink({ link, label, trigger }) {
  function handleKetchTrigger() {
    if (window && window.ketch) {
      window.ketch('showPreferences');
    }
  }

  switch (trigger) {
    case 'ketch':
      return (
        <button
          type="button"
          className="footer__link"
          onClick={handleKetchTrigger}
        >
          {label}
        </button>
      );

    default:
      return (
        <a
          className="footer__link"
          href={link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {label}
        </a>
      );
  }
}

FooterLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default FooterLink;
