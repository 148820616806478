import React, { useState, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import cx from 'utils/classnames';
import GroupedProgramsContainer from 'components/blocks/containers/grouped-programs/GroupedProgramsContainer';
import ProgramPager from 'components/sections/program-selection/programPager/ProgramPager';
import useProgramSelection from 'hooks/custom/programs/useProgramSelection';
import useProgramEnrichment from 'hooks/custom/programs/useProgramEnrichment';
import { usePagination } from 'hooks/custom/tables/usePagination';
import { OptionsPropType, ProgramsPropType } from 'utils/propTypes';
import { NOP, scrollTo } from 'utils/generalUtils';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import { DEFAULT_SELECT_VALUE } from 'consts';
import { trackAudienceGroup } from 'utils/trackingFunctions';
import GlobalContext from 'hooks/contexts/GlobalContext';

export default function PagedProgramsSearch(props) {
  const programModuleRef = useRef(null);

  const {
    taxonomyValues,
    refs: { headerComponentRef },
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  const {
    background,
    callToActionBehavior,
    customCssClass,
    degreeOptions = [],
    description,
    dropdownOptions = {
      parentCategoryLabel: 'Browse by Area of Interest',
      degreeLabel: 'Browse by Degree Level',
    },
    fullProgramsList = [],
    initialValues = [],
    onCtaClick = NOP,
    onFilterChange = NOP,
    paginationLimit,
    parentCategoryOptions = [],
    title,
    variation,
    showProgramInfoStats,
  } = props;

  const degreeDefaultOption = {
    value: DEFAULT_SELECT_VALUE.value,
    label: dropdownOptions.degreePlaceholder,
  };

  const parentCategoryDefaultOption = {
    value: DEFAULT_SELECT_VALUE.value,
    label: dropdownOptions.parentCategoryPlaceholder,
  };

  const [selectionState, setSelectionSate] = useState({
    degreeSelection: degreeDefaultOption,
    parentCategorySelection: parentCategoryDefaultOption,
  });

  // programsToRender is based off of currentPage, no selection, or current page and a single selection
  const { selectedPrograms, isNotFoundList } = useProgramSelection({
    initialValues,
    programsMap: fullProgramsList,
    degreeSelection: selectionState.degreeSelection,
    parentCategorySelection: selectionState.parentCategorySelection,
  });

  // once we know the programs we need to update their flags given current page/selection
  const { enrichedPrograms } = useProgramEnrichment({
    programs: selectedPrograms,
    degreeSelection: selectionState.degreeSelection,
    parentCategorySelection: selectionState.parentCategorySelection,
    pageTaxonomy: taxonomyValues,
  });

  // now we page the results
  const {
    state: { currentPageRecords, currentPageIndex, pageCount },
    goToPage,
  } = usePagination({
    records: enrichedPrograms,
    pageLimit: paginationLimit,
  });

  const scrollToPrograms = () => {
    try {
      currentBreakpoint === 'desktop'
        ? scrollTo(
            programModuleRef.current.offsetTop -
              (headerComponentRef.current.offsetHeight + 20)
          )
        : scrollTo(programModuleRef.current.offsetTop);
    } catch (error) {
      console.error('headerComponentRef is not set');
    }
  };

  const onSelection = ({ option, name }) => {
    trackAudienceGroup(option);

    setSelectionSate({
      ...selectionState,
      [name]: option,
    });

    onFilterChange({ option, name }, { prevState: selectionState });

    scrollToPrograms();
  };

  const onDegreeChange = (option) =>
    onSelection({ option, name: 'degreeSelection' });

  const onParentCategoryChange = (option) =>
    onSelection({ option, name: 'parentCategorySelection' });

  const _degreeOptions = useMemo(
    () => [degreeDefaultOption, ...degreeOptions],
    [degreeOptions]
  );

  const _parentCategoryOptions = useMemo(() => {
    const sortedOptions = [
      parentCategoryDefaultOption,
      ...parentCategoryOptions,
    ];
    sortedOptions.sort((a, b) => a?.label.localeCompare(b?.label));
    return sortedOptions;
  }, [parentCategoryOptions]);

  const _onClickPaginationLink = (pageIndex) => {
    goToPage(pageIndex);

    scrollToPrograms();
  };

  const getProgramSearchClassName = cx({
    pagedProgramsSearch: true,
    [`pagedProgramsSearch--variation${
      variation ? capitalizeFirstLetter(variation) : ''
    }`]: !!variation,
    [`pagedProgramsSearch--background${
      background ? capitalizeFirstLetter(background) : ''
    }`]: !!background,
    [`pagedProgramsSearch--${customCssClass}`]: !!customCssClass,
  });

  return (
    <div className={getProgramSearchClassName}>
      <div className="pagedProgramsSearch__inner">
        <div className="pagedProgramsSearch__header">
          <h1 className="pagedProgramsSearch__headerTitle">{title}</h1>
          <p className="pagedProgramsSearch__headerDescription">
            {description}
          </p>
        </div>
        <div className="pagedProgramsSearch__body" ref={programModuleRef}>
          <div className="pagedProgramsSearch__menuContainer">
            <h4 className="pagedProgramsSearch__menuContainerTitle">
              Filter Results
            </h4>
            <div className="pagedProgramsSearch__menuCtrl pagedProgramsSearch__menuCtrl--degree">
              <span className="pagedProgramsSearch__menuCtrlLabel">
                {dropdownOptions.degreeLabel}
              </span>
              <ReactSelect
                onChange={onDegreeChange}
                options={_degreeOptions}
                value={selectionState.degreeSelection}
                name="programDegreeSelect"
              />
            </div>
            <div className="pagedProgramsSearch__menuCtrl pagedProgramsSearch__menuCtrl--parentCategory">
              <span className="pagedProgramsSearch__menuCtrlLabel">
                {dropdownOptions.parentCategoryLabel}
              </span>
              <ReactSelect
                onChange={onParentCategoryChange}
                options={_parentCategoryOptions}
                value={selectionState.parentCategorySelection}
                name="programParentCategorySelect"
              />
            </div>
          </div>
          <div className="pagedProgramsSearch__programsListContainer">
            <GroupedProgramsContainer
              programs={currentPageRecords}
              onCtaClick={onCtaClick}
              callToActionBehavior={callToActionBehavior}
              isNotFoundList={isNotFoundList}
              currentSelection={selectionState}
              showProgramInfoStats={showProgramInfoStats}
            />
            <div className="pagedProgramsSearch__pager">
              <ProgramPager
                pageCount={pageCount}
                onPagerClick={_onClickPaginationLink}
                currentPageIndex={currentPageIndex}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PagedProgramsSearch.propTypes = {
  background: PropTypes.string,
  customCssClass: PropTypes.string,
  callToActionBehavior: PropTypes.string,
  variation: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fullProgramsList: PropTypes.arrayOf(PropTypes.object),
  initialValues: ProgramsPropType,
  degreeOptions: OptionsPropType,
  parentCategoryOptions: OptionsPropType,
  onFilterChange: PropTypes.func,
  onCtaClick: PropTypes.func,
  dropdownOptions: PropTypes.shape({
    degreePlaceholder: PropTypes.string,
    parentCategoryPlaceholder: PropTypes.string,
    parentCategoryLabel: PropTypes.string,
    degreeLabel: PropTypes.string,
  }),
  paginationLimit: PropTypes.string,
  showProgramInfoStats: PropTypes.bool,
};

// we can have this act differently on landing pages vs browse pages
PagedProgramsSearch.staticProps = (pageContext, { isLanding } = {}) => {
  const props = {
    onFilterChange: ({ name, option }) => {
      try {
        pageContext.actions.updateUserData({ [name]: option });
      } catch (error) {
        /* Silent Fail */
      }
    },
  };

  // On landing pages we want to push use to questionnaire
  if (isLanding) {
    props.onCtaClick = (program) => {
      try {
        pageContext.actions.updateUserData({ programOfInterest: program });
      } catch (error) {
        /* Silent Fail */
      }
    };
  }

  return props;
};
