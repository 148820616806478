import React, { useContext, useEffect } from 'react';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { OptionsPropType } from 'utils/propTypes';
import { func, object, shape, string } from 'prop-types';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import Disclaimer from '../disclaimer/Disclaimer';

export default function AdditionalSchoolsDisclaimer(props) {
  const {
    onChange,
    value,
    meta: { phoneNumber },
    ...otherProps
  } = props;

  const {
    siteMeta: { disclaimerText, disclaimerSuccessText },
  } = useContext(GlobalContext);

  const { formValues, allQuestionsInForm } = useContext(BasicFormWizardContext);

  const _disclaimerText = disclaimerSuccessText || disclaimerText;
  const selectedSchoolGuids = Object.keys(formValues).filter(
    (schoolGuid) => formValues[schoolGuid].isSelected
  );
  const dynamicDisclaimerText = useTokenReplacement(_disclaimerText, {
    '{APPLICABLE_SCHOOLS}': selectedSchoolGuids
      .map((schoolGuid) => allQuestionsInForm[schoolGuid]?.meta?.schoolName)
      .join(', '),
    '{PHONE}': phoneNumber,
  });

  useEffect(() => {
    onChange(
      {
        valueToSubmit: selectedSchoolGuids.map(
          (schoolGuid) => formValues[schoolGuid].impressionGuid
        ),
        dynamicDisclaimerText,
        value: null, // resetting this so the user has to re-check. This will help 3rd party script capture TCPA answering
      },
      { name: otherProps.name }
    );
  }, [dynamicDisclaimerText]);

  function onChange_(isSelected, event) {
    const updatedDisclaimerValue = {
      valueToSubmit: value?.valueToSubmit,
      dynamicDisclaimerText: value?.dynamicDisclaimerText,
      value: Boolean(isSelected) || null, // we wont accept false. Null will trigger form validation
    };

    onChange(updatedDisclaimerValue, event);
  }

  return (
    <Disclaimer
      {...otherProps}
      value={value?.value}
      disclaimerTextValue={value?.dynamicDisclaimerText}
      onChange={onChange_}
    />
  );
}

AdditionalSchoolsDisclaimer.propTypes = {
  options: OptionsPropType,
  onChange: func,
  value: object,
  name: string,
  meta: shape({ phoneNumber: string }),
};
