/**
 * @summary this file as all the component mappings that Wordpress will be able to render
 */
import { LogError } from 'utils/logging';
import DefaultSiteHeader from 'components/sections/headers/default-header/DefaultSiteHeader';
import ClickPortalHeader from 'components/sections/headers/click-portal-header/ClickPortalHeader';
import DefaultSiteFooter from 'components/sections/footers/DefaultSiteFooter';
import ClickPortalSiteFooter from 'components/sections/footers/ClickPortalSiteFooter';
import PagedProgramsSearch from 'components/sections/program-selection/pagedProgramsSearch/PagedProgramsSearch';
import BillBoard from 'components/sections/billBoard/BillBoard';
import Hero from 'components/sections/heros/Hero';
import DefaultLandingHeader from 'components/sections/headers/landing-page-header/DefaultLandingHeader';
import AltLandingHeader from 'components/sections/headers/landing-page-header/AltLandingHeader';
import FeatureBlocks from 'components/sections/featureBlocks/FeatureBlocks';
import ProgramInfo from 'components/sections/programInfo/ProgramInfo';
import Slider from 'components/sections/slider/Slider';
import UserContent from 'components/sections/userContent/UserContent';

import { AVAILABLE_COMPONENTS as ac } from 'components/AVAILABLE_COMPONENTS';
import Admissions from './sections/admissions/Admissions';
import Faq from './sections/faq/Faq';

/**
 * If we add a new page section in Wordpress we need to
 * add its acf_fc_layout value in the map below.
 */
const COMPONENT_MAP = {
  [ac.DEFAULT_HEADER]: DefaultSiteHeader,
  [ac.ADMISSIONS]: Admissions,
  [ac.LANDING_PAGE_HEADER]: DefaultLandingHeader,
  [ac.LANDING_PAGE_HEADER_ALT]: AltLandingHeader,
  [ac.DEFAULT_FOOTER]: DefaultSiteFooter,
  [ac.CLICK_PORTAL_FOOTER]: ClickPortalSiteFooter,
  [ac.CLICK_PORTAL_HEADER]: ClickPortalHeader,
  [ac.PROGRAM_SEARCH]: PagedProgramsSearch,
  [ac.PAGE_HERO_V1]: Hero,
  [ac.BILLBOARD]: BillBoard,
  [ac.FEATURE_BLOCKS]: FeatureBlocks,
  [ac.USER_CONTENT]: UserContent,
  [ac.SLIDER]: Slider,
  [ac.PROGRAM_INFO]: ProgramInfo,
  [ac.FAQ]: Faq,
};

function getComponent(key) {
  if (!COMPONENT_MAP[key]) {
    LogError(`Component Not Found: ${key} is not a valid Component`);
    return () => null; // skip this component and move on
  }

  return COMPONENT_MAP[key];
}

export default getComponent;
