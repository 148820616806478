import React from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import { SlidesPropTypes } from 'utils/propTypes';
import SectionTitle from 'components/blocks/headers/section-title/SectionTitle';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import Slides from './Slides';
import useSlider from './useSlider';
import SliderDirectionNav from './SliderDirectionNav';
import SliderIndexNav from './SliderIndexNav';

function Slider(props) {
  const {
    imageAlignment,
    customCssClass,
    variation,
    background,
    isReversed,
    mediaWidth,
    title,
    subTitle,
    slides,
  } = props;

  const { sliderState, gotoSlide, gotoNextSlide, gotoPrevSlide } = useSlider(
    slides.length
  );

  const sliderClass = cx({
    slider: true,
    [`slider--${sliderState.slideDirection}`]: true,
    [`slider--media${capitalizeFirstLetter(imageAlignment)}`]: true,
    [`slider--variation${variation ? capitalizeFirstLetter(variation) : ''}`]:
      !!variation,
    [`slider--background${
      background ? capitalizeFirstLetter(background) : ''
    }`]: !!background,
    [`slider--mediaWidth${
      mediaWidth ? capitalizeFirstLetter(mediaWidth) : ''
    }`]: !!mediaWidth,
    [`slider--isReversed`]: isReversed,
    [`slider--${customCssClass}`]: !!customCssClass,
  });

  return (
    <div className={sliderClass}>
      <div className="slider__inner">
        {title && (
          <div className="slider__title">
            <SectionTitle title={title} />
          </div>
        )}
        {subTitle && (
          <h2 className="slider__subTitle">
            <Wysiwyg content={subTitle} />
          </h2>
        )}
        {slides && slides.length > 1 && (
          <SliderDirectionNav
            handleGotoNext={gotoNextSlide}
            handleGotoPrev={gotoPrevSlide}
            isTransitioning={sliderState.isTransitioning}
          />
        )}
        <Slides
          slides={slides}
          currentIndex={sliderState.currentIndex}
          prevIndex={sliderState.prevIndex}
          currentDirection={sliderState.slideDirection}
          slidePosition={sliderState.infiniteSlidePosition}
          shouldTransition={sliderState.shouldTransition}
          handleGotoNext={gotoNextSlide}
          handleGotoPrev={gotoPrevSlide}
          options={{
            imageAlignment,
            customCssClass,
            variation,
            background,
            isReversed,
            mediaWidth,
          }}
        />
        {slides && slides.length > 1 && (
          <SliderIndexNav
            currentIndex={sliderState.currentIndex}
            slides={slides}
            handleGotoSlide={gotoSlide}
          />
        )}
      </div>
    </div>
  );
}

Slider.propTypes = {
  customCssClass: PropTypes.string,
  imageAlignment: PropTypes.string,
  isReversed: PropTypes.bool,
  mediaWidth: PropTypes.string,
  variation: PropTypes.string,
  background: PropTypes.string,
  slides: SlidesPropTypes,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

export default Slider;
