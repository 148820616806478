import React, { forwardRef } from 'react';

import DefaultLandingHeader from './DefaultLandingHeader';

function AltLandingHeader(props, ref) {
  return (
    <DefaultLandingHeader
      className="header--landingPageAlt"
      ref={ref}
      {...props}
    />
  );
}

export default forwardRef(AltLandingHeader);
