import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { NavItemProps } from 'utils/propTypes';
import MegaMenuContext from './MegaMenuContext';
import MegaMenuAction from './MegaMenuAction';
import MegaMenuMenus from './MegaMenuMenus';
import { getActionType } from './megaMenuUtils';

const MegaMenuNavItem = ({ navItem, currentBreakpoint, onMenuClick }) => {
  const { id, isSelected, level, label, children, link, customFunction } =
    navItem;

  const {
    handlers,
    navState,
    settings: { megaMenuAnimationSpeed },
  } = useContext(MegaMenuContext);

  const {
    flags: { navIsOpen },
  } = useContext(GlobalContext);

  function _handleMenuClick(e, item) {
    handlers.handleMenuClick(e, {
      id: item.id,
      itemType: item.itemType,
      navLevel: item.navLevel,
    });

    // call any handlers that were passed down to the top-level menu component
    if (onMenuClick) {
      onMenuClick(e, {
        id: item.id,
        itemType: item.itemType,
        navLevel: item.navLevel,
      });
    }
  }

  return (
    <CSSTransition
      in={navIsOpen && navState.navLevel === 0}
      timeout={megaMenuAnimationSpeed}
      appear
      key={`level1_${id}`}
      className={
        isSelected
          ? `megaMenu__navItem megaMenu__navItem--${id} megaMenu__navItem--isSelected`
          : `megaMenu__navItem megaMenu__navItem--${id}`
      }
      classNames="megaMenu__navItem"
    >
      <li>
        {currentBreakpoint && currentBreakpoint !== 'desktop' && (
          <MegaMenuAction
            testId="megaMenuNavItemLink"
            className={
              isSelected
                ? 'megaMenu__navItemLink megaMenu__navItemLink--isSelected'
                : 'megaMenu__navItemLink'
            }
            isSelected={isSelected}
            id={id}
            action={getActionType(link, customFunction)}
            customFunction={customFunction}
            label={label}
            link={link}
            onMenuItemClick={(e) =>
              _handleMenuClick(e, {
                id,
                navLevel: level,
              })
            }
          />
        )}

        {currentBreakpoint && currentBreakpoint === 'desktop' && (
          <MegaMenuAction
            testId="megaMenuNavItemLink"
            className="megaMenu__navItemLink"
            isSelected={isSelected}
            id={id}
            action={getActionType(link, customFunction)}
            customFunction={customFunction}
            label={label}
            link={link}
            onMenuItemClick={(e) =>
              _handleMenuClick(e, {
                id,
                navLevel: level,
              })
            }
          />
        )}
        <MegaMenuMenus
          isSelected={isSelected}
          subNav={children}
          parent={{ id, label }}
          navState={navState}
          handlers={handlers}
          currentBreakpoint={currentBreakpoint}
        />
      </li>
    </CSSTransition>
  );
};

MegaMenuNavItem.propTypes = {
  currentBreakpoint: PropTypes.string,
  navItem: NavItemProps,
  onMenuClick: PropTypes.func,
};

export default MegaMenuNavItem;
