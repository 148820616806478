import React from 'react';
import PropTypes from 'prop-types';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import camelCase from 'lodash/camelCase';

function Stats({ stats = [] }) {
  if (!stats.length) {
    return null;
  }
  return (
    <ul className="stats">
      {stats.map((stat) => (
        <li className="stats__item" key={`${camelCase(stat.title)}_stat`}>
          <div className="stats__title">
            <Wysiwyg content={stat.title} />
          </div>
          <div className="stats__subTitle">
            <Wysiwyg content={stat.subTitle} />
          </div>
        </li>
      ))}
    </ul>
  );
}

Stats.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.subTitle,
    })
  ),
};

export default Stats;
