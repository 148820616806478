import { useEffect, useReducer } from 'react';

// Initial Hook State
export const initialState = {
  records: [],
  pages: [],
  pageCount: 0,
  currentPageIndex: 0,
  currentPageRecords: [],
};

// Reducer to update hook state
export function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      return action.state;
    case 'SET_PAGE':
      return {
        ...state,
        currentPageIndex: action.currentPageIndex,
        currentPageRecords: action.currentPageRecords,
      };
    default:
      throw new Error('Unknown Action on usePagination hook!');
  }
}

export function usePagination({ records, pageLimit }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Main function that calculates pages
  useEffect(() => {
    const pages = [[]];
    let currentPage = 0;

    for (let index = 0; index < records.length; index += 1) {
      const record = records[index];

      if (pages[currentPage].length >= pageLimit) {
        currentPage += 1;
        pages.push([]);
      }

      pages[currentPage].push(record);
    }

    dispatch({
      type: 'SET_STATE',
      state: {
        records,
        pages,
        pageCount: pages.length,
        currentPageIndex: 0,
        currentPageRecords: pages[0],
      },
    });
  }, [records]);

  const goToLast = () =>
    dispatch({
      type: 'SET_PAGE',
      currentPageIndex: state.pageCount - 1,
      currentPageRecords: state.pages[state.pageCount - 1],
    });

  const goToFirst = () =>
    dispatch({
      type: 'SET_PAGE',
      currentPageIndex: 0,
      currentPageRecords: state.pages[0],
    });

  const goToPage = (pageIndex) =>
    dispatch({
      type: 'SET_PAGE',
      currentPageIndex: pageIndex,
      currentPageRecords: state.pages[pageIndex],
    });

  return {
    state,
    goToPage,
    goToFirst,
    goToLast,
  };
}
