/**
 * This is the secondary form for MicroPortal
 */
import React, { useContext, useEffect } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { getMicroPortalPostLeadSubmitQuestionnaire } from 'app-requests/triadmsRequests';
import Loader from 'components/base/loader/Loader';
import { FIELD_NAMES, QUESTION_IDS } from 'consts';
import BasicFormWizard from '../basic-form/BasicFormWizard';
import useMicroPortalFormHandler from './useMicroPortalFormHandler';

/**
 * This form is used on micro portal after the user has been sent as a lead
 * to other schools. Here they can select additional schools to send their information to
 */
export default function AdditionalLeadsForm() {
  const {
    microPortal: {
      additionalSchoolResultsPromise,
      additionalSchoolResults,
      primaryFormSubmitPollingHelper,
    },
    actions: { updateMicroPortalData },
    siteMeta: { schoolCode },
  } = useContext(GlobalContext);

  const { onFormSubmit } = useMicroPortalFormHandler({
    updateMicroPortalData,
    primaryFormSubmitPollingHelper,
  });

  /**
   * @param {Object} formValues - current form state
   * @param {Object} fieldNameMap - map of all field names
   * @param {Object} formConfigs - the questionnaire
   * @param {Object} formState - the full questionnaire state object
   */
  function _onFormSubmit(formValues, fieldNameMap, formConfigs, formState) {
    // backend wants all the user's selected schools in one field
    const selectedSchoolGuids = Object.keys(formValues).filter(
      (schoolGuid) => formValues[schoolGuid].isSelected
    );

    // rewrite the form values to match what the backend wants
    const _formValues = {
      ...formValues,
      [FIELD_NAMES.MICRO_PORTAL_ADDITIONAL_LEADS]: selectedSchoolGuids.map(
        (schoolGuid) => {
          return {
            value: JSON.stringify({
              impressionGuid: formValues[schoolGuid].impressionGuid,
              programGuid: formValues[schoolGuid].value,
            }),
          };
        }
      ),
    };
    selectedSchoolGuids.forEach((schoolGuid) => {
      delete _formValues[schoolGuid];
    });

    // rewrite the field name map to match what we need the fields to map to
    const fieldNameMap_ = {
      ...fieldNameMap,
      [FIELD_NAMES.MICRO_PORTAL_ADDITIONAL_LEADS]: {
        id: QUESTION_IDS.MICRO_PORTAL_ADDITIONAL_LEADS,
      },
    };

    return onFormSubmit(_formValues, fieldNameMap_, formConfigs, formState);
  }

  useEffect(() => {
    const apiCallPromise =
      additionalSchoolResultsPromise ||
      getMicroPortalPostLeadSubmitQuestionnaire({
        schoolCode,
      });

    apiCallPromise.then((results) =>
      updateMicroPortalData({ additionalSchoolResults: results })
    );
  }, []);

  if (!additionalSchoolResults) {
    return (
      <div>
        <Loader text="Loading related schools..." />
      </div>
    );
  }

  return (
    <BasicFormWizard
      {...additionalSchoolResults}
      disableLogging
      disableAutoSelect
      shouldInitJornaya
      customSubmitHandler={_onFormSubmit}
    />
  );
}
