/* eslint-disable no-shadow */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OptionsPropType, OptionPropType } from 'utils/propTypes';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import cx from 'utils/classnames';
import Checkbox from 'components/base/checkbox/Checkbox';

// number of options before wrapping to 2 columns for options
const MIN_OPTIONS_FOR_COLUMNS = 7;

export default function MultiSelect(props) {
  const {
    options = [],
    name = '',
    onChange = () => {},
    resetValue = {},
    value = [],
    fieldRef,
    ...rest
  } = props;

  // No Grouping Support Yet
  const _options = useMemo(() => {
    if (!get(options, '[0].options')) {
      return options;
    }
    return uniqBy(options.map((group) => group.options).flat(), 'value');
  }, [options]);

  // when loading from api to resume we may only get a string
  const _value = typeof value === 'string' ? [{ value }] : value;

  const [selectedValues, setSelectedValues] = useState(
    _value.length ? _value : [resetValue]
  );

  // if options change re-compute pre-selected values, or reset value
  // as per https://triadms.atlassian.net/browse/T1-3023 if we only have 2 options select the first and skip
  useEffect(() => {
    const preSelections = _options.filter(({ selected }) => selected);

    const shouldSetPreSelection =
      preSelections.length && !_value.filter(({ value }) => value).length;

    if (shouldSetPreSelection) {
      onChange(preSelections, { name });
      setSelectedValues(preSelections);
    }
  }, [_options]);

  // onChange we may need to reset values
  const _onChange = (value, meta) => {
    const { checked } = meta.event.target;

    // Note doing == because input onChange will always return string value so we need 10 to equal "10"
    // eslint-disable-next-line eqeqeq
    const selectedOption = _options.find((option) => value == option.value);

    let updatedSelection = [...selectedValues].filter(
      (option) =>
        option.value !== resetValue.value && option.value !== resetValue.guid
    );

    if (selectedOption.value === resetValue.value) {
      updatedSelection = [resetValue];
    } else if (checked) {
      updatedSelection.push(selectedOption);
    } else {
      // If hydrated from session selectedOption.value is a guid and wont match option's value
      updatedSelection = updatedSelection.filter(
        (option) =>
          option.value !== selectedOption.value &&
          option.value !== selectedOption.guid
      );
    }

    if (!updatedSelection.length) {
      updatedSelection = [resetValue];
    }

    onChange(updatedSelection, meta);
    setSelectedValues(updatedSelection);
  };

  const multiSelectClassName = cx({
    multiSelect: true,
    'multiSelect--columns': options && options.length > MIN_OPTIONS_FOR_COLUMNS,
  });

  if (!options.length) {
    return (
      <div className="multiSelect">
        <span className="multiSelect__loadingText">loading results...</span>
      </div>
    );
  }

  return (
    <div className={multiSelectClassName}>
      {_options.map((option, index) => {
        return (
          <div className="multiSelect__option" key={option.value}>
            <Checkbox
              {...rest}
              fieldRef={!index ? fieldRef : undefined}
              type="checkbox"
              className="multiSelect__option"
              name={name}
              value={option.value}
              label={option.label}
              id={`${name}_${index}`}
              checked={selectedValues.some(
                ({ value }) => value === option.value || value === option.guid
              )}
              onChange={_onChange}
            />
          </div>
        );
      })}
    </div>
  );
}

MultiSelect.propTypes = {
  options: OptionsPropType,
  value: PropTypes.oneOfType([PropTypes.string, OptionsPropType]),
  name: PropTypes.string,
  resetValue: OptionPropType,
  onChange: PropTypes.func,
  fieldRef: PropTypes.shape({}),
};
