/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { pagePropsObj } from 'utils/propTypes';
import getComponent from 'components/COMPONENT_MAP';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';

function SiteFooter(props) {
  const {
    config: { footerComponent, ...footerProps } = {},
    meta: { siteName } = '',
  } = props;

  const FooterComponent = getComponent(footerComponent);

  return (
    <DefaultErrorBoundary>
      <div className="site__footer">
        <FooterComponent {...footerProps} siteName={siteName} />
      </div>
    </DefaultErrorBoundary>
  );
}

SiteFooter.propTypes = {
  config: PropTypes.shape({
    component: PropTypes.string,
  }),
  meta: pagePropsObj.meta.isRequired,
};

export default SiteFooter;
