/* eslint-disable react/prop-types */
import React, { useState, useContext, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cx from 'utils/classnames';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import ProgressBar from 'components/base/progressBar/ProgressBar';
import { handleAutoAdvance } from 'components/form-wizards/multiQuestionFormWizard/multiQuestionFormUtils';
import { scrollTo } from 'utils/generalUtils';
import { getTransitionInStatus } from 'components/form-wizards/basic-form/utils';
import GlobalContext from 'hooks/contexts/GlobalContext';
import LoadingButton from 'components/base/buttons/loadingButton/LoadingButton';
import FormStep from 'components/form-wizards/basic-form/FormStep';
import FormContainerV1 from 'components/form-wizards/containers/FormContainerV1';
import Steps from 'components/base/steps/Steps';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import { SkipButton } from 'components/base/buttons/skipButton/SkipButton';

function Form(props) {
  const {
    currentAction,
    currentQuestions,
    currentStepIndex,
    formStatus,
    getNextButtonText,
    handleSubmit,
    isFirstStep = true,
    isNextDisabled,
    percentComplete,
    showSidebar,
    showSteps,
    steps,
    stepsCount,
    title,
    variant,
  } = props;

  const [formStepHeight, setFormStepHeight] = useState(null);
  const [formIsTransitioning, setFormIsTransitioning] = useState(false);
  const wizardRef = useRef(null);
  const { formSpeed } = useCSSsettings(wizardRef);

  const {
    refs: { stepsContainerRef },
  } = useContext(GlobalContext);

  const stepContainerClass = cx({
    basicFormWizard__stepContainer: true,
    [`basicFormWizard__stepContainer--${currentAction}`]: currentAction,
    'basicFormWizard__stepContainer--isTransitioning': formIsTransitioning,
  });

  return (
    <div
      className={
        isFirstStep
          ? 'basicFormWizard basicFormWizard--isFullPage basicFormWizard--isMultiQuestion basicFormWizard--isFirstStep'
          : 'basicFormWizard basicFormWizard--isFullPage basicFormWizard--isMultiQuestion'
      }
      ref={wizardRef}
    >
      <div className="basicFormWizard__container">
        <CSSTransition
          in
          mountOnEnter
          appear
          timeout={formSpeed}
          className="basicFormWizard__innerContainer"
          classNames="basicFormWizard__innerContainer"
        >
          <div>
            {formStatus.formError && (
              <div className="basicFormWizard__formError">
                {formStatus.formError}
              </div>
            )}
            <div className="basicFormWizard__head">
              <CSSTransition
                in={getTransitionInStatus(formStatus, isFirstStep)}
                appear
                mountOnEnter
                unmountOnExit
                timeout={formSpeed}
                className="basicFormWizard__title"
                classNames="basicFormWizard__title"
              >
                <h1>{title}</h1>
              </CSSTransition>
              <CSSTransition
                in={getTransitionInStatus(formStatus, isFirstStep)}
                appear
                mountOnEnter
                unmountOnExit
                timeout={formSpeed}
                className="basicFormWizard__progressBar"
                classNames="basicFormWizard__progressBar"
              >
                <div>
                  <ProgressBar
                    currentStepIndex={
                      steps ? steps[currentStepIndex].trueStepIndex : 0
                    }
                    totalSteps={stepsCount}
                    progress={percentComplete}
                  />
                </div>
              </CSSTransition>
              {showSidebar && showSteps && (
                <Steps
                  currentStepIndex={
                    steps ? steps[currentStepIndex].trueStepIndex : 0
                  }
                  steps={steps}
                  stepsContainerRef={stepsContainerRef}
                />
              )}
            </div>
            <div
              className={stepContainerClass}
              style={{ minHeight: `${formStepHeight + 20}px` }}
            >
              <TransitionGroup component={null}>
                <CSSTransition
                  in={getTransitionInStatus(formStatus, isFirstStep)}
                  key={`basicFormWizard__step_${currentStepIndex}`}
                  classNames="basicFormWizard__step"
                  className="basicFormWizard__step"
                  timeout={formSpeed}
                  onEntering={(node) => {
                    setFormStepHeight(node.clientHeight);
                    setFormIsTransitioning(true);
                    scrollTo();
                  }}
                  onExited={() => {
                    setFormIsTransitioning(false);
                  }}
                >
                  <div>
                    <DefaultErrorBoundary meta={{ currentStepIndex, variant }}>
                      <FormStep
                        questions={currentQuestions}
                        stepLabel={steps[currentStepIndex].groupLabel}
                        stepIndex={currentStepIndex}
                        formIsDirty={formStatus.isDirty}
                      />
                    </DefaultErrorBoundary>
                    <div className="basicFormWizard__ctaContainer">
                      {!isFirstStep &&
                        (formStatus.isDirty ||
                          formStatus.isResumeSessionAcknowledged) && (
                          <button
                            type="button"
                            onClick={() => window.history.back()}
                            className="basicFormWizard__backBtn"
                            disabled={
                              formIsTransitioning || formStatus.isSubmitting
                            }
                          >
                            Previous
                          </button>
                        )}
                      <LoadingButton
                        onClick={handleSubmit}
                        className="basicFormWizard__nextBtn"
                        disabled={formIsTransitioning || isNextDisabled}
                        loading={isNextDisabled}
                        text={getNextButtonText()}
                      />
                      <SkipButton
                        disabled={formIsTransitioning || isNextDisabled}
                        loading={isNextDisabled}
                      />
                    </div>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default function MultiQuestionFormWizardFullPage(props) {
  return (
    <FormContainerV1
      {...props}
      Form={Form}
      handleAutoAdvance={handleAutoAdvance}
    />
  );
}
