import React from 'react';
import PropTypes from 'prop-types';

function ApplicationLink({ url, buttonText }) {
  if (!url) {
    return null;
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className="ctaButton ctaButton--applicationLink"
      data-track-click="click apply"
      data-testid="ctaButton--applicationLink"
      href={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {buttonText}
    </a>
  );
}

ApplicationLink.propTypes = {
  buttonText: PropTypes.string,
  url: PropTypes.string,
};

export default ApplicationLink;
